const localeToBCP47 = {
  no: 'nb-NO',
};

module.exports = {
  i18n: {
    defaultLocale: 'no',
    locales: ['no'],
    localePath: './public/locales',
  },
  serializeConfig: false,
  interpolation: {
    escapeValue: false,
    format(value, format, locale, { currency }) {
      const localeBCP47 = localeToBCP47[locale];

      if (format === 'currency') {
        if (typeof value === 'undefined' || !currency) {
          return 'N/A';
        }
        return new Intl.NumberFormat(localeBCP47 || locale, {
          style: 'currency',
          currency,
        }).format(value);
      }

      return value;
    },
  },
};
