import React, { LinkHTMLAttributes } from 'react';
import classNames from 'classnames';
import NextLink, { LinkProps } from 'next/link';

type Props = LinkProps & LinkHTMLAttributes<HTMLElement>;

type LinkElement = React.ElementRef<'a'>;

const Link = React.forwardRef<LinkElement, Props>(
  ({ href, className = '', children, ...props }, forwardedRef) => {
    if (!href) return null;

    return (
      <NextLink
        ref={forwardedRef}
        href={href}
        className={classNames('border-b-1 border-pink hover:border-transparent', className)}
        {...props}
      >
        {children as any}
      </NextLink>
    );
  },
);

export { Link };
export type { Props };
