import { UseMutationResult } from 'react-query';
import { Sdk } from 'graphql/generated/graphql-request';
import { Maybe } from 'types';

import { User } from '../users/types';

export type MetaKeys = 'login' | 'code';

export const returnStateQuery = 'state';

export type SessionData = {
  token: string;
} & Partial<User>;

export type UserUpdate = Partial<Omit<User, 'referralCode' | 'id'>>;

export type SessionProviderType = {
  phone: Maybe<string>;
  sendTime: Maybe<string>;
  session: SessionData;
  sdk: Sdk | undefined;
  authenticatedSdk: boolean;
  login: UseMutationResult;
  logout: UseMutationResult;
  sendCode: UseMutationResult;
  resendCode: UseMutationResult;
  updateUser: UseMutationResult;
  checkLogin: UseMutationResult;
};
