import { GraphQLClient } from 'graphql-request';

import { config } from '../config';
import { getSdk, SdkFunctionWrapper } from './generated/graphql-request';

const wrapper: SdkFunctionWrapper = (action, _operationName, _operationType) =>
  action({ credentials: 'include' });

export const publicSdk = getSdk(
  new GraphQLClient(config.hasura.uri, {
    credentials: 'include',
  }),
);

export const userSdk = (token: string) => {
  return getSdk(
    new GraphQLClient(config.hasura.uri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: 'include',
    }),
    wrapper,
  );
};
