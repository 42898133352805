import '../styles.css';
// import '@petrus/ui-library/styles.css';
// import '@petrus/ui-add-to-calendar/styles.css';

import { useSession } from 'domain/auth/providers/SessionProvider';
import React, { useEffect, useMemo } from 'react';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import TagManager from 'react-gtm-module';
import { appWithTranslation } from 'next-i18next';

import { RootLayout } from 'app/layout';
import { config } from 'config';

import { useRouter } from 'next/router';
import nextI18NextConfig from '../../next-i18next.config.js';

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const { session } = useSession();
  const { callbackUrl: url } = router.query;
  const callbackUrl = url ? `${url}&` : '';
  const Layout = useMemo(() => Component.layout || RootLayout, [Component.layout]);

  useEffect(() => {
    posthog.init(config.posthog.token, {
      api_host: config.posthog.api_host,
      autocapture: false,
    });
    TagManager.initialize({
      gtmId: config.google.tagManager,
    });

    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (session) {
      const { id, ...rest } = session;
      router.push(`${callbackUrl}?userId=${id}`);
      posthog.identify(id, { ...rest });
    }
  }, [session, callbackUrl]);

  return (
    <PostHogProvider client={posthog}>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </PostHogProvider>
  );
};

export default appWithTranslation(MyApp, nextI18NextConfig as any);
