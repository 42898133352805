const constants = {
  customerServiceMailLink: 'mailto:kundeservice@petrus.no',
  orderForCubsLink: 'https://form.typeform.com/to/BhV1OxXZ',
  defaultSeoImage: 'https://iili.io/dhwvJj.md.jpg',
};

const routes = {
  login: '/login',
  checkin: '/checkin',
  register: '/registrer',
  logout: '/logout',
  myPage: '/min-side',
  booking: '/',
  getBookingUrlWithAniaml: (id) => `/?animal=${id}`,
};

export const array = (value: string): string[] => {
  if (!value) return [];

  return value.split(',');
};

export const number = (value?: string, fallback: number = 0): number => {
  if (!value) return fallback;

  return parseInt(value, 10);
};

const config = {
  isCypress: process.env.NEXT_PUBLIC_CYPRESS === 'true',
  environment: process.env.NODE_ENV ?? 'development',
  privacyLink: process.env.NEXT_PUBLIC_PRIVACY_LINK || '',
  homeUrl: process.env.NEXT_PUBLIC_HOME_URL || '',
  mailUrl: process.env.NEXT_PUBLIC_MAIL_URL || '',
  tlf: process.env.NEXT_PUBLIC_TLF || '',
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000',
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL || 'http://localhost:5005/api/v1',
  google: {
    tagManager: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  },
  posthog: {
    token: 'phc_OWyr0syBob2Owz6UCO6B34QiQrGySnlKfnH9vnkq9E5',
    api_host: 'https://app.posthog.com',
  },
  sentry: {
    environment: process.env.NODE_ENV || 'development',
    dsn: 'https://39bddc8f51524826ace774c32ff22cda@o242872.ingest.sentry.io/5422682',
  },
  auth: {
    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    audience: 'hasura',
    redirectUri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
    logoutUrI: process.env.NEXT_PUBLIC_AUTH0_LOGOUT_URI,
    scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
  },
  hasura: {
    uri: process.env.NEXT_PUBLIC_HASURA_ENDPOINT || 'http://localhost:8080/v1/graphql',
    ngrokUri: process.env.NEXT_PUBLIC_HASURA_BASE_URL,
    secret: String(process.env.HASURA_SECRET),
  },
  clinicOverwrite: {
    closed: array(process.env.NEXT_PUBLIC_CLOSED_CLINIC_IDS),
  },
  treatmentOverwrite: {
    cleaning: {
      ids: array(process.env.NEXT_PUBLIC_TOOTH_CLEAN_TREATMENT_IDS),
      limit: number(process.env.NEXT_PUBLIC_TOOTH_CLEAN_TREATMENT_TREATMENT_LIMT, 3),
      treatments: array(process.env.NEXT_PUBLIC_TOOTH_CLEAN_TREATMENT_OVERWRITE),
    },
    noteRequired: {
      ids: array(process.env.NEXT_PUBLIC_NOTE_REQUIRED_TREATMENT_IDS),
    },
  },
  reseptOverwrite: {
    id: String(process.env.NEXT_PUBLIC_RESEPT_ID),
    title: String(process.env.NEXT_PUBLIC_RESEPT_OVERWRITE_TITLE),
    description: String(process.env.NEXT_PUBLIC_RESEPT_OVERWRITE_DESCRIPTION),
    konsultasjonId: String(process.env.NEXT_PUBLIC_CONSULTATION_ID),
    isActive: process.env.NEXT_PUBLIC_RESEPT_OVERWRITE === 'true',
    link: process.env.NEXT_PUBLIC_RESEPT_OVERWRITE_LINK,
    cta: process.env.NEXT_PUBLIC_RESEPT_OVERWRITE_CTA,
  },
};

const isProduction = config.environment === 'production';

type Config = typeof config;

export { config, routes, constants, isProduction };
export type { Config };
