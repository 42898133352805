/* eslint-disable no-shadow, no-lonely-if */
import React, { useState, HTMLAttributes } from 'react';
import { useMutation } from 'react-query';
import { getPhoneNumberFormatted } from '@askeladden/phonenumber';

import AuthService from 'common/services/AuthService';
import { Sdk } from 'graphql/generated/graphql-request';
import { publicSdk, userSdk } from 'graphql/sdk';

import CustomAuthService from 'common/services/AuthService/AuthService';
import posthog from 'posthog-js';
import { SessionProviderType, SessionData, UserUpdate, returnStateQuery } from '../types';

const AuthContext = React.createContext({} as SessionProviderType);

export const useSession = () => React.useContext(AuthContext);

export type Props = HTMLAttributes<HTMLElement>;

export const SessionProvider = ({ children }: Props) => {
  const [session, setSession] = useState<SessionData>();
  const [phone, setPhone] = useState<string>();
  const [sdk, setSdk] = useState<Sdk>(publicSdk);
  const [authenticatedSdk, setAuthenticatedSdk] = useState(false);
  const [sendTime, setSendTime] = useState<string>();

  const login = useMutation(async ({ otp, returnTo = '' }: { otp: string; returnTo?: string }) => {
    const formattedPhoneNumber = getPhoneNumberFormatted(phone);

    if (!formattedPhoneNumber) {
      throw new Error('Invalid phone number');
    }

    if (returnTo) {
      const customAuth = new CustomAuthService({ [returnStateQuery]: returnTo });
      await customAuth.verify(phone, otp);
    } else {
      AuthService.verify(phone, otp);
    }
  });

  const logout = useMutation(() => AuthService.logout(), {
    onSuccess: () => {
      setSession(undefined);
      setSdk(undefined);
      setAuthenticatedSdk(false);
    },
  });

  const sendCode = useMutation(
    async (newPhoneNumber?: string) => {
      const phoneToSms = newPhoneNumber || phone;

      if (newPhoneNumber) setPhone(newPhoneNumber);
      if (!phoneToSms) return;

      try {
        await AuthService.start(phoneToSms);
      } catch (e) {
        throw Error(e.description);
      }
    },
    {
      onError() {
        setSendTime(undefined);
      },
      onSuccess: () => {
        setSendTime(new Date().toString());
      },
    },
  );

  const resendCode = useMutation(
    async () => {
      await AuthService.start(phone);
    },
    {
      onError() {
        setSendTime(undefined);
      },
      onSuccess: () => {
        setSendTime(new Date().toString());
      },
    },
  );

  const checkLogin = useMutation(async (isRequired: boolean = false) => {
    if (session) return;
    const validToken = await AuthService.getAndValidateAccessToken();
    if (validToken) {
      const { accessToken, userId } = AuthService.getTokenAndAuthUserId(validToken as any);
      const sdk = userSdk(accessToken);
      const { getMe } = await sdk.getMe();

      setSession({
        ...getMe,
        token: accessToken,
        id: userId,
      });
      posthog.identify(userId, { ...getMe });

      setSdk(sdk);
      setAuthenticatedSdk(true);

      return getMe;
    }
    if (isRequired) {
      throw new Error('invalid_token');
    }
  });

  const updateUser = useMutation(
    async (data: UserUpdate) => {
      const { updateMe } = await sdk.updateMe(data as any);
      return updateMe;
    },
    {
      onSuccess: (data) => {
        setSession({
          ...data,
          token: session.token,
          id: session.id,
        });
        setSendTime(new Date().toString());
      },
    },
  );

  return (
    <AuthContext.Provider
      value={{
        phone,
        sendTime,
        session,
        sdk,
        authenticatedSdk,
        login,
        logout,
        sendCode,
        resendCode,
        checkLogin,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
