export type Query = { [key: string]: undefined | null | boolean | number | string };

export const makeUrl = (base: string, query: Query = {}) =>
  Object.keys(query).reduce((accum, key, index) => {
    const value = query[key];

    if (value && value !== null) {
      return `${accum}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(value)}`;
    }
    return accum;
  }, base);
