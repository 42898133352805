import React, { HTMLAttributes } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { BookingFragment } from 'graphql/generated/graphql-request';
import { UserProviderType } from '../types';
import { useSession } from '../../auth/providers/SessionProvider';

const UserContext = React.createContext({} as UserProviderType);

export const useUserState = () => React.useContext(UserContext);

export const UserProvider = ({ children }: HTMLAttributes<HTMLElement>) => {
  const { sdk, authenticatedSdk } = useSession();
  const queryClient = useQueryClient();

  const getBookingsQuery = useQuery<BookingFragment[]>(
    ['bookings'],
    async () => {
      const { getMyBookings } = await sdk.getMyBookings();
      return getMyBookings;
    },
    {
      enabled: authenticatedSdk,
    },
  );

  const deleteBookingMutation = useMutation<BookingFragment, string, { bookingIds: string[] }>(
    async ({ bookingIds }) => {
      const { deleteBooking } = await sdk.deleteBooking({ bookingIds });
      return deleteBooking;
    },
    {
      onMutate: async ({ bookingIds }) => {
        await queryClient.cancelQueries({ queryKey: ['bookings'] });
        const previousBookings = queryClient.getQueryData(['bookings']);

        queryClient.setQueryData(['bookings'], (old: BookingFragment[]) =>
          old.filter((item) => !item.bookingIds.every((id) => bookingIds.includes(id))),
        );

        return { previousBookings };
      },
      onError: (err, newBooking, context: any) => {
        queryClient.setQueryData(['bookings'], context.previousBookings);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['bookings'] });
      },
    },
  );

  return (
    <UserContext.Provider
      value={{
        bookings: {
          get: getBookingsQuery,
          delete: deleteBookingMutation,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
