import { useEffect } from 'react';
import { useSession } from '../providers/SessionProvider';

const AutoLogin = () => {
  const { checkLogin } = useSession();

  useEffect(() => {
    checkLogin.mutate(false);
  }, []);

  return null;
};

export { AutoLogin };
