import { ControlledAnimal } from 'domain/animal/types';
import { useSession } from 'domain/auth/providers/SessionProvider';
import React, { HTMLAttributes, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDisclosure } from '@petrus/ui-hooks';

import { AnimalFragment } from 'graphql/generated/graphql-request';
import { AnimalProviderType } from '../types';

const AnimalContext = React.createContext({} as AnimalProviderType);

type EditAnimal = { id: string; data: ControlledAnimal };

export const useAnimals = () => React.useContext(AnimalContext);

export const AnimalProvider = ({ children }: HTMLAttributes<HTMLElement>) => {
  const { sdk, authenticatedSdk } = useSession();
  const queryClient = useQueryClient();
  const createDisclosure = useDisclosure();
  const [loadingState, setLoadingState] = useState({});

  const getQuery = useQuery<AnimalFragment[]>(
    ['animals'],
    async () => {
      const { animals } = await sdk.getMyAnimals();
      return animals;
    },
    { enabled: authenticatedSdk },
  );

  const createMutation = useMutation(
    async (data: ControlledAnimal) => {
      const { animal } = await sdk.createMyAnimal(data as any);
      return animal;
    },
    {
      onMutate: async (newAnimal) => {
        await queryClient.cancelQueries({ queryKey: ['animals'] });
        const previousAnimals = queryClient.getQueryData(['animals']);
        queryClient.setQueryData(['animals'], (old: AnimalFragment[]) => [...old, newAnimal]);
        return { previousAnimals };
      },
      onError: (err, newAnimal, context) => {
        queryClient.setQueryData(['animals'], context.previousAnimals);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['animals'] });
      },
    },
  );

  const cleanUpdateData = (data: ControlledAnimal) => {
    const allowedFields = ['dob', 'gender', 'id', 'name', 'type', 'weight'];
    return Object.keys(data).reduce((obj, key) => {
      if (allowedFields.includes(key)) {
        obj[key] = data[key];
      }
      return obj;
    }, {});
  };

  const updateMutation = useMutation(
    async ({ id, data }: EditAnimal) => {
      const { animal } = await sdk.updateMyAnimal({ id, ...cleanUpdateData(data) } as any);
      return animal;
    },
    {
      onMutate: async ({ id, data }) => {
        await queryClient.cancelQueries({ queryKey: ['animals'] });
        const previousAnimals = queryClient.getQueryData(['animals']);

        queryClient.setQueryData(['animals'], (old: AnimalFragment[]) =>
          old?.map((item) => {
            return item.id === id ? { ...item, ...data } : item;
          }),
        );

        return { previousAnimals };
      },
      onError: (err, newAnimal, context) => {
        queryClient.setQueryData(['animals'], context.previousAnimals);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['animals'] });
      },
    },
  );

  const archiveMutation = useMutation(
    async (id: string) => {
      setLoadingState((prev) => ({ ...prev, [id]: true }));
      await sdk.deleteMyAnimal({ id });
      return { id };
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries({ queryKey: ['animals'] });
      },
      onError: (err, id) => {
        setLoadingState((prev) => ({ ...prev, [id]: false }));
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries({ queryKey: ['animals'] });
        setLoadingState((prev) => ({ ...prev, [variables]: false }));
      },
    },
  );

  return (
    <AnimalContext.Provider
      value={{
        get: getQuery,
        create: createMutation,
        update: updateMutation,
        archive: archiveMutation,
        loadingState,
        dialog: {
          create: createDisclosure,
        },
      }}
    >
      {children}
    </AnimalContext.Provider>
  );
};
