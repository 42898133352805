import { useSession } from 'domain/auth/providers/SessionProvider';
import React, { createContext, HTMLAttributes } from 'react';

import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import {
  GetBookingRequestsQuery,
  ArchiveBookingRequestMutation,
} from 'graphql/generated/graphql-request';

export type BookingRequestsState = {
  getBookingRequests: UseQueryResult<GetBookingRequestsQuery['booking_request']>;
  archiveBookingRequest: UseMutationResult<
    ArchiveBookingRequestMutation['update_booking_request_by_pk']
  >;
};

const BookingRequestsContext = createContext({} as BookingRequestsState);

export const useBookingRequests = () => React.useContext(BookingRequestsContext);

export type Props = HTMLAttributes<HTMLElement>;

export const BookingRequestsProvider = ({ children }: Props) => {
  const { session, sdk } = useSession();

  const getBookingRequests = useQuery<GetBookingRequestsQuery['booking_request']>(
    ['booking_requests', session?.id],
    () => {
      if (!sdk || !session?.id) {
        return [];
      }

      return sdk.getBookingRequests().then((res) => res.booking_request);
    },
  );

  const archiveBookingRequest = useMutation<
    ArchiveBookingRequestMutation['update_booking_request_by_pk']
  >(async (id) => {
    return sdk.archiveBookingRequest({ id }).then((res) => res.update_booking_request_by_pk);
  });

  return (
    <BookingRequestsContext.Provider
      value={{
        getBookingRequests,
        archiveBookingRequest,
      }}
    >
      {children}
    </BookingRequestsContext.Provider>
  );
};
