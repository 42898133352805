import { AutoLogin } from 'domain/auth/containers/AutoLogin';
import { SessionProvider } from 'domain/auth/providers/SessionProvider';
import { BookingRequestsProvider } from 'domain/bookingRequest/providers/BookingRequestsProvider';
import { UserProvider } from 'domain/users/providers/UserProvider';
import { AnimalProvider } from 'domain/animal/providers/AnimalProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { PropsWithChildren, ComponentProps } from 'react';
import { NavBar } from 'app/NavBar';

type Props = PropsWithChildren<ComponentProps<'div'>>;

const queryClient = new QueryClient();

const RootLayout = ({ children }: Props) => (
  <QueryClientProvider client={queryClient}>
    <SessionProvider>
      <UserProvider>
        <AnimalProvider>
          <BookingRequestsProvider>
            <div className="flex flex-col min-h-screen">
              <NavBar />
              <div className="flex flex-col flex-1 bg-blush">{children}</div>
            </div>
            {/* <CookieBot /> */}
            <AutoLogin />
          </BookingRequestsProvider>
        </AnimalProvider>
      </UserProvider>
    </SessionProvider>
  </QueryClientProvider>
);

export { RootLayout };
