import React from 'react';
import NextLink, { LinkProps } from 'next/link';

import type { ButtonProps } from '@petrus/ui-library';
import { Button } from '@petrus/ui-library';

type Props = {
  href: LinkProps['href'];
} & ButtonProps;

const LinkButton = ({ href, children, ...props }: Props) => {
  return (
    <Button {...props} asChild>
      <NextLink href={href}>{children}</NextLink>
    </Button>
  );
};

export { LinkButton };
export type { Props };
