import jwtDecode from 'jwt-decode';
import { WebAuth, Auth0DecodedHash } from 'auth0-js';
import { getPhoneNumberFormatted } from '@askeladden/phonenumber';
import { config } from 'config';

import { Query, makeUrl } from 'common/utils/url.utils';
import { Auth0DecodedToken, APIAuthenticationPayload } from './types';

type AuthenticationTokenPayload = {
  accessToken: string;
  idToken: string;
  expiresIn: number;
};

const isBrowser = typeof window !== 'undefined' && process.env.CI !== 'true' && !config.isCypress;

const returnTo = config.auth.logoutUrI || 'https://app.petrus.no/';

class AuthService {
  private webAuth: WebAuth;

  constructor(returnState?: Query) {
    this.webAuth = isBrowser
      ? new WebAuth({
          domain: config.auth.domain,
          clientID: config.auth.clientId,
          audience: config.auth.audience,
          redirectUri: makeUrl(config.auth.redirectUri, returnState),
          responseType: 'token id_token',
          scope: config.auth.scope,
        })
      : {};
  }

  async getAndValidateAccessToken(): Promise<AuthenticationTokenPayload | boolean> {
    const authSession = await this.checkAuthSession();
    const isAuthPayloadValid = this.authPayloadIsValid(authSession);
    return isAuthPayloadValid ? authSession : isAuthPayloadValid;
  }

  checkAuthSession() {
    return new Promise<AuthenticationTokenPayload>((resolve, reject) => {
      this.webAuth.checkSession({}, (err, authResult) => {
        if (err) {
          return reject(err);
        }

        if (authResult == null) {
          return reject(new Error('hash is null'));
        }

        const authPayload = this.mapDecodedHashToAuthPayload(authResult);
        resolve(authPayload);
      });
    });
  }

  mapDecodedHashToAuthPayload(decodedHash: Auth0DecodedHash): AuthenticationTokenPayload {
    return {
      idToken: decodedHash.idToken,
      accessToken: decodedHash.accessToken,
      expiresIn: decodedHash.expiresIn,
    };
  }

  getTokenAndAuthUserId({
    accessToken,
    idToken,
  }: AuthenticationTokenPayload): APIAuthenticationPayload {
    const decodedIdToken: Auth0DecodedToken = jwtDecode(idToken);
    const userId = decodedIdToken['https://hasura.io/jwt/claims']['x-hasura-user-id'];
    const payload: APIAuthenticationPayload = { accessToken, userId };
    return payload;
  }

  start(phoneNumber: string) {
    const formattedPhoneNumber = getPhoneNumberFormatted(phoneNumber);

    if (!formattedPhoneNumber) {
      throw new Error('Invalid phone number');
    }

    const variables = {
      send: 'code',
      connection: 'sms',
      phoneNumber: formattedPhoneNumber.toString(),
      authParams: {
        scope: config.auth.scope,
      },
    };

    return new Promise((ok, fail) => {
      this.webAuth.passwordlessStart(variables, (err, res) => {
        if (err) {
          return fail(err);
        }

        return ok(res);
      });
    });
  }

  verify(phoneNumber: string, verificationCode: string) {
    const formattedPhoneNumber = getPhoneNumberFormatted(phoneNumber);

    if (!formattedPhoneNumber) {
      throw new Error('Invalid phone number');
    }

    const variables = {
      send: 'code',
      connection: 'sms',
      phoneNumber: formattedPhoneNumber.toString(),
      verificationCode,
    };

    return new Promise((ok, fail) => {
      this.webAuth.passwordlessLogin(variables, (err, res) => {
        if (err) {
          return fail(err.code || err);
        }

        return ok(res);
      });
    });
  }

  logout() {
    return this.webAuth.logout({ returnTo });
  }

  private authPayloadIsValid(authPayload: AuthenticationTokenPayload) {
    const notExpired = authPayload.expiresIn > 0;
    return !!authPayload && !!authPayload.accessToken && notExpired;
  }
}

export default AuthService;
