import { useSession } from 'domain/auth/providers/SessionProvider';
import { SessionData } from 'domain/auth/types';
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Banner, Container, Logo, Text } from '@petrus/ui-library';

import { LinkButton } from 'common/components/LinkButton';
import { Link } from 'common/components/Link';
import { useRouter } from 'next/router';
import { config, routes } from 'config';
import posthog from 'posthog-js';

type NavLink = {
  href: string;
  title: string;
};

const getNavLinks = (checkLogin: boolean, session?: SessionData): NavLink[] => {
  if (checkLogin) return [];
  if (session && session.token) {
    return [
      {
        href: routes.myPage,
        title: 'components.nav.myPage',
      },
    ];
  }

  return [
    {
      href: routes.login,
      title: 'components.nav.login',
    },
  ];
};

const NavBar = () => {
  const { session, checkLogin } = useSession();
  const { route } = useRouter();
  const { t } = useTranslation('common');

  const navLinks = useMemo(
    () =>
      getNavLinks(checkLogin.isLoading || checkLogin.isIdle || route === routes.register, session),
    [checkLogin, session],
  );
  const filteredNavLinks = useMemo(
    () => navLinks.filter((link) => link.href !== route),
    [navLinks, route],
  );
  const hideBookingLink = useMemo(() => ['/'].includes(route), [route]);

  return (
    <div className="bg-blush">
      <Banner>
        <Text size="small">
          {t('global.banner')}
          <Link
            onClick={() => posthog.capture('Banner link pressed')}
            href={`tel:${config.tlf}`}
            className="border-none"
          >
            {config.tlf}
          </Link>
        </Text>
      </Banner>
      <Container className="py-3">
        <div className="flex justify-between items-center h-[40px]">
          <div>
            <Logo className="h-6 fill-carmine" />
          </div>
          <div className="flex">
            <nav className="items-center text-carmine block">
              {filteredNavLinks.map((link) => (
                <Link key={link.href} href={link.href} className="mr-4">
                  {t(link.title)}
                </Link>
              ))}
              <LinkButton href="/" size="small" className={hideBookingLink && 'hidden'}>
                <Text size="small">{t('booking')}</Text>
              </LinkButton>
            </nav>
          </div>
        </div>
      </Container>
    </div>
  );
};

export { NavBar };
