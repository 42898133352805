import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  timestamptz: any;
  uuid: any;
};

export type Animal = {
  __typename?: 'Animal';
  breed?: Maybe<Scalars['String']>;
  deceased?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  gender?: Maybe<AnimalGender>;
  id: Scalars['String'];
  insurance?: Maybe<Scalars['String']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner: Scalars['String'];
  state?: Maybe<DtoState>;
  type?: Maybe<AnimalType>;
  weight?: Maybe<Scalars['Int']>;
};

export type AnimalBookingInput = {
  dob: Scalars['String'];
  gender: AnimalGender;
  name: Scalars['String'];
  type: AnimalType;
  weight?: InputMaybe<Scalars['Float']>;
};

export enum AnimalGender {
  FEMALE = 'female',
  MALE = 'male',
}

export enum AnimalType {
  CAT = 'cat',
  DOG = 'dog',
}

export type Availablity = {
  __typename?: 'Availablity';
  date: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOpen: Scalars['Boolean'];
  slots: Array<Slot>;
};

export type Booking = {
  __typename?: 'Booking';
  animal: Animal;
  bookingIds: Array<Maybe<Scalars['String']>>;
  confirmed: Scalars['Boolean'];
  duration: Scalars['Int'];
  location?: Maybe<Locations>;
  location_id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  services: Array<Maybe<Service>>;
  state: BookingState;
  time: Scalars['String'];
};

export enum BookingState {
  BOOKED = 'booked',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
}

export type CreateBookingInput = {
  locationId: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  pet?: InputMaybe<AnimalBookingInput>;
  petId?: InputMaybe<Scalars['String']>;
  serviceIds: Array<Scalars['String']>;
  time: Scalars['String'];
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export enum DtoState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type IdOutput = {
  __typename?: 'IdOutput';
  id: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type Service = {
  __typename?: 'Service';
  duration: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  unknown: Scalars['Boolean'];
};

export type Slot = {
  __typename?: 'Slot';
  isAvailable: Scalars['Boolean'];
  numberOfAvailableSlots: Scalars['Int'];
  status: Scalars['String'];
  time: Scalars['String'];
  timeInfo: Array<Maybe<TimeInfo>>;
};

export type StatusOutput = {
  __typename?: 'StatusOutput';
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringArrayComparisonExp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type TimeInfo = {
  __typename?: 'TimeInfo';
  endTime: Scalars['String'];
  primaryResources: Array<Scalars['String']>;
  secondaryResources: Array<Scalars['String']>;
  service: Scalars['String'];
  startTime: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  animals: Array<Maybe<Scalars['String']>>;
  city: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  state: DtoState;
  streetAddress: Scalars['String'];
  zipCode: Scalars['String'];
};

export type UserInput = {
  city: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  streetAddress: Scalars['String'];
  zipCode: Scalars['String'];
};

/** columns and relationships of "animal_genders" */
export type AnimalGenders = {
  __typename?: 'animal_genders';
  name: Scalars['String'];
  /** An array relationship */
  treatments: Array<Treatments>;
  /** An aggregate relationship */
  treatments_aggregate: TreatmentsAggregate;
};

/** columns and relationships of "animal_genders" */
export type AnimalGendersTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** columns and relationships of "animal_genders" */
export type AnimalGendersTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** aggregated selection of "animal_genders" */
export type AnimalGendersAggregate = {
  __typename?: 'animal_genders_aggregate';
  aggregate?: Maybe<AnimalGendersAggregateFields>;
  nodes: Array<AnimalGenders>;
};

/** aggregate fields of "animal_genders" */
export type AnimalGendersAggregateFields = {
  __typename?: 'animal_genders_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AnimalGendersMaxFields>;
  min?: Maybe<AnimalGendersMinFields>;
};

/** aggregate fields of "animal_genders" */
export type AnimalGendersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AnimalGendersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "animal_genders". All fields are combined with a logical 'AND'. */
export type AnimalGendersBoolExp = {
  _and?: InputMaybe<Array<AnimalGendersBoolExp>>;
  _not?: InputMaybe<AnimalGendersBoolExp>;
  _or?: InputMaybe<Array<AnimalGendersBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
  treatments?: InputMaybe<TreatmentsBoolExp>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateBoolExp>;
};

/** unique or primary key constraints on table "animal_genders" */
export enum AnimalGendersConstraint {
  /** unique or primary key constraint on columns "name" */
  ANIMAL_GENDERS_PKEY = 'animal_genders_pkey',
}

export enum AnimalGendersEnum {
  FEMALE = 'female',
  MALE = 'male',
}

/** Boolean expression to compare columns of type "animal_genders_enum". All fields are combined with logical 'AND'. */
export type AnimalGendersEnumComparisonExp = {
  _eq?: InputMaybe<AnimalGendersEnum>;
  _in?: InputMaybe<Array<AnimalGendersEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AnimalGendersEnum>;
  _nin?: InputMaybe<Array<AnimalGendersEnum>>;
};

/** input type for inserting data into table "animal_genders" */
export type AnimalGendersInsertInput = {
  name?: InputMaybe<Scalars['String']>;
  treatments?: InputMaybe<TreatmentsArrRelInsertInput>;
};

/** aggregate max on columns */
export type AnimalGendersMaxFields = {
  __typename?: 'animal_genders_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AnimalGendersMinFields = {
  __typename?: 'animal_genders_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "animal_genders" */
export type AnimalGendersMutationResponse = {
  __typename?: 'animal_genders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AnimalGenders>;
};

/** input type for inserting object relation for remote table "animal_genders" */
export type AnimalGendersObjRelInsertInput = {
  data: AnimalGendersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AnimalGendersOnConflict>;
};

/** on_conflict condition type for table "animal_genders" */
export type AnimalGendersOnConflict = {
  constraint: AnimalGendersConstraint;
  update_columns?: Array<AnimalGendersUpdateColumn>;
  where?: InputMaybe<AnimalGendersBoolExp>;
};

/** Ordering options when selecting data from "animal_genders". */
export type AnimalGendersOrderBy = {
  name?: InputMaybe<OrderBy>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateOrderBy>;
};

/** primary key columns input for table: animal_genders */
export type AnimalGendersPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "animal_genders" */
export enum AnimalGendersSelectColumn {
  /** column name */
  NAME = 'name',
}

/** input type for updating data in table "animal_genders" */
export type AnimalGendersSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "animal_genders" */
export type AnimalGendersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AnimalGendersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AnimalGendersStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "animal_genders" */
export enum AnimalGendersUpdateColumn {
  /** column name */
  NAME = 'name',
}

export type AnimalGendersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AnimalGendersSetInput>;
  /** filter the rows which have to be updated */
  where: AnimalGendersBoolExp;
};

/** columns and relationships of "animal_species" */
export type AnimalSpecies = {
  __typename?: 'animal_species';
  name: Scalars['String'];
  /** An array relationship */
  treatments: Array<Treatments>;
  /** An aggregate relationship */
  treatments_aggregate: TreatmentsAggregate;
};

/** columns and relationships of "animal_species" */
export type AnimalSpeciesTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** columns and relationships of "animal_species" */
export type AnimalSpeciesTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** aggregated selection of "animal_species" */
export type AnimalSpeciesAggregate = {
  __typename?: 'animal_species_aggregate';
  aggregate?: Maybe<AnimalSpeciesAggregateFields>;
  nodes: Array<AnimalSpecies>;
};

/** aggregate fields of "animal_species" */
export type AnimalSpeciesAggregateFields = {
  __typename?: 'animal_species_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AnimalSpeciesMaxFields>;
  min?: Maybe<AnimalSpeciesMinFields>;
};

/** aggregate fields of "animal_species" */
export type AnimalSpeciesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AnimalSpeciesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "animal_species". All fields are combined with a logical 'AND'. */
export type AnimalSpeciesBoolExp = {
  _and?: InputMaybe<Array<AnimalSpeciesBoolExp>>;
  _not?: InputMaybe<AnimalSpeciesBoolExp>;
  _or?: InputMaybe<Array<AnimalSpeciesBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
  treatments?: InputMaybe<TreatmentsBoolExp>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateBoolExp>;
};

/** unique or primary key constraints on table "animal_species" */
export enum AnimalSpeciesConstraint {
  /** unique or primary key constraint on columns "name" */
  ANIMAL_SPECIES_PKEY = 'animal_species_pkey',
}

export enum AnimalSpeciesEnum {
  CAT = 'cat',
  DOG = 'dog',
}

/** Boolean expression to compare columns of type "animal_species_enum". All fields are combined with logical 'AND'. */
export type AnimalSpeciesEnumComparisonExp = {
  _eq?: InputMaybe<AnimalSpeciesEnum>;
  _in?: InputMaybe<Array<AnimalSpeciesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AnimalSpeciesEnum>;
  _nin?: InputMaybe<Array<AnimalSpeciesEnum>>;
};

/** input type for inserting data into table "animal_species" */
export type AnimalSpeciesInsertInput = {
  name?: InputMaybe<Scalars['String']>;
  treatments?: InputMaybe<TreatmentsArrRelInsertInput>;
};

/** aggregate max on columns */
export type AnimalSpeciesMaxFields = {
  __typename?: 'animal_species_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AnimalSpeciesMinFields = {
  __typename?: 'animal_species_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "animal_species" */
export type AnimalSpeciesMutationResponse = {
  __typename?: 'animal_species_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AnimalSpecies>;
};

/** input type for inserting object relation for remote table "animal_species" */
export type AnimalSpeciesObjRelInsertInput = {
  data: AnimalSpeciesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AnimalSpeciesOnConflict>;
};

/** on_conflict condition type for table "animal_species" */
export type AnimalSpeciesOnConflict = {
  constraint: AnimalSpeciesConstraint;
  update_columns?: Array<AnimalSpeciesUpdateColumn>;
  where?: InputMaybe<AnimalSpeciesBoolExp>;
};

/** Ordering options when selecting data from "animal_species". */
export type AnimalSpeciesOrderBy = {
  name?: InputMaybe<OrderBy>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateOrderBy>;
};

/** primary key columns input for table: animal_species */
export type AnimalSpeciesPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "animal_species" */
export enum AnimalSpeciesSelectColumn {
  /** column name */
  NAME = 'name',
}

/** input type for updating data in table "animal_species" */
export type AnimalSpeciesSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "animal_species" */
export type AnimalSpeciesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AnimalSpeciesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AnimalSpeciesStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "animal_species" */
export enum AnimalSpeciesUpdateColumn {
  /** column name */
  NAME = 'name',
}

export type AnimalSpeciesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AnimalSpeciesSetInput>;
  /** filter the rows which have to be updated */
  where: AnimalSpeciesBoolExp;
};

/** columns and relationships of "booking_request" */
export type BookingRequest = {
  __typename?: 'booking_request';
  animal_id: Scalars['String'];
  /** An object relationship */
  booking_request_status: BookingRequestStatus;
  /** An array relationship */
  booking_request_treatments: Array<BookingRequestTreatments>;
  /** An aggregate relationship */
  booking_request_treatments_aggregate: BookingRequestTreatmentsAggregate;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  created_user?: Maybe<Users>;
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['Int']>;
  status: BookingRequestStatusEnum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** columns and relationships of "booking_request" */
export type BookingRequestBookingRequestTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestTreatmentsOrderBy>>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

/** columns and relationships of "booking_request" */
export type BookingRequestBookingRequestTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestTreatmentsOrderBy>>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

/** aggregated selection of "booking_request" */
export type BookingRequestAggregate = {
  __typename?: 'booking_request_aggregate';
  aggregate?: Maybe<BookingRequestAggregateFields>;
  nodes: Array<BookingRequest>;
};

export type BookingRequestAggregateBoolExp = {
  count?: InputMaybe<BookingRequestAggregateBoolExpCount>;
};

export type BookingRequestAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<BookingRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BookingRequestBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "booking_request" */
export type BookingRequestAggregateFields = {
  __typename?: 'booking_request_aggregate_fields';
  avg?: Maybe<BookingRequestAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BookingRequestMaxFields>;
  min?: Maybe<BookingRequestMinFields>;
  stddev?: Maybe<BookingRequestStddevFields>;
  stddev_pop?: Maybe<BookingRequestStddevPopFields>;
  stddev_samp?: Maybe<BookingRequestStddevSampFields>;
  sum?: Maybe<BookingRequestSumFields>;
  var_pop?: Maybe<BookingRequestVarPopFields>;
  var_samp?: Maybe<BookingRequestVarSampFields>;
  variance?: Maybe<BookingRequestVarianceFields>;
};

/** aggregate fields of "booking_request" */
export type BookingRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BookingRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking_request" */
export type BookingRequestAggregateOrderBy = {
  avg?: InputMaybe<BookingRequestAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BookingRequestMaxOrderBy>;
  min?: InputMaybe<BookingRequestMinOrderBy>;
  stddev?: InputMaybe<BookingRequestStddevOrderBy>;
  stddev_pop?: InputMaybe<BookingRequestStddevPopOrderBy>;
  stddev_samp?: InputMaybe<BookingRequestStddevSampOrderBy>;
  sum?: InputMaybe<BookingRequestSumOrderBy>;
  var_pop?: InputMaybe<BookingRequestVarPopOrderBy>;
  var_samp?: InputMaybe<BookingRequestVarSampOrderBy>;
  variance?: InputMaybe<BookingRequestVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "booking_request" */
export type BookingRequestArrRelInsertInput = {
  data: Array<BookingRequestInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<BookingRequestOnConflict>;
};

/** aggregate avg on columns */
export type BookingRequestAvgFields = {
  __typename?: 'booking_request_avg_fields';
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "booking_request" */
export type BookingRequestAvgOrderBy = {
  location_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "booking_request". All fields are combined with a logical 'AND'. */
export type BookingRequestBoolExp = {
  _and?: InputMaybe<Array<BookingRequestBoolExp>>;
  _not?: InputMaybe<BookingRequestBoolExp>;
  _or?: InputMaybe<Array<BookingRequestBoolExp>>;
  animal_id?: InputMaybe<StringComparisonExp>;
  booking_request_status?: InputMaybe<BookingRequestStatusBoolExp>;
  booking_request_treatments?: InputMaybe<BookingRequestTreatmentsBoolExp>;
  booking_request_treatments_aggregate?: InputMaybe<BookingRequestTreatmentsAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by?: InputMaybe<UuidComparisonExp>;
  created_user?: InputMaybe<UsersBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  location?: InputMaybe<LocationsBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<BookingRequestStatusEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "booking_request" */
export enum BookingRequestConstraint {
  /** unique or primary key constraint on columns "id" */
  BOOKING_REQUEST_PKEY = 'booking_request_pkey',
}

/** input type for incrementing numeric columns in table "booking_request" */
export type BookingRequestIncInput = {
  location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "booking_request" */
export type BookingRequestInsertInput = {
  animal_id?: InputMaybe<Scalars['String']>;
  booking_request_status?: InputMaybe<BookingRequestStatusObjRelInsertInput>;
  booking_request_treatments?: InputMaybe<BookingRequestTreatmentsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_user?: InputMaybe<UsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<LocationsObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<BookingRequestStatusEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type BookingRequestMaxFields = {
  __typename?: 'booking_request_max_fields';
  animal_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "booking_request" */
export type BookingRequestMaxOrderBy = {
  animal_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type BookingRequestMinFields = {
  __typename?: 'booking_request_min_fields';
  animal_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "booking_request" */
export type BookingRequestMinOrderBy = {
  animal_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "booking_request" */
export type BookingRequestMutationResponse = {
  __typename?: 'booking_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BookingRequest>;
};

/** input type for inserting object relation for remote table "booking_request" */
export type BookingRequestObjRelInsertInput = {
  data: BookingRequestInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<BookingRequestOnConflict>;
};

/** on_conflict condition type for table "booking_request" */
export type BookingRequestOnConflict = {
  constraint: BookingRequestConstraint;
  update_columns?: Array<BookingRequestUpdateColumn>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

/** Ordering options when selecting data from "booking_request". */
export type BookingRequestOrderBy = {
  animal_id?: InputMaybe<OrderBy>;
  booking_request_status?: InputMaybe<BookingRequestStatusOrderBy>;
  booking_request_treatments_aggregate?: InputMaybe<BookingRequestTreatmentsAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<OrderBy>;
  created_user?: InputMaybe<UsersOrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<LocationsOrderBy>;
  location_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: booking_request */
export type BookingRequestPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "booking_request" */
export enum BookingRequestSelectColumn {
  /** column name */
  ANIMAL_ID = 'animal_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  CREATED_BY = 'created_by',
  /** column name */
  ID = 'id',
  /** column name */
  LOCATION_ID = 'location_id',
  /** column name */
  STATUS = 'status',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
}

/** input type for updating data in table "booking_request" */
export type BookingRequestSetInput = {
  animal_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<BookingRequestStatusEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "booking_request_status" */
export type BookingRequestStatus = {
  __typename?: 'booking_request_status';
  /** An array relationship */
  booking_requests: Array<BookingRequest>;
  /** An aggregate relationship */
  booking_requests_aggregate: BookingRequestAggregate;
  name: Scalars['String'];
};

/** columns and relationships of "booking_request_status" */
export type BookingRequestStatusBookingRequestsArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

/** columns and relationships of "booking_request_status" */
export type BookingRequestStatusBookingRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

/** aggregated selection of "booking_request_status" */
export type BookingRequestStatusAggregate = {
  __typename?: 'booking_request_status_aggregate';
  aggregate?: Maybe<BookingRequestStatusAggregateFields>;
  nodes: Array<BookingRequestStatus>;
};

/** aggregate fields of "booking_request_status" */
export type BookingRequestStatusAggregateFields = {
  __typename?: 'booking_request_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<BookingRequestStatusMaxFields>;
  min?: Maybe<BookingRequestStatusMinFields>;
};

/** aggregate fields of "booking_request_status" */
export type BookingRequestStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BookingRequestStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "booking_request_status". All fields are combined with a logical 'AND'. */
export type BookingRequestStatusBoolExp = {
  _and?: InputMaybe<Array<BookingRequestStatusBoolExp>>;
  _not?: InputMaybe<BookingRequestStatusBoolExp>;
  _or?: InputMaybe<Array<BookingRequestStatusBoolExp>>;
  booking_requests?: InputMaybe<BookingRequestBoolExp>;
  booking_requests_aggregate?: InputMaybe<BookingRequestAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "booking_request_status" */
export enum BookingRequestStatusConstraint {
  /** unique or primary key constraint on columns "name" */
  BOOKING_REQUEST_STATUS_PKEY = 'booking_request_status_pkey',
}

export enum BookingRequestStatusEnum {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  COMPLETED = 'completed',
}

/** Boolean expression to compare columns of type "booking_request_status_enum". All fields are combined with logical 'AND'. */
export type BookingRequestStatusEnumComparisonExp = {
  _eq?: InputMaybe<BookingRequestStatusEnum>;
  _in?: InputMaybe<Array<BookingRequestStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<BookingRequestStatusEnum>;
  _nin?: InputMaybe<Array<BookingRequestStatusEnum>>;
};

/** input type for inserting data into table "booking_request_status" */
export type BookingRequestStatusInsertInput = {
  booking_requests?: InputMaybe<BookingRequestArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type BookingRequestStatusMaxFields = {
  __typename?: 'booking_request_status_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type BookingRequestStatusMinFields = {
  __typename?: 'booking_request_status_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "booking_request_status" */
export type BookingRequestStatusMutationResponse = {
  __typename?: 'booking_request_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BookingRequestStatus>;
};

/** input type for inserting object relation for remote table "booking_request_status" */
export type BookingRequestStatusObjRelInsertInput = {
  data: BookingRequestStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<BookingRequestStatusOnConflict>;
};

/** on_conflict condition type for table "booking_request_status" */
export type BookingRequestStatusOnConflict = {
  constraint: BookingRequestStatusConstraint;
  update_columns?: Array<BookingRequestStatusUpdateColumn>;
  where?: InputMaybe<BookingRequestStatusBoolExp>;
};

/** Ordering options when selecting data from "booking_request_status". */
export type BookingRequestStatusOrderBy = {
  booking_requests_aggregate?: InputMaybe<BookingRequestAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: booking_request_status */
export type BookingRequestStatusPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "booking_request_status" */
export enum BookingRequestStatusSelectColumn {
  /** column name */
  NAME = 'name',
}

/** input type for updating data in table "booking_request_status" */
export type BookingRequestStatusSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "booking_request_status" */
export type BookingRequestStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: BookingRequestStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BookingRequestStatusStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "booking_request_status" */
export enum BookingRequestStatusUpdateColumn {
  /** column name */
  NAME = 'name',
}

export type BookingRequestStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookingRequestStatusSetInput>;
  /** filter the rows which have to be updated */
  where: BookingRequestStatusBoolExp;
};

/** aggregate stddev on columns */
export type BookingRequestStddevFields = {
  __typename?: 'booking_request_stddev_fields';
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "booking_request" */
export type BookingRequestStddevOrderBy = {
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type BookingRequestStddevPopFields = {
  __typename?: 'booking_request_stddev_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "booking_request" */
export type BookingRequestStddevPopOrderBy = {
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type BookingRequestStddevSampFields = {
  __typename?: 'booking_request_stddev_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "booking_request" */
export type BookingRequestStddevSampOrderBy = {
  location_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "booking_request" */
export type BookingRequestStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: BookingRequestStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BookingRequestStreamCursorValueInput = {
  animal_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<BookingRequestStatusEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type BookingRequestSumFields = {
  __typename?: 'booking_request_sum_fields';
  location_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "booking_request" */
export type BookingRequestSumOrderBy = {
  location_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "booking_request_treatments" */
export type BookingRequestTreatments = {
  __typename?: 'booking_request_treatments';
  /** An object relationship */
  booking_request: BookingRequest;
  booking_request_id: Scalars['uuid'];
  /** An object relationship */
  treatment: Treatments;
  treatment_id: Scalars['Int'];
};

/** aggregated selection of "booking_request_treatments" */
export type BookingRequestTreatmentsAggregate = {
  __typename?: 'booking_request_treatments_aggregate';
  aggregate?: Maybe<BookingRequestTreatmentsAggregateFields>;
  nodes: Array<BookingRequestTreatments>;
};

export type BookingRequestTreatmentsAggregateBoolExp = {
  count?: InputMaybe<BookingRequestTreatmentsAggregateBoolExpCount>;
};

export type BookingRequestTreatmentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BookingRequestTreatmentsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "booking_request_treatments" */
export type BookingRequestTreatmentsAggregateFields = {
  __typename?: 'booking_request_treatments_aggregate_fields';
  avg?: Maybe<BookingRequestTreatmentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BookingRequestTreatmentsMaxFields>;
  min?: Maybe<BookingRequestTreatmentsMinFields>;
  stddev?: Maybe<BookingRequestTreatmentsStddevFields>;
  stddev_pop?: Maybe<BookingRequestTreatmentsStddevPopFields>;
  stddev_samp?: Maybe<BookingRequestTreatmentsStddevSampFields>;
  sum?: Maybe<BookingRequestTreatmentsSumFields>;
  var_pop?: Maybe<BookingRequestTreatmentsVarPopFields>;
  var_samp?: Maybe<BookingRequestTreatmentsVarSampFields>;
  variance?: Maybe<BookingRequestTreatmentsVarianceFields>;
};

/** aggregate fields of "booking_request_treatments" */
export type BookingRequestTreatmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking_request_treatments" */
export type BookingRequestTreatmentsAggregateOrderBy = {
  avg?: InputMaybe<BookingRequestTreatmentsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BookingRequestTreatmentsMaxOrderBy>;
  min?: InputMaybe<BookingRequestTreatmentsMinOrderBy>;
  stddev?: InputMaybe<BookingRequestTreatmentsStddevOrderBy>;
  stddev_pop?: InputMaybe<BookingRequestTreatmentsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<BookingRequestTreatmentsStddevSampOrderBy>;
  sum?: InputMaybe<BookingRequestTreatmentsSumOrderBy>;
  var_pop?: InputMaybe<BookingRequestTreatmentsVarPopOrderBy>;
  var_samp?: InputMaybe<BookingRequestTreatmentsVarSampOrderBy>;
  variance?: InputMaybe<BookingRequestTreatmentsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "booking_request_treatments" */
export type BookingRequestTreatmentsArrRelInsertInput = {
  data: Array<BookingRequestTreatmentsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<BookingRequestTreatmentsOnConflict>;
};

/** aggregate avg on columns */
export type BookingRequestTreatmentsAvgFields = {
  __typename?: 'booking_request_treatments_avg_fields';
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsAvgOrderBy = {
  treatment_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "booking_request_treatments". All fields are combined with a logical 'AND'. */
export type BookingRequestTreatmentsBoolExp = {
  _and?: InputMaybe<Array<BookingRequestTreatmentsBoolExp>>;
  _not?: InputMaybe<BookingRequestTreatmentsBoolExp>;
  _or?: InputMaybe<Array<BookingRequestTreatmentsBoolExp>>;
  booking_request?: InputMaybe<BookingRequestBoolExp>;
  booking_request_id?: InputMaybe<UuidComparisonExp>;
  treatment?: InputMaybe<TreatmentsBoolExp>;
  treatment_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "booking_request_treatments" */
export enum BookingRequestTreatmentsConstraint {
  /** unique or primary key constraint on columns "booking_request_id", "treatment_id" */
  BOOKING_REQUEST_TREATMENTS_PKEY = 'booking_request_treatments_pkey',
}

/** input type for incrementing numeric columns in table "booking_request_treatments" */
export type BookingRequestTreatmentsIncInput = {
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "booking_request_treatments" */
export type BookingRequestTreatmentsInsertInput = {
  booking_request?: InputMaybe<BookingRequestObjRelInsertInput>;
  booking_request_id?: InputMaybe<Scalars['uuid']>;
  treatment?: InputMaybe<TreatmentsObjRelInsertInput>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type BookingRequestTreatmentsMaxFields = {
  __typename?: 'booking_request_treatments_max_fields';
  booking_request_id?: Maybe<Scalars['uuid']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsMaxOrderBy = {
  booking_request_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type BookingRequestTreatmentsMinFields = {
  __typename?: 'booking_request_treatments_min_fields';
  booking_request_id?: Maybe<Scalars['uuid']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsMinOrderBy = {
  booking_request_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "booking_request_treatments" */
export type BookingRequestTreatmentsMutationResponse = {
  __typename?: 'booking_request_treatments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BookingRequestTreatments>;
};

/** on_conflict condition type for table "booking_request_treatments" */
export type BookingRequestTreatmentsOnConflict = {
  constraint: BookingRequestTreatmentsConstraint;
  update_columns?: Array<BookingRequestTreatmentsUpdateColumn>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

/** Ordering options when selecting data from "booking_request_treatments". */
export type BookingRequestTreatmentsOrderBy = {
  booking_request?: InputMaybe<BookingRequestOrderBy>;
  booking_request_id?: InputMaybe<OrderBy>;
  treatment?: InputMaybe<TreatmentsOrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: booking_request_treatments */
export type BookingRequestTreatmentsPkColumnsInput = {
  booking_request_id: Scalars['uuid'];
  treatment_id: Scalars['Int'];
};

/** select columns of table "booking_request_treatments" */
export enum BookingRequestTreatmentsSelectColumn {
  /** column name */
  BOOKING_REQUEST_ID = 'booking_request_id',
  /** column name */
  TREATMENT_ID = 'treatment_id',
}

/** input type for updating data in table "booking_request_treatments" */
export type BookingRequestTreatmentsSetInput = {
  booking_request_id?: InputMaybe<Scalars['uuid']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BookingRequestTreatmentsStddevFields = {
  __typename?: 'booking_request_treatments_stddev_fields';
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsStddevOrderBy = {
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type BookingRequestTreatmentsStddevPopFields = {
  __typename?: 'booking_request_treatments_stddev_pop_fields';
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsStddevPopOrderBy = {
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type BookingRequestTreatmentsStddevSampFields = {
  __typename?: 'booking_request_treatments_stddev_samp_fields';
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsStddevSampOrderBy = {
  treatment_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "booking_request_treatments" */
export type BookingRequestTreatmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: BookingRequestTreatmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BookingRequestTreatmentsStreamCursorValueInput = {
  booking_request_id?: InputMaybe<Scalars['uuid']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type BookingRequestTreatmentsSumFields = {
  __typename?: 'booking_request_treatments_sum_fields';
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsSumOrderBy = {
  treatment_id?: InputMaybe<OrderBy>;
};

/** update columns of table "booking_request_treatments" */
export enum BookingRequestTreatmentsUpdateColumn {
  /** column name */
  BOOKING_REQUEST_ID = 'booking_request_id',
  /** column name */
  TREATMENT_ID = 'treatment_id',
}

export type BookingRequestTreatmentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BookingRequestTreatmentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookingRequestTreatmentsSetInput>;
  /** filter the rows which have to be updated */
  where: BookingRequestTreatmentsBoolExp;
};

/** aggregate var_pop on columns */
export type BookingRequestTreatmentsVarPopFields = {
  __typename?: 'booking_request_treatments_var_pop_fields';
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsVarPopOrderBy = {
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type BookingRequestTreatmentsVarSampFields = {
  __typename?: 'booking_request_treatments_var_samp_fields';
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsVarSampOrderBy = {
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type BookingRequestTreatmentsVarianceFields = {
  __typename?: 'booking_request_treatments_variance_fields';
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "booking_request_treatments" */
export type BookingRequestTreatmentsVarianceOrderBy = {
  treatment_id?: InputMaybe<OrderBy>;
};

/** update columns of table "booking_request" */
export enum BookingRequestUpdateColumn {
  /** column name */
  ANIMAL_ID = 'animal_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  CREATED_BY = 'created_by',
  /** column name */
  ID = 'id',
  /** column name */
  LOCATION_ID = 'location_id',
  /** column name */
  STATUS = 'status',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
}

export type BookingRequestUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BookingRequestIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookingRequestSetInput>;
  /** filter the rows which have to be updated */
  where: BookingRequestBoolExp;
};

/** aggregate var_pop on columns */
export type BookingRequestVarPopFields = {
  __typename?: 'booking_request_var_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "booking_request" */
export type BookingRequestVarPopOrderBy = {
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type BookingRequestVarSampFields = {
  __typename?: 'booking_request_var_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "booking_request" */
export type BookingRequestVarSampOrderBy = {
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type BookingRequestVarianceFields = {
  __typename?: 'booking_request_variance_fields';
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "booking_request" */
export type BookingRequestVarianceOrderBy = {
  location_id?: InputMaybe<OrderBy>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  ASC = 'ASC',
  /** descending ordering of the cursor */
  DESC = 'DESC',
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "employee_types" */
export type EmployeeTypes = {
  __typename?: 'employee_types';
  /** An array relationship */
  employees: Array<Employees>;
  /** An aggregate relationship */
  employees_aggregate: EmployeesAggregate;
  name: Scalars['String'];
};

/** columns and relationships of "employee_types" */
export type EmployeeTypesEmployeesArgs = {
  distinct_on?: InputMaybe<Array<EmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeesOrderBy>>;
  where?: InputMaybe<EmployeesBoolExp>;
};

/** columns and relationships of "employee_types" */
export type EmployeeTypesEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeesOrderBy>>;
  where?: InputMaybe<EmployeesBoolExp>;
};

/** aggregated selection of "employee_types" */
export type EmployeeTypesAggregate = {
  __typename?: 'employee_types_aggregate';
  aggregate?: Maybe<EmployeeTypesAggregateFields>;
  nodes: Array<EmployeeTypes>;
};

/** aggregate fields of "employee_types" */
export type EmployeeTypesAggregateFields = {
  __typename?: 'employee_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmployeeTypesMaxFields>;
  min?: Maybe<EmployeeTypesMinFields>;
};

/** aggregate fields of "employee_types" */
export type EmployeeTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EmployeeTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "employee_types". All fields are combined with a logical 'AND'. */
export type EmployeeTypesBoolExp = {
  _and?: InputMaybe<Array<EmployeeTypesBoolExp>>;
  _not?: InputMaybe<EmployeeTypesBoolExp>;
  _or?: InputMaybe<Array<EmployeeTypesBoolExp>>;
  employees?: InputMaybe<EmployeesBoolExp>;
  employees_aggregate?: InputMaybe<EmployeesAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "employee_types" */
export enum EmployeeTypesConstraint {
  /** unique or primary key constraint on columns "name" */
  EMPLOYEE_TYPES_PKEY = 'employee_types_pkey',
}

export enum EmployeeTypesEnum {
  VETERINARY = 'veterinary',
  VETERINARY_ASSISTANT = 'veterinary_assistant',
}

/** Boolean expression to compare columns of type "employee_types_enum". All fields are combined with logical 'AND'. */
export type EmployeeTypesEnumComparisonExp = {
  _eq?: InputMaybe<EmployeeTypesEnum>;
  _in?: InputMaybe<Array<EmployeeTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmployeeTypesEnum>;
  _nin?: InputMaybe<Array<EmployeeTypesEnum>>;
};

/** input type for inserting data into table "employee_types" */
export type EmployeeTypesInsertInput = {
  employees?: InputMaybe<EmployeesArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EmployeeTypesMaxFields = {
  __typename?: 'employee_types_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EmployeeTypesMinFields = {
  __typename?: 'employee_types_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "employee_types" */
export type EmployeeTypesMutationResponse = {
  __typename?: 'employee_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmployeeTypes>;
};

/** input type for inserting object relation for remote table "employee_types" */
export type EmployeeTypesObjRelInsertInput = {
  data: EmployeeTypesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EmployeeTypesOnConflict>;
};

/** on_conflict condition type for table "employee_types" */
export type EmployeeTypesOnConflict = {
  constraint: EmployeeTypesConstraint;
  update_columns?: Array<EmployeeTypesUpdateColumn>;
  where?: InputMaybe<EmployeeTypesBoolExp>;
};

/** Ordering options when selecting data from "employee_types". */
export type EmployeeTypesOrderBy = {
  employees_aggregate?: InputMaybe<EmployeesAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: employee_types */
export type EmployeeTypesPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "employee_types" */
export enum EmployeeTypesSelectColumn {
  /** column name */
  NAME = 'name',
}

/** input type for updating data in table "employee_types" */
export type EmployeeTypesSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "employee_types" */
export type EmployeeTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EmployeeTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EmployeeTypesStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "employee_types" */
export enum EmployeeTypesUpdateColumn {
  /** column name */
  NAME = 'name',
}

export type EmployeeTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmployeeTypesSetInput>;
  /** filter the rows which have to be updated */
  where: EmployeeTypesBoolExp;
};

/** columns and relationships of "employees" */
export type Employees = {
  __typename?: 'employees';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  employee_type: EmployeeTypes;
  id: Scalars['Int'];
  /** An array relationship */
  location_employees: Array<LocationEmployees>;
  /** An aggregate relationship */
  location_employees_aggregate: LocationEmployeesAggregate;
  name: Scalars['String'];
  provet_id: Scalars['String'];
  /** An array relationship */
  treatment_employees: Array<TreatmentEmployees>;
  /** An aggregate relationship */
  treatment_employees_aggregate: TreatmentEmployeesAggregate;
  type: EmployeeTypesEnum;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "employees" */
export type EmployeesLocationEmployeesArgs = {
  distinct_on?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationEmployeesOrderBy>>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

/** columns and relationships of "employees" */
export type EmployeesLocationEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationEmployeesOrderBy>>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

/** columns and relationships of "employees" */
export type EmployeesTreatmentEmployeesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentEmployeesOrderBy>>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

/** columns and relationships of "employees" */
export type EmployeesTreatmentEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentEmployeesOrderBy>>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

/** aggregated selection of "employees" */
export type EmployeesAggregate = {
  __typename?: 'employees_aggregate';
  aggregate?: Maybe<EmployeesAggregateFields>;
  nodes: Array<Employees>;
};

export type EmployeesAggregateBoolExp = {
  count?: InputMaybe<EmployeesAggregateBoolExpCount>;
};

export type EmployeesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EmployeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EmployeesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "employees" */
export type EmployeesAggregateFields = {
  __typename?: 'employees_aggregate_fields';
  avg?: Maybe<EmployeesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EmployeesMaxFields>;
  min?: Maybe<EmployeesMinFields>;
  stddev?: Maybe<EmployeesStddevFields>;
  stddev_pop?: Maybe<EmployeesStddevPopFields>;
  stddev_samp?: Maybe<EmployeesStddevSampFields>;
  sum?: Maybe<EmployeesSumFields>;
  var_pop?: Maybe<EmployeesVarPopFields>;
  var_samp?: Maybe<EmployeesVarSampFields>;
  variance?: Maybe<EmployeesVarianceFields>;
};

/** aggregate fields of "employees" */
export type EmployeesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EmployeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employees" */
export type EmployeesAggregateOrderBy = {
  avg?: InputMaybe<EmployeesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EmployeesMaxOrderBy>;
  min?: InputMaybe<EmployeesMinOrderBy>;
  stddev?: InputMaybe<EmployeesStddevOrderBy>;
  stddev_pop?: InputMaybe<EmployeesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EmployeesStddevSampOrderBy>;
  sum?: InputMaybe<EmployeesSumOrderBy>;
  var_pop?: InputMaybe<EmployeesVarPopOrderBy>;
  var_samp?: InputMaybe<EmployeesVarSampOrderBy>;
  variance?: InputMaybe<EmployeesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "employees" */
export type EmployeesArrRelInsertInput = {
  data: Array<EmployeesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EmployeesOnConflict>;
};

/** aggregate avg on columns */
export type EmployeesAvgFields = {
  __typename?: 'employees_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employees" */
export type EmployeesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "employees". All fields are combined with a logical 'AND'. */
export type EmployeesBoolExp = {
  _and?: InputMaybe<Array<EmployeesBoolExp>>;
  _not?: InputMaybe<EmployeesBoolExp>;
  _or?: InputMaybe<Array<EmployeesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  employee_type?: InputMaybe<EmployeeTypesBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  location_employees?: InputMaybe<LocationEmployeesBoolExp>;
  location_employees_aggregate?: InputMaybe<LocationEmployeesAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  provet_id?: InputMaybe<StringComparisonExp>;
  treatment_employees?: InputMaybe<TreatmentEmployeesBoolExp>;
  treatment_employees_aggregate?: InputMaybe<TreatmentEmployeesAggregateBoolExp>;
  type?: InputMaybe<EmployeeTypesEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "employees" */
export enum EmployeesConstraint {
  /** unique or primary key constraint on columns "id" */
  EMPLOYEES_PKEY = 'employees_pkey',
}

/** input type for incrementing numeric columns in table "employees" */
export type EmployeesIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "employees" */
export type EmployeesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employee_type?: InputMaybe<EmployeeTypesObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  location_employees?: InputMaybe<LocationEmployeesArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  provet_id?: InputMaybe<Scalars['String']>;
  treatment_employees?: InputMaybe<TreatmentEmployeesArrRelInsertInput>;
  type?: InputMaybe<EmployeeTypesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmployeesMaxFields = {
  __typename?: 'employees_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provet_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "employees" */
export type EmployeesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EmployeesMinFields = {
  __typename?: 'employees_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provet_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "employees" */
export type EmployeesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "employees" */
export type EmployeesMutationResponse = {
  __typename?: 'employees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employees>;
};

/** input type for inserting object relation for remote table "employees" */
export type EmployeesObjRelInsertInput = {
  data: EmployeesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EmployeesOnConflict>;
};

/** on_conflict condition type for table "employees" */
export type EmployeesOnConflict = {
  constraint: EmployeesConstraint;
  update_columns?: Array<EmployeesUpdateColumn>;
  where?: InputMaybe<EmployeesBoolExp>;
};

/** Ordering options when selecting data from "employees". */
export type EmployeesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  employee_type?: InputMaybe<EmployeeTypesOrderBy>;
  id?: InputMaybe<OrderBy>;
  location_employees_aggregate?: InputMaybe<LocationEmployeesAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  treatment_employees_aggregate?: InputMaybe<TreatmentEmployeesAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: employees */
export type EmployeesPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "employees" */
export enum EmployeesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATED_AT = 'updated_at',
}

/** input type for updating data in table "employees" */
export type EmployeesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provet_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmployeeTypesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EmployeesStddevFields = {
  __typename?: 'employees_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employees" */
export type EmployeesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EmployeesStddevPopFields = {
  __typename?: 'employees_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employees" */
export type EmployeesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EmployeesStddevSampFields = {
  __typename?: 'employees_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employees" */
export type EmployeesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "employees" */
export type EmployeesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EmployeesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EmployeesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provet_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmployeeTypesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type EmployeesSumFields = {
  __typename?: 'employees_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "employees" */
export type EmployeesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "employees" */
export enum EmployeesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATED_AT = 'updated_at',
}

export type EmployeesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EmployeesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmployeesSetInput>;
  /** filter the rows which have to be updated */
  where: EmployeesBoolExp;
};

/** aggregate var_pop on columns */
export type EmployeesVarPopFields = {
  __typename?: 'employees_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employees" */
export type EmployeesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EmployeesVarSampFields = {
  __typename?: 'employees_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employees" */
export type EmployeesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EmployeesVarianceFields = {
  __typename?: 'employees_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employees" */
export type EmployeesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "icons" */
export type Icons = {
  __typename?: 'icons';
  name: Scalars['String'];
  /** An array relationship */
  treatment_groups: Array<TreatmentGroups>;
  /** An aggregate relationship */
  treatment_groups_aggregate: TreatmentGroupsAggregate;
};

/** columns and relationships of "icons" */
export type IconsTreatmentGroupsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentGroupsOrderBy>>;
  where?: InputMaybe<TreatmentGroupsBoolExp>;
};

/** columns and relationships of "icons" */
export type IconsTreatmentGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentGroupsOrderBy>>;
  where?: InputMaybe<TreatmentGroupsBoolExp>;
};

/** aggregated selection of "icons" */
export type IconsAggregate = {
  __typename?: 'icons_aggregate';
  aggregate?: Maybe<IconsAggregateFields>;
  nodes: Array<Icons>;
};

/** aggregate fields of "icons" */
export type IconsAggregateFields = {
  __typename?: 'icons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IconsMaxFields>;
  min?: Maybe<IconsMinFields>;
};

/** aggregate fields of "icons" */
export type IconsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IconsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "icons". All fields are combined with a logical 'AND'. */
export type IconsBoolExp = {
  _and?: InputMaybe<Array<IconsBoolExp>>;
  _not?: InputMaybe<IconsBoolExp>;
  _or?: InputMaybe<Array<IconsBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
  treatment_groups?: InputMaybe<TreatmentGroupsBoolExp>;
  treatment_groups_aggregate?: InputMaybe<TreatmentGroupsAggregateBoolExp>;
};

/** unique or primary key constraints on table "icons" */
export enum IconsConstraint {
  /** unique or primary key constraint on columns "name" */
  ICONS_PKEY = 'icons_pkey',
}

export enum IconsEnum {
  BONE = 'bone',
  HEART = 'heart',
  JOURNAL = 'journal',
  SURGERY = 'surgery',
  SYRINGE = 'syringe',
  TESTS = 'tests',
  TOOTH = 'tooth',
  TRAVEL = 'travel',
}

/** Boolean expression to compare columns of type "icons_enum". All fields are combined with logical 'AND'. */
export type IconsEnumComparisonExp = {
  _eq?: InputMaybe<IconsEnum>;
  _in?: InputMaybe<Array<IconsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<IconsEnum>;
  _nin?: InputMaybe<Array<IconsEnum>>;
};

/** input type for inserting data into table "icons" */
export type IconsInsertInput = {
  name?: InputMaybe<Scalars['String']>;
  treatment_groups?: InputMaybe<TreatmentGroupsArrRelInsertInput>;
};

/** aggregate max on columns */
export type IconsMaxFields = {
  __typename?: 'icons_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IconsMinFields = {
  __typename?: 'icons_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icons" */
export type IconsMutationResponse = {
  __typename?: 'icons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icons>;
};

/** input type for inserting object relation for remote table "icons" */
export type IconsObjRelInsertInput = {
  data: IconsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IconsOnConflict>;
};

/** on_conflict condition type for table "icons" */
export type IconsOnConflict = {
  constraint: IconsConstraint;
  update_columns?: Array<IconsUpdateColumn>;
  where?: InputMaybe<IconsBoolExp>;
};

/** Ordering options when selecting data from "icons". */
export type IconsOrderBy = {
  name?: InputMaybe<OrderBy>;
  treatment_groups_aggregate?: InputMaybe<TreatmentGroupsAggregateOrderBy>;
};

/** primary key columns input for table: icons */
export type IconsPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "icons" */
export enum IconsSelectColumn {
  /** column name */
  NAME = 'name',
}

/** input type for updating data in table "icons" */
export type IconsSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "icons" */
export type IconsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IconsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IconsStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "icons" */
export enum IconsUpdateColumn {
  /** column name */
  NAME = 'name',
}

export type IconsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IconsSetInput>;
  /** filter the rows which have to be updated */
  where: IconsBoolExp;
};

/** columns and relationships of "location_employees" */
export type LocationEmployees = {
  __typename?: 'location_employees';
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['Int'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['Int'];
};

/** aggregated selection of "location_employees" */
export type LocationEmployeesAggregate = {
  __typename?: 'location_employees_aggregate';
  aggregate?: Maybe<LocationEmployeesAggregateFields>;
  nodes: Array<LocationEmployees>;
};

export type LocationEmployeesAggregateBoolExp = {
  count?: InputMaybe<LocationEmployeesAggregateBoolExpCount>;
};

export type LocationEmployeesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationEmployeesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "location_employees" */
export type LocationEmployeesAggregateFields = {
  __typename?: 'location_employees_aggregate_fields';
  avg?: Maybe<LocationEmployeesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LocationEmployeesMaxFields>;
  min?: Maybe<LocationEmployeesMinFields>;
  stddev?: Maybe<LocationEmployeesStddevFields>;
  stddev_pop?: Maybe<LocationEmployeesStddevPopFields>;
  stddev_samp?: Maybe<LocationEmployeesStddevSampFields>;
  sum?: Maybe<LocationEmployeesSumFields>;
  var_pop?: Maybe<LocationEmployeesVarPopFields>;
  var_samp?: Maybe<LocationEmployeesVarSampFields>;
  variance?: Maybe<LocationEmployeesVarianceFields>;
};

/** aggregate fields of "location_employees" */
export type LocationEmployeesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "location_employees" */
export type LocationEmployeesAggregateOrderBy = {
  avg?: InputMaybe<LocationEmployeesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LocationEmployeesMaxOrderBy>;
  min?: InputMaybe<LocationEmployeesMinOrderBy>;
  stddev?: InputMaybe<LocationEmployeesStddevOrderBy>;
  stddev_pop?: InputMaybe<LocationEmployeesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LocationEmployeesStddevSampOrderBy>;
  sum?: InputMaybe<LocationEmployeesSumOrderBy>;
  var_pop?: InputMaybe<LocationEmployeesVarPopOrderBy>;
  var_samp?: InputMaybe<LocationEmployeesVarSampOrderBy>;
  variance?: InputMaybe<LocationEmployeesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "location_employees" */
export type LocationEmployeesArrRelInsertInput = {
  data: Array<LocationEmployeesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LocationEmployeesOnConflict>;
};

/** aggregate avg on columns */
export type LocationEmployeesAvgFields = {
  __typename?: 'location_employees_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "location_employees" */
export type LocationEmployeesAvgOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "location_employees". All fields are combined with a logical 'AND'. */
export type LocationEmployeesBoolExp = {
  _and?: InputMaybe<Array<LocationEmployeesBoolExp>>;
  _not?: InputMaybe<LocationEmployeesBoolExp>;
  _or?: InputMaybe<Array<LocationEmployeesBoolExp>>;
  employee?: InputMaybe<EmployeesBoolExp>;
  employee_id?: InputMaybe<IntComparisonExp>;
  location?: InputMaybe<LocationsBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "location_employees" */
export enum LocationEmployeesConstraint {
  /** unique or primary key constraint on columns "employee_id", "location_id" */
  LOCATION_EMPLOYEES_PKEY = 'location_employees_pkey',
}

/** input type for incrementing numeric columns in table "location_employees" */
export type LocationEmployeesIncInput = {
  employee_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "location_employees" */
export type LocationEmployeesInsertInput = {
  employee?: InputMaybe<EmployeesObjRelInsertInput>;
  employee_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<LocationsObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type LocationEmployeesMaxFields = {
  __typename?: 'location_employees_max_fields';
  employee_id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "location_employees" */
export type LocationEmployeesMaxOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LocationEmployeesMinFields = {
  __typename?: 'location_employees_min_fields';
  employee_id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "location_employees" */
export type LocationEmployeesMinOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "location_employees" */
export type LocationEmployeesMutationResponse = {
  __typename?: 'location_employees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LocationEmployees>;
};

/** on_conflict condition type for table "location_employees" */
export type LocationEmployeesOnConflict = {
  constraint: LocationEmployeesConstraint;
  update_columns?: Array<LocationEmployeesUpdateColumn>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

/** Ordering options when selecting data from "location_employees". */
export type LocationEmployeesOrderBy = {
  employee?: InputMaybe<EmployeesOrderBy>;
  employee_id?: InputMaybe<OrderBy>;
  location?: InputMaybe<LocationsOrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: location_employees */
export type LocationEmployeesPkColumnsInput = {
  employee_id: Scalars['Int'];
  location_id: Scalars['Int'];
};

/** select columns of table "location_employees" */
export enum LocationEmployeesSelectColumn {
  /** column name */
  EMPLOYEE_ID = 'employee_id',
  /** column name */
  LOCATION_ID = 'location_id',
}

/** input type for updating data in table "location_employees" */
export type LocationEmployeesSetInput = {
  employee_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LocationEmployeesStddevFields = {
  __typename?: 'location_employees_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "location_employees" */
export type LocationEmployeesStddevOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LocationEmployeesStddevPopFields = {
  __typename?: 'location_employees_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "location_employees" */
export type LocationEmployeesStddevPopOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LocationEmployeesStddevSampFields = {
  __typename?: 'location_employees_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "location_employees" */
export type LocationEmployeesStddevSampOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "location_employees" */
export type LocationEmployeesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LocationEmployeesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LocationEmployeesStreamCursorValueInput = {
  employee_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type LocationEmployeesSumFields = {
  __typename?: 'location_employees_sum_fields';
  employee_id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "location_employees" */
export type LocationEmployeesSumOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** update columns of table "location_employees" */
export enum LocationEmployeesUpdateColumn {
  /** column name */
  EMPLOYEE_ID = 'employee_id',
  /** column name */
  LOCATION_ID = 'location_id',
}

export type LocationEmployeesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LocationEmployeesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LocationEmployeesSetInput>;
  /** filter the rows which have to be updated */
  where: LocationEmployeesBoolExp;
};

/** aggregate var_pop on columns */
export type LocationEmployeesVarPopFields = {
  __typename?: 'location_employees_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "location_employees" */
export type LocationEmployeesVarPopOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LocationEmployeesVarSampFields = {
  __typename?: 'location_employees_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "location_employees" */
export type LocationEmployeesVarSampOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LocationEmployeesVarianceFields = {
  __typename?: 'location_employees_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "location_employees" */
export type LocationEmployeesVarianceOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  address: Scalars['String'];
  /** An array relationship */
  booking_requests: Array<BookingRequest>;
  /** An aggregate relationship */
  booking_requests_aggregate: BookingRequestAggregate;
  city: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  location_employees: Array<LocationEmployees>;
  /** An aggregate relationship */
  location_employees_aggregate: LocationEmployeesAggregate;
  opening_date: Scalars['date'];
  provet_id: Scalars['String'];
  /** An array relationship */
  resources: Array<Resources>;
  /** An aggregate relationship */
  resources_aggregate: ResourcesAggregate;
  /** An array relationship */
  shift_types: Array<ShiftTypes>;
  /** An aggregate relationship */
  shift_types_aggregate: ShiftTypesAggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zip_code: Scalars['String'];
};

/** columns and relationships of "locations" */
export type LocationsBookingRequestsArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

/** columns and relationships of "locations" */
export type LocationsBookingRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

/** columns and relationships of "locations" */
export type LocationsLocationEmployeesArgs = {
  distinct_on?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationEmployeesOrderBy>>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

/** columns and relationships of "locations" */
export type LocationsLocationEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationEmployeesOrderBy>>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

/** columns and relationships of "locations" */
export type LocationsResourcesArgs = {
  distinct_on?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

/** columns and relationships of "locations" */
export type LocationsResourcesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

/** columns and relationships of "locations" */
export type LocationsShiftTypesArgs = {
  distinct_on?: InputMaybe<Array<ShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShiftTypesOrderBy>>;
  where?: InputMaybe<ShiftTypesBoolExp>;
};

/** columns and relationships of "locations" */
export type LocationsShiftTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShiftTypesOrderBy>>;
  where?: InputMaybe<ShiftTypesBoolExp>;
};

/** aggregated selection of "locations" */
export type LocationsAggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<LocationsAggregateFields>;
  nodes: Array<Locations>;
};

/** aggregate fields of "locations" */
export type LocationsAggregateFields = {
  __typename?: 'locations_aggregate_fields';
  avg?: Maybe<LocationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LocationsMaxFields>;
  min?: Maybe<LocationsMinFields>;
  stddev?: Maybe<LocationsStddevFields>;
  stddev_pop?: Maybe<LocationsStddevPopFields>;
  stddev_samp?: Maybe<LocationsStddevSampFields>;
  sum?: Maybe<LocationsSumFields>;
  var_pop?: Maybe<LocationsVarPopFields>;
  var_samp?: Maybe<LocationsVarSampFields>;
  variance?: Maybe<LocationsVarianceFields>;
};

/** aggregate fields of "locations" */
export type LocationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LocationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LocationsAvgFields = {
  __typename?: 'locations_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type LocationsBoolExp = {
  _and?: InputMaybe<Array<LocationsBoolExp>>;
  _not?: InputMaybe<LocationsBoolExp>;
  _or?: InputMaybe<Array<LocationsBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  booking_requests?: InputMaybe<BookingRequestBoolExp>;
  booking_requests_aggregate?: InputMaybe<BookingRequestAggregateBoolExp>;
  city?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  location_employees?: InputMaybe<LocationEmployeesBoolExp>;
  location_employees_aggregate?: InputMaybe<LocationEmployeesAggregateBoolExp>;
  opening_date?: InputMaybe<DateComparisonExp>;
  provet_id?: InputMaybe<StringComparisonExp>;
  resources?: InputMaybe<ResourcesBoolExp>;
  resources_aggregate?: InputMaybe<ResourcesAggregateBoolExp>;
  shift_types?: InputMaybe<ShiftTypesBoolExp>;
  shift_types_aggregate?: InputMaybe<ShiftTypesAggregateBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  zip_code?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "locations" */
export enum LocationsConstraint {
  /** unique or primary key constraint on columns "id" */
  LOCATIONS_PKEY = 'locations_pkey',
}

/** input type for incrementing numeric columns in table "locations" */
export type LocationsIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "locations" */
export type LocationsInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  booking_requests?: InputMaybe<BookingRequestArrRelInsertInput>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location_employees?: InputMaybe<LocationEmployeesArrRelInsertInput>;
  opening_date?: InputMaybe<Scalars['date']>;
  provet_id?: InputMaybe<Scalars['String']>;
  resources?: InputMaybe<ResourcesArrRelInsertInput>;
  shift_types?: InputMaybe<ShiftTypesArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LocationsMaxFields = {
  __typename?: 'locations_max_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  opening_date?: Maybe<Scalars['date']>;
  provet_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LocationsMinFields = {
  __typename?: 'locations_min_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  opening_date?: Maybe<Scalars['date']>;
  provet_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "locations" */
export type LocationsMutationResponse = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type LocationsObjRelInsertInput = {
  data: LocationsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<LocationsOnConflict>;
};

/** on_conflict condition type for table "locations" */
export type LocationsOnConflict = {
  constraint: LocationsConstraint;
  update_columns?: Array<LocationsUpdateColumn>;
  where?: InputMaybe<LocationsBoolExp>;
};

/** Ordering options when selecting data from "locations". */
export type LocationsOrderBy = {
  address?: InputMaybe<OrderBy>;
  booking_requests_aggregate?: InputMaybe<BookingRequestAggregateOrderBy>;
  city?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location_employees_aggregate?: InputMaybe<LocationEmployeesAggregateOrderBy>;
  opening_date?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  resources_aggregate?: InputMaybe<ResourcesAggregateOrderBy>;
  shift_types_aggregate?: InputMaybe<ShiftTypesAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zip_code?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: locations */
export type LocationsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "locations" */
export enum LocationsSelectColumn {
  /** column name */
  ADDRESS = 'address',
  /** column name */
  CITY = 'city',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  OPENING_DATE = 'opening_date',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  ZIP_CODE = 'zip_code',
}

/** input type for updating data in table "locations" */
export type LocationsSetInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  opening_date?: InputMaybe<Scalars['date']>;
  provet_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LocationsStddevFields = {
  __typename?: 'locations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LocationsStddevPopFields = {
  __typename?: 'locations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LocationsStddevSampFields = {
  __typename?: 'locations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "locations" */
export type LocationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LocationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LocationsStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  opening_date?: InputMaybe<Scalars['date']>;
  provet_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type LocationsSumFields = {
  __typename?: 'locations_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "locations" */
export enum LocationsUpdateColumn {
  /** column name */
  ADDRESS = 'address',
  /** column name */
  CITY = 'city',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  OPENING_DATE = 'opening_date',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  ZIP_CODE = 'zip_code',
}

export type LocationsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LocationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LocationsSetInput>;
  /** filter the rows which have to be updated */
  where: LocationsBoolExp;
};

/** aggregate var_pop on columns */
export type LocationsVarPopFields = {
  __typename?: 'locations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LocationsVarSampFields = {
  __typename?: 'locations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LocationsVarianceFields = {
  __typename?: 'locations_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** createAnimal */
  createAnimal?: Maybe<Animal>;
  /** createBooking */
  createBooking?: Maybe<Booking>;
  /** createBookingFromRequest */
  createBookingFromRequest?: Maybe<Booking>;
  /** createBookingRequest */
  createBookingRequest?: Maybe<IdOutput>;
  /** createMyAnimal */
  createMyAnimal?: Maybe<Animal>;
  /** createUser */
  createUser?: Maybe<IdOutput>;
  /** createUserFromProvet */
  createUserFromProvet?: Maybe<IdOutput>;
  /** deleteBooking */
  deleteBooking?: Maybe<Booking>;
  /** deleteMyAnimal */
  deleteMyAnimal?: Maybe<IdOutput>;
  /** delete data from the table: "animal_genders" */
  delete_animal_genders?: Maybe<AnimalGendersMutationResponse>;
  /** delete single row from the table: "animal_genders" */
  delete_animal_genders_by_pk?: Maybe<AnimalGenders>;
  /** delete data from the table: "animal_species" */
  delete_animal_species?: Maybe<AnimalSpeciesMutationResponse>;
  /** delete single row from the table: "animal_species" */
  delete_animal_species_by_pk?: Maybe<AnimalSpecies>;
  /** delete data from the table: "booking_request" */
  delete_booking_request?: Maybe<BookingRequestMutationResponse>;
  /** delete single row from the table: "booking_request" */
  delete_booking_request_by_pk?: Maybe<BookingRequest>;
  /** delete data from the table: "booking_request_status" */
  delete_booking_request_status?: Maybe<BookingRequestStatusMutationResponse>;
  /** delete single row from the table: "booking_request_status" */
  delete_booking_request_status_by_pk?: Maybe<BookingRequestStatus>;
  /** delete data from the table: "booking_request_treatments" */
  delete_booking_request_treatments?: Maybe<BookingRequestTreatmentsMutationResponse>;
  /** delete single row from the table: "booking_request_treatments" */
  delete_booking_request_treatments_by_pk?: Maybe<BookingRequestTreatments>;
  /** delete data from the table: "employee_types" */
  delete_employee_types?: Maybe<EmployeeTypesMutationResponse>;
  /** delete single row from the table: "employee_types" */
  delete_employee_types_by_pk?: Maybe<EmployeeTypes>;
  /** delete data from the table: "employees" */
  delete_employees?: Maybe<EmployeesMutationResponse>;
  /** delete single row from the table: "employees" */
  delete_employees_by_pk?: Maybe<Employees>;
  /** delete data from the table: "icons" */
  delete_icons?: Maybe<IconsMutationResponse>;
  /** delete single row from the table: "icons" */
  delete_icons_by_pk?: Maybe<Icons>;
  /** delete data from the table: "location_employees" */
  delete_location_employees?: Maybe<LocationEmployeesMutationResponse>;
  /** delete single row from the table: "location_employees" */
  delete_location_employees_by_pk?: Maybe<LocationEmployees>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<LocationsMutationResponse>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "resources" */
  delete_resources?: Maybe<ResourcesMutationResponse>;
  /** delete single row from the table: "resources" */
  delete_resources_by_pk?: Maybe<Resources>;
  /** delete data from the table: "shift_types" */
  delete_shift_types?: Maybe<ShiftTypesMutationResponse>;
  /** delete single row from the table: "shift_types" */
  delete_shift_types_by_pk?: Maybe<ShiftTypes>;
  /** delete data from the table: "treatment_employees" */
  delete_treatment_employees?: Maybe<TreatmentEmployeesMutationResponse>;
  /** delete single row from the table: "treatment_employees" */
  delete_treatment_employees_by_pk?: Maybe<TreatmentEmployees>;
  /** delete data from the table: "treatment_groups" */
  delete_treatment_groups?: Maybe<TreatmentGroupsMutationResponse>;
  /** delete single row from the table: "treatment_groups" */
  delete_treatment_groups_by_pk?: Maybe<TreatmentGroups>;
  /** delete data from the table: "treatment_resources" */
  delete_treatment_resources?: Maybe<TreatmentResourcesMutationResponse>;
  /** delete single row from the table: "treatment_resources" */
  delete_treatment_resources_by_pk?: Maybe<TreatmentResources>;
  /** delete data from the table: "treatment_shift_types" */
  delete_treatment_shift_types?: Maybe<TreatmentShiftTypesMutationResponse>;
  /** delete single row from the table: "treatment_shift_types" */
  delete_treatment_shift_types_by_pk?: Maybe<TreatmentShiftTypes>;
  /** delete data from the table: "treatment_sms_treads" */
  delete_treatment_sms_treads?: Maybe<TreatmentSmsTreadsMutationResponse>;
  /** delete single row from the table: "treatment_sms_treads" */
  delete_treatment_sms_treads_by_pk?: Maybe<TreatmentSmsTreads>;
  /** delete data from the table: "treatment_status" */
  delete_treatment_status?: Maybe<TreatmentStatusMutationResponse>;
  /** delete single row from the table: "treatment_status" */
  delete_treatment_status_by_pk?: Maybe<TreatmentStatus>;
  /** delete data from the table: "treatments" */
  delete_treatments?: Maybe<TreatmentsMutationResponse>;
  /** delete single row from the table: "treatments" */
  delete_treatments_by_pk?: Maybe<Treatments>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "animal_genders" */
  insert_animal_genders?: Maybe<AnimalGendersMutationResponse>;
  /** insert a single row into the table: "animal_genders" */
  insert_animal_genders_one?: Maybe<AnimalGenders>;
  /** insert data into the table: "animal_species" */
  insert_animal_species?: Maybe<AnimalSpeciesMutationResponse>;
  /** insert a single row into the table: "animal_species" */
  insert_animal_species_one?: Maybe<AnimalSpecies>;
  /** insert data into the table: "booking_request" */
  insert_booking_request?: Maybe<BookingRequestMutationResponse>;
  /** insert a single row into the table: "booking_request" */
  insert_booking_request_one?: Maybe<BookingRequest>;
  /** insert data into the table: "booking_request_status" */
  insert_booking_request_status?: Maybe<BookingRequestStatusMutationResponse>;
  /** insert a single row into the table: "booking_request_status" */
  insert_booking_request_status_one?: Maybe<BookingRequestStatus>;
  /** insert data into the table: "booking_request_treatments" */
  insert_booking_request_treatments?: Maybe<BookingRequestTreatmentsMutationResponse>;
  /** insert a single row into the table: "booking_request_treatments" */
  insert_booking_request_treatments_one?: Maybe<BookingRequestTreatments>;
  /** insert data into the table: "employee_types" */
  insert_employee_types?: Maybe<EmployeeTypesMutationResponse>;
  /** insert a single row into the table: "employee_types" */
  insert_employee_types_one?: Maybe<EmployeeTypes>;
  /** insert data into the table: "employees" */
  insert_employees?: Maybe<EmployeesMutationResponse>;
  /** insert a single row into the table: "employees" */
  insert_employees_one?: Maybe<Employees>;
  /** insert data into the table: "icons" */
  insert_icons?: Maybe<IconsMutationResponse>;
  /** insert a single row into the table: "icons" */
  insert_icons_one?: Maybe<Icons>;
  /** insert data into the table: "location_employees" */
  insert_location_employees?: Maybe<LocationEmployeesMutationResponse>;
  /** insert a single row into the table: "location_employees" */
  insert_location_employees_one?: Maybe<LocationEmployees>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<LocationsMutationResponse>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "resources" */
  insert_resources?: Maybe<ResourcesMutationResponse>;
  /** insert a single row into the table: "resources" */
  insert_resources_one?: Maybe<Resources>;
  /** insert data into the table: "shift_types" */
  insert_shift_types?: Maybe<ShiftTypesMutationResponse>;
  /** insert a single row into the table: "shift_types" */
  insert_shift_types_one?: Maybe<ShiftTypes>;
  /** insert data into the table: "treatment_employees" */
  insert_treatment_employees?: Maybe<TreatmentEmployeesMutationResponse>;
  /** insert a single row into the table: "treatment_employees" */
  insert_treatment_employees_one?: Maybe<TreatmentEmployees>;
  /** insert data into the table: "treatment_groups" */
  insert_treatment_groups?: Maybe<TreatmentGroupsMutationResponse>;
  /** insert a single row into the table: "treatment_groups" */
  insert_treatment_groups_one?: Maybe<TreatmentGroups>;
  /** insert data into the table: "treatment_resources" */
  insert_treatment_resources?: Maybe<TreatmentResourcesMutationResponse>;
  /** insert a single row into the table: "treatment_resources" */
  insert_treatment_resources_one?: Maybe<TreatmentResources>;
  /** insert data into the table: "treatment_shift_types" */
  insert_treatment_shift_types?: Maybe<TreatmentShiftTypesMutationResponse>;
  /** insert a single row into the table: "treatment_shift_types" */
  insert_treatment_shift_types_one?: Maybe<TreatmentShiftTypes>;
  /** insert data into the table: "treatment_sms_treads" */
  insert_treatment_sms_treads?: Maybe<TreatmentSmsTreadsMutationResponse>;
  /** insert a single row into the table: "treatment_sms_treads" */
  insert_treatment_sms_treads_one?: Maybe<TreatmentSmsTreads>;
  /** insert data into the table: "treatment_status" */
  insert_treatment_status?: Maybe<TreatmentStatusMutationResponse>;
  /** insert a single row into the table: "treatment_status" */
  insert_treatment_status_one?: Maybe<TreatmentStatus>;
  /** insert data into the table: "treatments" */
  insert_treatments?: Maybe<TreatmentsMutationResponse>;
  /** insert a single row into the table: "treatments" */
  insert_treatments_one?: Maybe<Treatments>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** updateMe */
  updateMe: User;
  /** updateMyAnimal */
  updateMyAnimal?: Maybe<Animal>;
  /** updateMyBooking */
  updateMyBooking?: Maybe<Booking>;
  /** updateMyBookingToCheckedIn */
  updateMyBookingToCheckedIn?: Maybe<StatusOutput>;
  /** update data of the table: "animal_genders" */
  update_animal_genders?: Maybe<AnimalGendersMutationResponse>;
  /** update single row of the table: "animal_genders" */
  update_animal_genders_by_pk?: Maybe<AnimalGenders>;
  /** update multiples rows of table: "animal_genders" */
  update_animal_genders_many?: Maybe<Array<Maybe<AnimalGendersMutationResponse>>>;
  /** update data of the table: "animal_species" */
  update_animal_species?: Maybe<AnimalSpeciesMutationResponse>;
  /** update single row of the table: "animal_species" */
  update_animal_species_by_pk?: Maybe<AnimalSpecies>;
  /** update multiples rows of table: "animal_species" */
  update_animal_species_many?: Maybe<Array<Maybe<AnimalSpeciesMutationResponse>>>;
  /** update data of the table: "booking_request" */
  update_booking_request?: Maybe<BookingRequestMutationResponse>;
  /** update single row of the table: "booking_request" */
  update_booking_request_by_pk?: Maybe<BookingRequest>;
  /** update multiples rows of table: "booking_request" */
  update_booking_request_many?: Maybe<Array<Maybe<BookingRequestMutationResponse>>>;
  /** update data of the table: "booking_request_status" */
  update_booking_request_status?: Maybe<BookingRequestStatusMutationResponse>;
  /** update single row of the table: "booking_request_status" */
  update_booking_request_status_by_pk?: Maybe<BookingRequestStatus>;
  /** update multiples rows of table: "booking_request_status" */
  update_booking_request_status_many?: Maybe<Array<Maybe<BookingRequestStatusMutationResponse>>>;
  /** update data of the table: "booking_request_treatments" */
  update_booking_request_treatments?: Maybe<BookingRequestTreatmentsMutationResponse>;
  /** update single row of the table: "booking_request_treatments" */
  update_booking_request_treatments_by_pk?: Maybe<BookingRequestTreatments>;
  /** update multiples rows of table: "booking_request_treatments" */
  update_booking_request_treatments_many?: Maybe<
    Array<Maybe<BookingRequestTreatmentsMutationResponse>>
  >;
  /** update data of the table: "employee_types" */
  update_employee_types?: Maybe<EmployeeTypesMutationResponse>;
  /** update single row of the table: "employee_types" */
  update_employee_types_by_pk?: Maybe<EmployeeTypes>;
  /** update multiples rows of table: "employee_types" */
  update_employee_types_many?: Maybe<Array<Maybe<EmployeeTypesMutationResponse>>>;
  /** update data of the table: "employees" */
  update_employees?: Maybe<EmployeesMutationResponse>;
  /** update single row of the table: "employees" */
  update_employees_by_pk?: Maybe<Employees>;
  /** update multiples rows of table: "employees" */
  update_employees_many?: Maybe<Array<Maybe<EmployeesMutationResponse>>>;
  /** update data of the table: "icons" */
  update_icons?: Maybe<IconsMutationResponse>;
  /** update single row of the table: "icons" */
  update_icons_by_pk?: Maybe<Icons>;
  /** update multiples rows of table: "icons" */
  update_icons_many?: Maybe<Array<Maybe<IconsMutationResponse>>>;
  /** update data of the table: "location_employees" */
  update_location_employees?: Maybe<LocationEmployeesMutationResponse>;
  /** update single row of the table: "location_employees" */
  update_location_employees_by_pk?: Maybe<LocationEmployees>;
  /** update multiples rows of table: "location_employees" */
  update_location_employees_many?: Maybe<Array<Maybe<LocationEmployeesMutationResponse>>>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<LocationsMutationResponse>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update multiples rows of table: "locations" */
  update_locations_many?: Maybe<Array<Maybe<LocationsMutationResponse>>>;
  /** update data of the table: "resources" */
  update_resources?: Maybe<ResourcesMutationResponse>;
  /** update single row of the table: "resources" */
  update_resources_by_pk?: Maybe<Resources>;
  /** update multiples rows of table: "resources" */
  update_resources_many?: Maybe<Array<Maybe<ResourcesMutationResponse>>>;
  /** update data of the table: "shift_types" */
  update_shift_types?: Maybe<ShiftTypesMutationResponse>;
  /** update single row of the table: "shift_types" */
  update_shift_types_by_pk?: Maybe<ShiftTypes>;
  /** update multiples rows of table: "shift_types" */
  update_shift_types_many?: Maybe<Array<Maybe<ShiftTypesMutationResponse>>>;
  /** update data of the table: "treatment_employees" */
  update_treatment_employees?: Maybe<TreatmentEmployeesMutationResponse>;
  /** update single row of the table: "treatment_employees" */
  update_treatment_employees_by_pk?: Maybe<TreatmentEmployees>;
  /** update multiples rows of table: "treatment_employees" */
  update_treatment_employees_many?: Maybe<Array<Maybe<TreatmentEmployeesMutationResponse>>>;
  /** update data of the table: "treatment_groups" */
  update_treatment_groups?: Maybe<TreatmentGroupsMutationResponse>;
  /** update single row of the table: "treatment_groups" */
  update_treatment_groups_by_pk?: Maybe<TreatmentGroups>;
  /** update multiples rows of table: "treatment_groups" */
  update_treatment_groups_many?: Maybe<Array<Maybe<TreatmentGroupsMutationResponse>>>;
  /** update data of the table: "treatment_resources" */
  update_treatment_resources?: Maybe<TreatmentResourcesMutationResponse>;
  /** update single row of the table: "treatment_resources" */
  update_treatment_resources_by_pk?: Maybe<TreatmentResources>;
  /** update multiples rows of table: "treatment_resources" */
  update_treatment_resources_many?: Maybe<Array<Maybe<TreatmentResourcesMutationResponse>>>;
  /** update data of the table: "treatment_shift_types" */
  update_treatment_shift_types?: Maybe<TreatmentShiftTypesMutationResponse>;
  /** update single row of the table: "treatment_shift_types" */
  update_treatment_shift_types_by_pk?: Maybe<TreatmentShiftTypes>;
  /** update multiples rows of table: "treatment_shift_types" */
  update_treatment_shift_types_many?: Maybe<Array<Maybe<TreatmentShiftTypesMutationResponse>>>;
  /** update data of the table: "treatment_sms_treads" */
  update_treatment_sms_treads?: Maybe<TreatmentSmsTreadsMutationResponse>;
  /** update single row of the table: "treatment_sms_treads" */
  update_treatment_sms_treads_by_pk?: Maybe<TreatmentSmsTreads>;
  /** update multiples rows of table: "treatment_sms_treads" */
  update_treatment_sms_treads_many?: Maybe<Array<Maybe<TreatmentSmsTreadsMutationResponse>>>;
  /** update data of the table: "treatment_status" */
  update_treatment_status?: Maybe<TreatmentStatusMutationResponse>;
  /** update single row of the table: "treatment_status" */
  update_treatment_status_by_pk?: Maybe<TreatmentStatus>;
  /** update multiples rows of table: "treatment_status" */
  update_treatment_status_many?: Maybe<Array<Maybe<TreatmentStatusMutationResponse>>>;
  /** update data of the table: "treatments" */
  update_treatments?: Maybe<TreatmentsMutationResponse>;
  /** update single row of the table: "treatments" */
  update_treatments_by_pk?: Maybe<Treatments>;
  /** update multiples rows of table: "treatments" */
  update_treatments_many?: Maybe<Array<Maybe<TreatmentsMutationResponse>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<UsersMutationResponse>>>;
};

/** mutation root */
export type MutationRootCreateAnimalArgs = {
  dob: Scalars['String'];
  gender: AnimalGender;
  name: Scalars['String'];
  type: AnimalType;
  userId: Scalars['String'];
};

/** mutation root */
export type MutationRootCreateBookingArgs = {
  args?: InputMaybe<CreateBookingInput>;
};

/** mutation root */
export type MutationRootCreateBookingFromRequestArgs = {
  id: Scalars['String'];
  time: Scalars['String'];
};

/** mutation root */
export type MutationRootCreateBookingRequestArgs = {
  animalId: Scalars['String'];
  locationId: Scalars['Int'];
  treatmentIds: Array<Scalars['Int']>;
  userId: Scalars['String'];
};

/** mutation root */
export type MutationRootCreateMyAnimalArgs = {
  dob: Scalars['String'];
  gender: AnimalGender;
  name: Scalars['String'];
  type: AnimalType;
  weight?: InputMaybe<Scalars['Float']>;
};

/** mutation root */
export type MutationRootCreateUserArgs = {
  address: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  zipCode: Scalars['String'];
};

/** mutation root */
export type MutationRootCreateUserFromProvetArgs = {
  provetUserId: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteBookingArgs = {
  bookingIds: Array<InputMaybe<Scalars['String']>>;
};

/** mutation root */
export type MutationRootDeleteMyAnimalArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteAnimalGendersArgs = {
  where: AnimalGendersBoolExp;
};

/** mutation root */
export type MutationRootDeleteAnimalGendersByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteAnimalSpeciesArgs = {
  where: AnimalSpeciesBoolExp;
};

/** mutation root */
export type MutationRootDeleteAnimalSpeciesByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteBookingRequestArgs = {
  where: BookingRequestBoolExp;
};

/** mutation root */
export type MutationRootDeleteBookingRequestByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteBookingRequestStatusArgs = {
  where: BookingRequestStatusBoolExp;
};

/** mutation root */
export type MutationRootDeleteBookingRequestStatusByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteBookingRequestTreatmentsArgs = {
  where: BookingRequestTreatmentsBoolExp;
};

/** mutation root */
export type MutationRootDeleteBookingRequestTreatmentsByPkArgs = {
  booking_request_id: Scalars['uuid'];
  treatment_id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteEmployeeTypesArgs = {
  where: EmployeeTypesBoolExp;
};

/** mutation root */
export type MutationRootDeleteEmployeeTypesByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteEmployeesArgs = {
  where: EmployeesBoolExp;
};

/** mutation root */
export type MutationRootDeleteEmployeesByPkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteIconsArgs = {
  where: IconsBoolExp;
};

/** mutation root */
export type MutationRootDeleteIconsByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteLocationEmployeesArgs = {
  where: LocationEmployeesBoolExp;
};

/** mutation root */
export type MutationRootDeleteLocationEmployeesByPkArgs = {
  employee_id: Scalars['Int'];
  location_id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteLocationsArgs = {
  where: LocationsBoolExp;
};

/** mutation root */
export type MutationRootDeleteLocationsByPkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteResourcesArgs = {
  where: ResourcesBoolExp;
};

/** mutation root */
export type MutationRootDeleteResourcesByPkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteShiftTypesArgs = {
  where: ShiftTypesBoolExp;
};

/** mutation root */
export type MutationRootDeleteShiftTypesByPkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteTreatmentEmployeesArgs = {
  where: TreatmentEmployeesBoolExp;
};

/** mutation root */
export type MutationRootDeleteTreatmentEmployeesByPkArgs = {
  employee_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteTreatmentGroupsArgs = {
  where: TreatmentGroupsBoolExp;
};

/** mutation root */
export type MutationRootDeleteTreatmentGroupsByPkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteTreatmentResourcesArgs = {
  where: TreatmentResourcesBoolExp;
};

/** mutation root */
export type MutationRootDeleteTreatmentResourcesByPkArgs = {
  resource_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteTreatmentShiftTypesArgs = {
  where: TreatmentShiftTypesBoolExp;
};

/** mutation root */
export type MutationRootDeleteTreatmentShiftTypesByPkArgs = {
  shift_type_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteTreatmentSmsTreadsArgs = {
  where: TreatmentSmsTreadsBoolExp;
};

/** mutation root */
export type MutationRootDeleteTreatmentSmsTreadsByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteTreatmentStatusArgs = {
  where: TreatmentStatusBoolExp;
};

/** mutation root */
export type MutationRootDeleteTreatmentStatusByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteTreatmentsArgs = {
  where: TreatmentsBoolExp;
};

/** mutation root */
export type MutationRootDeleteTreatmentsByPkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type MutationRootDeleteUsersArgs = {
  where: UsersBoolExp;
};

/** mutation root */
export type MutationRootDeleteUsersByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootInsertAnimalGendersArgs = {
  objects: Array<AnimalGendersInsertInput>;
  on_conflict?: InputMaybe<AnimalGendersOnConflict>;
};

/** mutation root */
export type MutationRootInsertAnimalGendersOneArgs = {
  object: AnimalGendersInsertInput;
  on_conflict?: InputMaybe<AnimalGendersOnConflict>;
};

/** mutation root */
export type MutationRootInsertAnimalSpeciesArgs = {
  objects: Array<AnimalSpeciesInsertInput>;
  on_conflict?: InputMaybe<AnimalSpeciesOnConflict>;
};

/** mutation root */
export type MutationRootInsertAnimalSpeciesOneArgs = {
  object: AnimalSpeciesInsertInput;
  on_conflict?: InputMaybe<AnimalSpeciesOnConflict>;
};

/** mutation root */
export type MutationRootInsertBookingRequestArgs = {
  objects: Array<BookingRequestInsertInput>;
  on_conflict?: InputMaybe<BookingRequestOnConflict>;
};

/** mutation root */
export type MutationRootInsertBookingRequestOneArgs = {
  object: BookingRequestInsertInput;
  on_conflict?: InputMaybe<BookingRequestOnConflict>;
};

/** mutation root */
export type MutationRootInsertBookingRequestStatusArgs = {
  objects: Array<BookingRequestStatusInsertInput>;
  on_conflict?: InputMaybe<BookingRequestStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertBookingRequestStatusOneArgs = {
  object: BookingRequestStatusInsertInput;
  on_conflict?: InputMaybe<BookingRequestStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertBookingRequestTreatmentsArgs = {
  objects: Array<BookingRequestTreatmentsInsertInput>;
  on_conflict?: InputMaybe<BookingRequestTreatmentsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBookingRequestTreatmentsOneArgs = {
  object: BookingRequestTreatmentsInsertInput;
  on_conflict?: InputMaybe<BookingRequestTreatmentsOnConflict>;
};

/** mutation root */
export type MutationRootInsertEmployeeTypesArgs = {
  objects: Array<EmployeeTypesInsertInput>;
  on_conflict?: InputMaybe<EmployeeTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertEmployeeTypesOneArgs = {
  object: EmployeeTypesInsertInput;
  on_conflict?: InputMaybe<EmployeeTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertEmployeesArgs = {
  objects: Array<EmployeesInsertInput>;
  on_conflict?: InputMaybe<EmployeesOnConflict>;
};

/** mutation root */
export type MutationRootInsertEmployeesOneArgs = {
  object: EmployeesInsertInput;
  on_conflict?: InputMaybe<EmployeesOnConflict>;
};

/** mutation root */
export type MutationRootInsertIconsArgs = {
  objects: Array<IconsInsertInput>;
  on_conflict?: InputMaybe<IconsOnConflict>;
};

/** mutation root */
export type MutationRootInsertIconsOneArgs = {
  object: IconsInsertInput;
  on_conflict?: InputMaybe<IconsOnConflict>;
};

/** mutation root */
export type MutationRootInsertLocationEmployeesArgs = {
  objects: Array<LocationEmployeesInsertInput>;
  on_conflict?: InputMaybe<LocationEmployeesOnConflict>;
};

/** mutation root */
export type MutationRootInsertLocationEmployeesOneArgs = {
  object: LocationEmployeesInsertInput;
  on_conflict?: InputMaybe<LocationEmployeesOnConflict>;
};

/** mutation root */
export type MutationRootInsertLocationsArgs = {
  objects: Array<LocationsInsertInput>;
  on_conflict?: InputMaybe<LocationsOnConflict>;
};

/** mutation root */
export type MutationRootInsertLocationsOneArgs = {
  object: LocationsInsertInput;
  on_conflict?: InputMaybe<LocationsOnConflict>;
};

/** mutation root */
export type MutationRootInsertResourcesArgs = {
  objects: Array<ResourcesInsertInput>;
  on_conflict?: InputMaybe<ResourcesOnConflict>;
};

/** mutation root */
export type MutationRootInsertResourcesOneArgs = {
  object: ResourcesInsertInput;
  on_conflict?: InputMaybe<ResourcesOnConflict>;
};

/** mutation root */
export type MutationRootInsertShiftTypesArgs = {
  objects: Array<ShiftTypesInsertInput>;
  on_conflict?: InputMaybe<ShiftTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertShiftTypesOneArgs = {
  object: ShiftTypesInsertInput;
  on_conflict?: InputMaybe<ShiftTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentEmployeesArgs = {
  objects: Array<TreatmentEmployeesInsertInput>;
  on_conflict?: InputMaybe<TreatmentEmployeesOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentEmployeesOneArgs = {
  object: TreatmentEmployeesInsertInput;
  on_conflict?: InputMaybe<TreatmentEmployeesOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentGroupsArgs = {
  objects: Array<TreatmentGroupsInsertInput>;
  on_conflict?: InputMaybe<TreatmentGroupsOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentGroupsOneArgs = {
  object: TreatmentGroupsInsertInput;
  on_conflict?: InputMaybe<TreatmentGroupsOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentResourcesArgs = {
  objects: Array<TreatmentResourcesInsertInput>;
  on_conflict?: InputMaybe<TreatmentResourcesOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentResourcesOneArgs = {
  object: TreatmentResourcesInsertInput;
  on_conflict?: InputMaybe<TreatmentResourcesOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentShiftTypesArgs = {
  objects: Array<TreatmentShiftTypesInsertInput>;
  on_conflict?: InputMaybe<TreatmentShiftTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentShiftTypesOneArgs = {
  object: TreatmentShiftTypesInsertInput;
  on_conflict?: InputMaybe<TreatmentShiftTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentSmsTreadsArgs = {
  objects: Array<TreatmentSmsTreadsInsertInput>;
  on_conflict?: InputMaybe<TreatmentSmsTreadsOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentSmsTreadsOneArgs = {
  object: TreatmentSmsTreadsInsertInput;
  on_conflict?: InputMaybe<TreatmentSmsTreadsOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentStatusArgs = {
  objects: Array<TreatmentStatusInsertInput>;
  on_conflict?: InputMaybe<TreatmentStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentStatusOneArgs = {
  object: TreatmentStatusInsertInput;
  on_conflict?: InputMaybe<TreatmentStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentsArgs = {
  objects: Array<TreatmentsInsertInput>;
  on_conflict?: InputMaybe<TreatmentsOnConflict>;
};

/** mutation root */
export type MutationRootInsertTreatmentsOneArgs = {
  object: TreatmentsInsertInput;
  on_conflict?: InputMaybe<TreatmentsOnConflict>;
};

/** mutation root */
export type MutationRootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  on_conflict?: InputMaybe<UsersOnConflict>;
};

/** mutation root */
export type MutationRootInsertUsersOneArgs = {
  object: UsersInsertInput;
  on_conflict?: InputMaybe<UsersOnConflict>;
};

/** mutation root */
export type MutationRootUpdateMeArgs = {
  address: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  zipCode: Scalars['String'];
};

/** mutation root */
export type MutationRootUpdateMyAnimalArgs = {
  dob: Scalars['String'];
  gender: AnimalGender;
  id: Scalars['String'];
  name: Scalars['String'];
  type: AnimalType;
  weight?: InputMaybe<Scalars['Float']>;
};

/** mutation root */
export type MutationRootUpdateMyBookingArgs = {
  bookingIds: Array<Scalars['String']>;
  time: Scalars['String'];
};

/** mutation root */
export type MutationRootUpdateMyBookingToCheckedInArgs = {
  bookingIds: Array<Scalars['String']>;
  weight: Scalars['Float'];
};

/** mutation root */
export type MutationRootUpdateAnimalGendersArgs = {
  _set?: InputMaybe<AnimalGendersSetInput>;
  where: AnimalGendersBoolExp;
};

/** mutation root */
export type MutationRootUpdateAnimalGendersByPkArgs = {
  _set?: InputMaybe<AnimalGendersSetInput>;
  pk_columns: AnimalGendersPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateAnimalGendersManyArgs = {
  updates: Array<AnimalGendersUpdates>;
};

/** mutation root */
export type MutationRootUpdateAnimalSpeciesArgs = {
  _set?: InputMaybe<AnimalSpeciesSetInput>;
  where: AnimalSpeciesBoolExp;
};

/** mutation root */
export type MutationRootUpdateAnimalSpeciesByPkArgs = {
  _set?: InputMaybe<AnimalSpeciesSetInput>;
  pk_columns: AnimalSpeciesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateAnimalSpeciesManyArgs = {
  updates: Array<AnimalSpeciesUpdates>;
};

/** mutation root */
export type MutationRootUpdateBookingRequestArgs = {
  _inc?: InputMaybe<BookingRequestIncInput>;
  _set?: InputMaybe<BookingRequestSetInput>;
  where: BookingRequestBoolExp;
};

/** mutation root */
export type MutationRootUpdateBookingRequestByPkArgs = {
  _inc?: InputMaybe<BookingRequestIncInput>;
  _set?: InputMaybe<BookingRequestSetInput>;
  pk_columns: BookingRequestPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBookingRequestManyArgs = {
  updates: Array<BookingRequestUpdates>;
};

/** mutation root */
export type MutationRootUpdateBookingRequestStatusArgs = {
  _set?: InputMaybe<BookingRequestStatusSetInput>;
  where: BookingRequestStatusBoolExp;
};

/** mutation root */
export type MutationRootUpdateBookingRequestStatusByPkArgs = {
  _set?: InputMaybe<BookingRequestStatusSetInput>;
  pk_columns: BookingRequestStatusPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBookingRequestStatusManyArgs = {
  updates: Array<BookingRequestStatusUpdates>;
};

/** mutation root */
export type MutationRootUpdateBookingRequestTreatmentsArgs = {
  _inc?: InputMaybe<BookingRequestTreatmentsIncInput>;
  _set?: InputMaybe<BookingRequestTreatmentsSetInput>;
  where: BookingRequestTreatmentsBoolExp;
};

/** mutation root */
export type MutationRootUpdateBookingRequestTreatmentsByPkArgs = {
  _inc?: InputMaybe<BookingRequestTreatmentsIncInput>;
  _set?: InputMaybe<BookingRequestTreatmentsSetInput>;
  pk_columns: BookingRequestTreatmentsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBookingRequestTreatmentsManyArgs = {
  updates: Array<BookingRequestTreatmentsUpdates>;
};

/** mutation root */
export type MutationRootUpdateEmployeeTypesArgs = {
  _set?: InputMaybe<EmployeeTypesSetInput>;
  where: EmployeeTypesBoolExp;
};

/** mutation root */
export type MutationRootUpdateEmployeeTypesByPkArgs = {
  _set?: InputMaybe<EmployeeTypesSetInput>;
  pk_columns: EmployeeTypesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateEmployeeTypesManyArgs = {
  updates: Array<EmployeeTypesUpdates>;
};

/** mutation root */
export type MutationRootUpdateEmployeesArgs = {
  _inc?: InputMaybe<EmployeesIncInput>;
  _set?: InputMaybe<EmployeesSetInput>;
  where: EmployeesBoolExp;
};

/** mutation root */
export type MutationRootUpdateEmployeesByPkArgs = {
  _inc?: InputMaybe<EmployeesIncInput>;
  _set?: InputMaybe<EmployeesSetInput>;
  pk_columns: EmployeesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateEmployeesManyArgs = {
  updates: Array<EmployeesUpdates>;
};

/** mutation root */
export type MutationRootUpdateIconsArgs = {
  _set?: InputMaybe<IconsSetInput>;
  where: IconsBoolExp;
};

/** mutation root */
export type MutationRootUpdateIconsByPkArgs = {
  _set?: InputMaybe<IconsSetInput>;
  pk_columns: IconsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateIconsManyArgs = {
  updates: Array<IconsUpdates>;
};

/** mutation root */
export type MutationRootUpdateLocationEmployeesArgs = {
  _inc?: InputMaybe<LocationEmployeesIncInput>;
  _set?: InputMaybe<LocationEmployeesSetInput>;
  where: LocationEmployeesBoolExp;
};

/** mutation root */
export type MutationRootUpdateLocationEmployeesByPkArgs = {
  _inc?: InputMaybe<LocationEmployeesIncInput>;
  _set?: InputMaybe<LocationEmployeesSetInput>;
  pk_columns: LocationEmployeesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateLocationEmployeesManyArgs = {
  updates: Array<LocationEmployeesUpdates>;
};

/** mutation root */
export type MutationRootUpdateLocationsArgs = {
  _inc?: InputMaybe<LocationsIncInput>;
  _set?: InputMaybe<LocationsSetInput>;
  where: LocationsBoolExp;
};

/** mutation root */
export type MutationRootUpdateLocationsByPkArgs = {
  _inc?: InputMaybe<LocationsIncInput>;
  _set?: InputMaybe<LocationsSetInput>;
  pk_columns: LocationsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateLocationsManyArgs = {
  updates: Array<LocationsUpdates>;
};

/** mutation root */
export type MutationRootUpdateResourcesArgs = {
  _inc?: InputMaybe<ResourcesIncInput>;
  _set?: InputMaybe<ResourcesSetInput>;
  where: ResourcesBoolExp;
};

/** mutation root */
export type MutationRootUpdateResourcesByPkArgs = {
  _inc?: InputMaybe<ResourcesIncInput>;
  _set?: InputMaybe<ResourcesSetInput>;
  pk_columns: ResourcesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateResourcesManyArgs = {
  updates: Array<ResourcesUpdates>;
};

/** mutation root */
export type MutationRootUpdateShiftTypesArgs = {
  _inc?: InputMaybe<ShiftTypesIncInput>;
  _set?: InputMaybe<ShiftTypesSetInput>;
  where: ShiftTypesBoolExp;
};

/** mutation root */
export type MutationRootUpdateShiftTypesByPkArgs = {
  _inc?: InputMaybe<ShiftTypesIncInput>;
  _set?: InputMaybe<ShiftTypesSetInput>;
  pk_columns: ShiftTypesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateShiftTypesManyArgs = {
  updates: Array<ShiftTypesUpdates>;
};

/** mutation root */
export type MutationRootUpdateTreatmentEmployeesArgs = {
  _inc?: InputMaybe<TreatmentEmployeesIncInput>;
  _set?: InputMaybe<TreatmentEmployeesSetInput>;
  where: TreatmentEmployeesBoolExp;
};

/** mutation root */
export type MutationRootUpdateTreatmentEmployeesByPkArgs = {
  _inc?: InputMaybe<TreatmentEmployeesIncInput>;
  _set?: InputMaybe<TreatmentEmployeesSetInput>;
  pk_columns: TreatmentEmployeesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTreatmentEmployeesManyArgs = {
  updates: Array<TreatmentEmployeesUpdates>;
};

/** mutation root */
export type MutationRootUpdateTreatmentGroupsArgs = {
  _inc?: InputMaybe<TreatmentGroupsIncInput>;
  _set?: InputMaybe<TreatmentGroupsSetInput>;
  where: TreatmentGroupsBoolExp;
};

/** mutation root */
export type MutationRootUpdateTreatmentGroupsByPkArgs = {
  _inc?: InputMaybe<TreatmentGroupsIncInput>;
  _set?: InputMaybe<TreatmentGroupsSetInput>;
  pk_columns: TreatmentGroupsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTreatmentGroupsManyArgs = {
  updates: Array<TreatmentGroupsUpdates>;
};

/** mutation root */
export type MutationRootUpdateTreatmentResourcesArgs = {
  _inc?: InputMaybe<TreatmentResourcesIncInput>;
  _set?: InputMaybe<TreatmentResourcesSetInput>;
  where: TreatmentResourcesBoolExp;
};

/** mutation root */
export type MutationRootUpdateTreatmentResourcesByPkArgs = {
  _inc?: InputMaybe<TreatmentResourcesIncInput>;
  _set?: InputMaybe<TreatmentResourcesSetInput>;
  pk_columns: TreatmentResourcesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTreatmentResourcesManyArgs = {
  updates: Array<TreatmentResourcesUpdates>;
};

/** mutation root */
export type MutationRootUpdateTreatmentShiftTypesArgs = {
  _inc?: InputMaybe<TreatmentShiftTypesIncInput>;
  _set?: InputMaybe<TreatmentShiftTypesSetInput>;
  where: TreatmentShiftTypesBoolExp;
};

/** mutation root */
export type MutationRootUpdateTreatmentShiftTypesByPkArgs = {
  _inc?: InputMaybe<TreatmentShiftTypesIncInput>;
  _set?: InputMaybe<TreatmentShiftTypesSetInput>;
  pk_columns: TreatmentShiftTypesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTreatmentShiftTypesManyArgs = {
  updates: Array<TreatmentShiftTypesUpdates>;
};

/** mutation root */
export type MutationRootUpdateTreatmentSmsTreadsArgs = {
  _set?: InputMaybe<TreatmentSmsTreadsSetInput>;
  where: TreatmentSmsTreadsBoolExp;
};

/** mutation root */
export type MutationRootUpdateTreatmentSmsTreadsByPkArgs = {
  _set?: InputMaybe<TreatmentSmsTreadsSetInput>;
  pk_columns: TreatmentSmsTreadsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTreatmentSmsTreadsManyArgs = {
  updates: Array<TreatmentSmsTreadsUpdates>;
};

/** mutation root */
export type MutationRootUpdateTreatmentStatusArgs = {
  _set?: InputMaybe<TreatmentStatusSetInput>;
  where: TreatmentStatusBoolExp;
};

/** mutation root */
export type MutationRootUpdateTreatmentStatusByPkArgs = {
  _set?: InputMaybe<TreatmentStatusSetInput>;
  pk_columns: TreatmentStatusPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTreatmentStatusManyArgs = {
  updates: Array<TreatmentStatusUpdates>;
};

/** mutation root */
export type MutationRootUpdateTreatmentsArgs = {
  _inc?: InputMaybe<TreatmentsIncInput>;
  _set?: InputMaybe<TreatmentsSetInput>;
  where: TreatmentsBoolExp;
};

/** mutation root */
export type MutationRootUpdateTreatmentsByPkArgs = {
  _inc?: InputMaybe<TreatmentsIncInput>;
  _set?: InputMaybe<TreatmentsSetInput>;
  pk_columns: TreatmentsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTreatmentsManyArgs = {
  updates: Array<TreatmentsUpdates>;
};

/** mutation root */
export type MutationRootUpdateUsersArgs = {
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};

/** mutation root */
export type MutationRootUpdateUsersByPkArgs = {
  _set?: InputMaybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  ASC = 'asc',
  /** in ascending order, nulls first */
  ASC_NULLS_FIRST = 'asc_nulls_first',
  /** in ascending order, nulls last */
  ASC_NULLS_LAST = 'asc_nulls_last',
  /** in descending order, nulls first */
  DESC = 'desc',
  /** in descending order, nulls first */
  DESC_NULLS_FIRST = 'desc_nulls_first',
  /** in descending order, nulls last */
  DESC_NULLS_LAST = 'desc_nulls_last',
}

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "animal_genders" */
  animal_genders: Array<AnimalGenders>;
  /** fetch aggregated fields from the table: "animal_genders" */
  animal_genders_aggregate: AnimalGendersAggregate;
  /** fetch data from the table: "animal_genders" using primary key columns */
  animal_genders_by_pk?: Maybe<AnimalGenders>;
  /** fetch data from the table: "animal_species" */
  animal_species: Array<AnimalSpecies>;
  /** fetch aggregated fields from the table: "animal_species" */
  animal_species_aggregate: AnimalSpeciesAggregate;
  /** fetch data from the table: "animal_species" using primary key columns */
  animal_species_by_pk?: Maybe<AnimalSpecies>;
  /** fetch data from the table: "booking_request" */
  booking_request: Array<BookingRequest>;
  /** fetch aggregated fields from the table: "booking_request" */
  booking_request_aggregate: BookingRequestAggregate;
  /** fetch data from the table: "booking_request" using primary key columns */
  booking_request_by_pk?: Maybe<BookingRequest>;
  /** fetch data from the table: "booking_request_status" */
  booking_request_status: Array<BookingRequestStatus>;
  /** fetch aggregated fields from the table: "booking_request_status" */
  booking_request_status_aggregate: BookingRequestStatusAggregate;
  /** fetch data from the table: "booking_request_status" using primary key columns */
  booking_request_status_by_pk?: Maybe<BookingRequestStatus>;
  /** An array relationship */
  booking_request_treatments: Array<BookingRequestTreatments>;
  /** An aggregate relationship */
  booking_request_treatments_aggregate: BookingRequestTreatmentsAggregate;
  /** fetch data from the table: "booking_request_treatments" using primary key columns */
  booking_request_treatments_by_pk?: Maybe<BookingRequestTreatments>;
  /** fetch data from the table: "employee_types" */
  employee_types: Array<EmployeeTypes>;
  /** fetch aggregated fields from the table: "employee_types" */
  employee_types_aggregate: EmployeeTypesAggregate;
  /** fetch data from the table: "employee_types" using primary key columns */
  employee_types_by_pk?: Maybe<EmployeeTypes>;
  /** An array relationship */
  employees: Array<Employees>;
  /** An aggregate relationship */
  employees_aggregate: EmployeesAggregate;
  /** fetch data from the table: "employees" using primary key columns */
  employees_by_pk?: Maybe<Employees>;
  /** getAvailability */
  getAvailability: Array<Maybe<Availablity>>;
  /** getAvailabilityForBooking */
  getAvailabilityForBooking: Array<Maybe<Availablity>>;
  /** getAvailabilityForBookingRequest */
  getAvailabilityForBookingRequest: Array<Maybe<Availablity>>;
  /** getMe */
  getMe: User;
  /** getMyAnimals */
  getMyAnimals: Array<Maybe<Animal>>;
  /** getMyBookings */
  getMyBookings?: Maybe<Array<Maybe<Booking>>>;
  /** getMyBookingsForToday */
  getMyBookingsForToday: Array<Maybe<Booking>>;
  /** getUserAnimals */
  getUserAnimals?: Maybe<Array<Animal>>;
  /** getUsersByPhonenumber */
  getUsersByPhonenumber?: Maybe<Array<Maybe<User>>>;
  /** fetch data from the table: "icons" */
  icons: Array<Icons>;
  /** fetch aggregated fields from the table: "icons" */
  icons_aggregate: IconsAggregate;
  /** fetch data from the table: "icons" using primary key columns */
  icons_by_pk?: Maybe<Icons>;
  /** An array relationship */
  location_employees: Array<LocationEmployees>;
  /** An aggregate relationship */
  location_employees_aggregate: LocationEmployeesAggregate;
  /** fetch data from the table: "location_employees" using primary key columns */
  location_employees_by_pk?: Maybe<LocationEmployees>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: LocationsAggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  resources: Array<Resources>;
  /** An aggregate relationship */
  resources_aggregate: ResourcesAggregate;
  /** fetch data from the table: "resources" using primary key columns */
  resources_by_pk?: Maybe<Resources>;
  /** An array relationship */
  shift_types: Array<ShiftTypes>;
  /** An aggregate relationship */
  shift_types_aggregate: ShiftTypesAggregate;
  /** fetch data from the table: "shift_types" using primary key columns */
  shift_types_by_pk?: Maybe<ShiftTypes>;
  /** An array relationship */
  treatment_employees: Array<TreatmentEmployees>;
  /** An aggregate relationship */
  treatment_employees_aggregate: TreatmentEmployeesAggregate;
  /** fetch data from the table: "treatment_employees" using primary key columns */
  treatment_employees_by_pk?: Maybe<TreatmentEmployees>;
  /** An array relationship */
  treatment_groups: Array<TreatmentGroups>;
  /** An aggregate relationship */
  treatment_groups_aggregate: TreatmentGroupsAggregate;
  /** fetch data from the table: "treatment_groups" using primary key columns */
  treatment_groups_by_pk?: Maybe<TreatmentGroups>;
  /** An array relationship */
  treatment_resources: Array<TreatmentResources>;
  /** An aggregate relationship */
  treatment_resources_aggregate: TreatmentResourcesAggregate;
  /** fetch data from the table: "treatment_resources" using primary key columns */
  treatment_resources_by_pk?: Maybe<TreatmentResources>;
  /** An array relationship */
  treatment_shift_types: Array<TreatmentShiftTypes>;
  /** An aggregate relationship */
  treatment_shift_types_aggregate: TreatmentShiftTypesAggregate;
  /** fetch data from the table: "treatment_shift_types" using primary key columns */
  treatment_shift_types_by_pk?: Maybe<TreatmentShiftTypes>;
  /** fetch data from the table: "treatment_sms_treads" */
  treatment_sms_treads: Array<TreatmentSmsTreads>;
  /** fetch aggregated fields from the table: "treatment_sms_treads" */
  treatment_sms_treads_aggregate: TreatmentSmsTreadsAggregate;
  /** fetch data from the table: "treatment_sms_treads" using primary key columns */
  treatment_sms_treads_by_pk?: Maybe<TreatmentSmsTreads>;
  /** fetch data from the table: "treatment_status" */
  treatment_status: Array<TreatmentStatus>;
  /** fetch aggregated fields from the table: "treatment_status" */
  treatment_status_aggregate: TreatmentStatusAggregate;
  /** fetch data from the table: "treatment_status" using primary key columns */
  treatment_status_by_pk?: Maybe<TreatmentStatus>;
  /** An array relationship */
  treatments: Array<Treatments>;
  /** An aggregate relationship */
  treatments_aggregate: TreatmentsAggregate;
  /** fetch data from the table: "treatments" using primary key columns */
  treatments_by_pk?: Maybe<Treatments>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};

export type QueryRootAnimalGendersArgs = {
  distinct_on?: InputMaybe<Array<AnimalGendersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnimalGendersOrderBy>>;
  where?: InputMaybe<AnimalGendersBoolExp>;
};

export type QueryRootAnimalGendersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AnimalGendersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnimalGendersOrderBy>>;
  where?: InputMaybe<AnimalGendersBoolExp>;
};

export type QueryRootAnimalGendersByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootAnimalSpeciesArgs = {
  distinct_on?: InputMaybe<Array<AnimalSpeciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnimalSpeciesOrderBy>>;
  where?: InputMaybe<AnimalSpeciesBoolExp>;
};

export type QueryRootAnimalSpeciesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AnimalSpeciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnimalSpeciesOrderBy>>;
  where?: InputMaybe<AnimalSpeciesBoolExp>;
};

export type QueryRootAnimalSpeciesByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootBookingRequestArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

export type QueryRootBookingRequestAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

export type QueryRootBookingRequestByPkArgs = {
  id: Scalars['uuid'];
};

export type QueryRootBookingRequestStatusArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestStatusOrderBy>>;
  where?: InputMaybe<BookingRequestStatusBoolExp>;
};

export type QueryRootBookingRequestStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestStatusOrderBy>>;
  where?: InputMaybe<BookingRequestStatusBoolExp>;
};

export type QueryRootBookingRequestStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootBookingRequestTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestTreatmentsOrderBy>>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

export type QueryRootBookingRequestTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestTreatmentsOrderBy>>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

export type QueryRootBookingRequestTreatmentsByPkArgs = {
  booking_request_id: Scalars['uuid'];
  treatment_id: Scalars['Int'];
};

export type QueryRootEmployeeTypesArgs = {
  distinct_on?: InputMaybe<Array<EmployeeTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeTypesOrderBy>>;
  where?: InputMaybe<EmployeeTypesBoolExp>;
};

export type QueryRootEmployeeTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeTypesOrderBy>>;
  where?: InputMaybe<EmployeeTypesBoolExp>;
};

export type QueryRootEmployeeTypesByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEmployeesArgs = {
  distinct_on?: InputMaybe<Array<EmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeesOrderBy>>;
  where?: InputMaybe<EmployeesBoolExp>;
};

export type QueryRootEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeesOrderBy>>;
  where?: InputMaybe<EmployeesBoolExp>;
};

export type QueryRootEmployeesByPkArgs = {
  id: Scalars['Int'];
};

export type QueryRootGetAvailabilityArgs = {
  endDate: Scalars['String'];
  locationId: Scalars['String'];
  serviceIds: Array<Scalars['String']>;
  startDate: Scalars['String'];
};

export type QueryRootGetAvailabilityForBookingArgs = {
  bookingIds: Array<Scalars['String']>;
};

export type QueryRootGetAvailabilityForBookingRequestArgs = {
  id: Scalars['String'];
};

export type QueryRootGetUserAnimalsArgs = {
  id: Scalars['String'];
};

export type QueryRootGetUsersByPhonenumberArgs = {
  phoneNumber: Scalars['String'];
};

export type QueryRootIconsArgs = {
  distinct_on?: InputMaybe<Array<IconsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IconsOrderBy>>;
  where?: InputMaybe<IconsBoolExp>;
};

export type QueryRootIconsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IconsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IconsOrderBy>>;
  where?: InputMaybe<IconsBoolExp>;
};

export type QueryRootIconsByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootLocationEmployeesArgs = {
  distinct_on?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationEmployeesOrderBy>>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

export type QueryRootLocationEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationEmployeesOrderBy>>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

export type QueryRootLocationEmployeesByPkArgs = {
  employee_id: Scalars['Int'];
  location_id: Scalars['Int'];
};

export type QueryRootLocationsArgs = {
  distinct_on?: InputMaybe<Array<LocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationsOrderBy>>;
  where?: InputMaybe<LocationsBoolExp>;
};

export type QueryRootLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationsOrderBy>>;
  where?: InputMaybe<LocationsBoolExp>;
};

export type QueryRootLocationsByPkArgs = {
  id: Scalars['Int'];
};

export type QueryRootResourcesArgs = {
  distinct_on?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type QueryRootResourcesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type QueryRootResourcesByPkArgs = {
  id: Scalars['Int'];
};

export type QueryRootShiftTypesArgs = {
  distinct_on?: InputMaybe<Array<ShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShiftTypesOrderBy>>;
  where?: InputMaybe<ShiftTypesBoolExp>;
};

export type QueryRootShiftTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShiftTypesOrderBy>>;
  where?: InputMaybe<ShiftTypesBoolExp>;
};

export type QueryRootShiftTypesByPkArgs = {
  id: Scalars['Int'];
};

export type QueryRootTreatmentEmployeesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentEmployeesOrderBy>>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

export type QueryRootTreatmentEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentEmployeesOrderBy>>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

export type QueryRootTreatmentEmployeesByPkArgs = {
  employee_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

export type QueryRootTreatmentGroupsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentGroupsOrderBy>>;
  where?: InputMaybe<TreatmentGroupsBoolExp>;
};

export type QueryRootTreatmentGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentGroupsOrderBy>>;
  where?: InputMaybe<TreatmentGroupsBoolExp>;
};

export type QueryRootTreatmentGroupsByPkArgs = {
  id: Scalars['Int'];
};

export type QueryRootTreatmentResourcesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentResourcesOrderBy>>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

export type QueryRootTreatmentResourcesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentResourcesOrderBy>>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

export type QueryRootTreatmentResourcesByPkArgs = {
  resource_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

export type QueryRootTreatmentShiftTypesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentShiftTypesOrderBy>>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

export type QueryRootTreatmentShiftTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentShiftTypesOrderBy>>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

export type QueryRootTreatmentShiftTypesByPkArgs = {
  shift_type_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

export type QueryRootTreatmentSmsTreadsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentSmsTreadsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentSmsTreadsOrderBy>>;
  where?: InputMaybe<TreatmentSmsTreadsBoolExp>;
};

export type QueryRootTreatmentSmsTreadsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentSmsTreadsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentSmsTreadsOrderBy>>;
  where?: InputMaybe<TreatmentSmsTreadsBoolExp>;
};

export type QueryRootTreatmentSmsTreadsByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootTreatmentStatusArgs = {
  distinct_on?: InputMaybe<Array<TreatmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentStatusOrderBy>>;
  where?: InputMaybe<TreatmentStatusBoolExp>;
};

export type QueryRootTreatmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentStatusOrderBy>>;
  where?: InputMaybe<TreatmentStatusBoolExp>;
};

export type QueryRootTreatmentStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

export type QueryRootTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

export type QueryRootTreatmentsByPkArgs = {
  id: Scalars['Int'];
};

export type QueryRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type QueryRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type QueryRootUsersByPkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "resources" */
export type Resources = {
  __typename?: 'resources';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['Int'];
  provet_id: Scalars['String'];
  title: Scalars['String'];
  /** An array relationship */
  treatment_resources: Array<TreatmentResources>;
  /** An aggregate relationship */
  treatment_resources_aggregate: TreatmentResourcesAggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "resources" */
export type ResourcesTreatmentResourcesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentResourcesOrderBy>>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

/** columns and relationships of "resources" */
export type ResourcesTreatmentResourcesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentResourcesOrderBy>>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

/** aggregated selection of "resources" */
export type ResourcesAggregate = {
  __typename?: 'resources_aggregate';
  aggregate?: Maybe<ResourcesAggregateFields>;
  nodes: Array<Resources>;
};

export type ResourcesAggregateBoolExp = {
  count?: InputMaybe<ResourcesAggregateBoolExpCount>;
};

export type ResourcesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ResourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ResourcesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources" */
export type ResourcesAggregateFields = {
  __typename?: 'resources_aggregate_fields';
  avg?: Maybe<ResourcesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ResourcesMaxFields>;
  min?: Maybe<ResourcesMinFields>;
  stddev?: Maybe<ResourcesStddevFields>;
  stddev_pop?: Maybe<ResourcesStddevPopFields>;
  stddev_samp?: Maybe<ResourcesStddevSampFields>;
  sum?: Maybe<ResourcesSumFields>;
  var_pop?: Maybe<ResourcesVarPopFields>;
  var_samp?: Maybe<ResourcesVarSampFields>;
  variance?: Maybe<ResourcesVarianceFields>;
};

/** aggregate fields of "resources" */
export type ResourcesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ResourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "resources" */
export type ResourcesAggregateOrderBy = {
  avg?: InputMaybe<ResourcesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ResourcesMaxOrderBy>;
  min?: InputMaybe<ResourcesMinOrderBy>;
  stddev?: InputMaybe<ResourcesStddevOrderBy>;
  stddev_pop?: InputMaybe<ResourcesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ResourcesStddevSampOrderBy>;
  sum?: InputMaybe<ResourcesSumOrderBy>;
  var_pop?: InputMaybe<ResourcesVarPopOrderBy>;
  var_samp?: InputMaybe<ResourcesVarSampOrderBy>;
  variance?: InputMaybe<ResourcesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources" */
export type ResourcesArrRelInsertInput = {
  data: Array<ResourcesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ResourcesOnConflict>;
};

/** aggregate avg on columns */
export type ResourcesAvgFields = {
  __typename?: 'resources_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "resources" */
export type ResourcesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources". All fields are combined with a logical 'AND'. */
export type ResourcesBoolExp = {
  _and?: InputMaybe<Array<ResourcesBoolExp>>;
  _not?: InputMaybe<ResourcesBoolExp>;
  _or?: InputMaybe<Array<ResourcesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  location?: InputMaybe<LocationsBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
  provet_id?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  treatment_resources?: InputMaybe<TreatmentResourcesBoolExp>;
  treatment_resources_aggregate?: InputMaybe<TreatmentResourcesAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "resources" */
export enum ResourcesConstraint {
  /** unique or primary key constraint on columns "id" */
  RESOURCES_PKEY = 'resources_pkey',
}

/** input type for incrementing numeric columns in table "resources" */
export type ResourcesIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "resources" */
export type ResourcesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<LocationsObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['Int']>;
  provet_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  treatment_resources?: InputMaybe<TreatmentResourcesArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ResourcesMaxFields = {
  __typename?: 'resources_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  provet_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "resources" */
export type ResourcesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ResourcesMinFields = {
  __typename?: 'resources_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  provet_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "resources" */
export type ResourcesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources" */
export type ResourcesMutationResponse = {
  __typename?: 'resources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Resources>;
};

/** input type for inserting object relation for remote table "resources" */
export type ResourcesObjRelInsertInput = {
  data: ResourcesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ResourcesOnConflict>;
};

/** on_conflict condition type for table "resources" */
export type ResourcesOnConflict = {
  constraint: ResourcesConstraint;
  update_columns?: Array<ResourcesUpdateColumn>;
  where?: InputMaybe<ResourcesBoolExp>;
};

/** Ordering options when selecting data from "resources". */
export type ResourcesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<LocationsOrderBy>;
  location_id?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  treatment_resources_aggregate?: InputMaybe<TreatmentResourcesAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources */
export type ResourcesPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "resources" */
export enum ResourcesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LOCATION_ID = 'location_id',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
}

/** input type for updating data in table "resources" */
export type ResourcesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  provet_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ResourcesStddevFields = {
  __typename?: 'resources_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "resources" */
export type ResourcesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ResourcesStddevPopFields = {
  __typename?: 'resources_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "resources" */
export type ResourcesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ResourcesStddevSampFields = {
  __typename?: 'resources_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "resources" */
export type ResourcesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "resources" */
export type ResourcesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ResourcesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ResourcesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  provet_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ResourcesSumFields = {
  __typename?: 'resources_sum_fields';
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "resources" */
export type ResourcesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** update columns of table "resources" */
export enum ResourcesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LOCATION_ID = 'location_id',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
}

export type ResourcesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ResourcesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ResourcesSetInput>;
  /** filter the rows which have to be updated */
  where: ResourcesBoolExp;
};

/** aggregate var_pop on columns */
export type ResourcesVarPopFields = {
  __typename?: 'resources_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "resources" */
export type ResourcesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ResourcesVarSampFields = {
  __typename?: 'resources_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "resources" */
export type ResourcesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ResourcesVarianceFields = {
  __typename?: 'resources_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "resources" */
export type ResourcesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "shift_types" */
export type ShiftTypes = {
  __typename?: 'shift_types';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['Int'];
  provet_id: Scalars['String'];
  title: Scalars['String'];
  /** An array relationship */
  treatment_shift_types: Array<TreatmentShiftTypes>;
  /** An aggregate relationship */
  treatment_shift_types_aggregate: TreatmentShiftTypesAggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "shift_types" */
export type ShiftTypesTreatmentShiftTypesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentShiftTypesOrderBy>>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

/** columns and relationships of "shift_types" */
export type ShiftTypesTreatmentShiftTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentShiftTypesOrderBy>>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

/** aggregated selection of "shift_types" */
export type ShiftTypesAggregate = {
  __typename?: 'shift_types_aggregate';
  aggregate?: Maybe<ShiftTypesAggregateFields>;
  nodes: Array<ShiftTypes>;
};

export type ShiftTypesAggregateBoolExp = {
  count?: InputMaybe<ShiftTypesAggregateBoolExpCount>;
};

export type ShiftTypesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ShiftTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ShiftTypesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "shift_types" */
export type ShiftTypesAggregateFields = {
  __typename?: 'shift_types_aggregate_fields';
  avg?: Maybe<ShiftTypesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ShiftTypesMaxFields>;
  min?: Maybe<ShiftTypesMinFields>;
  stddev?: Maybe<ShiftTypesStddevFields>;
  stddev_pop?: Maybe<ShiftTypesStddevPopFields>;
  stddev_samp?: Maybe<ShiftTypesStddevSampFields>;
  sum?: Maybe<ShiftTypesSumFields>;
  var_pop?: Maybe<ShiftTypesVarPopFields>;
  var_samp?: Maybe<ShiftTypesVarSampFields>;
  variance?: Maybe<ShiftTypesVarianceFields>;
};

/** aggregate fields of "shift_types" */
export type ShiftTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ShiftTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "shift_types" */
export type ShiftTypesAggregateOrderBy = {
  avg?: InputMaybe<ShiftTypesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShiftTypesMaxOrderBy>;
  min?: InputMaybe<ShiftTypesMinOrderBy>;
  stddev?: InputMaybe<ShiftTypesStddevOrderBy>;
  stddev_pop?: InputMaybe<ShiftTypesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShiftTypesStddevSampOrderBy>;
  sum?: InputMaybe<ShiftTypesSumOrderBy>;
  var_pop?: InputMaybe<ShiftTypesVarPopOrderBy>;
  var_samp?: InputMaybe<ShiftTypesVarSampOrderBy>;
  variance?: InputMaybe<ShiftTypesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shift_types" */
export type ShiftTypesArrRelInsertInput = {
  data: Array<ShiftTypesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShiftTypesOnConflict>;
};

/** aggregate avg on columns */
export type ShiftTypesAvgFields = {
  __typename?: 'shift_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "shift_types" */
export type ShiftTypesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shift_types". All fields are combined with a logical 'AND'. */
export type ShiftTypesBoolExp = {
  _and?: InputMaybe<Array<ShiftTypesBoolExp>>;
  _not?: InputMaybe<ShiftTypesBoolExp>;
  _or?: InputMaybe<Array<ShiftTypesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  location?: InputMaybe<LocationsBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
  provet_id?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  treatment_shift_types?: InputMaybe<TreatmentShiftTypesBoolExp>;
  treatment_shift_types_aggregate?: InputMaybe<TreatmentShiftTypesAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "shift_types" */
export enum ShiftTypesConstraint {
  /** unique or primary key constraint on columns "id" */
  SHIFT_TYPES_PKEY = 'shift_types_pkey',
}

/** input type for incrementing numeric columns in table "shift_types" */
export type ShiftTypesIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shift_types" */
export type ShiftTypesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<LocationsObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['Int']>;
  provet_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  treatment_shift_types?: InputMaybe<TreatmentShiftTypesArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ShiftTypesMaxFields = {
  __typename?: 'shift_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  provet_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "shift_types" */
export type ShiftTypesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ShiftTypesMinFields = {
  __typename?: 'shift_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  provet_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "shift_types" */
export type ShiftTypesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shift_types" */
export type ShiftTypesMutationResponse = {
  __typename?: 'shift_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShiftTypes>;
};

/** input type for inserting object relation for remote table "shift_types" */
export type ShiftTypesObjRelInsertInput = {
  data: ShiftTypesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ShiftTypesOnConflict>;
};

/** on_conflict condition type for table "shift_types" */
export type ShiftTypesOnConflict = {
  constraint: ShiftTypesConstraint;
  update_columns?: Array<ShiftTypesUpdateColumn>;
  where?: InputMaybe<ShiftTypesBoolExp>;
};

/** Ordering options when selecting data from "shift_types". */
export type ShiftTypesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<LocationsOrderBy>;
  location_id?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  treatment_shift_types_aggregate?: InputMaybe<TreatmentShiftTypesAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shift_types */
export type ShiftTypesPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "shift_types" */
export enum ShiftTypesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LOCATION_ID = 'location_id',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
}

/** input type for updating data in table "shift_types" */
export type ShiftTypesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  provet_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ShiftTypesStddevFields = {
  __typename?: 'shift_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "shift_types" */
export type ShiftTypesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ShiftTypesStddevPopFields = {
  __typename?: 'shift_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "shift_types" */
export type ShiftTypesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ShiftTypesStddevSampFields = {
  __typename?: 'shift_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "shift_types" */
export type ShiftTypesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shift_types" */
export type ShiftTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShiftTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ShiftTypesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  provet_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ShiftTypesSumFields = {
  __typename?: 'shift_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "shift_types" */
export type ShiftTypesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** update columns of table "shift_types" */
export enum ShiftTypesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LOCATION_ID = 'location_id',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
}

export type ShiftTypesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShiftTypesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShiftTypesSetInput>;
  /** filter the rows which have to be updated */
  where: ShiftTypesBoolExp;
};

/** aggregate var_pop on columns */
export type ShiftTypesVarPopFields = {
  __typename?: 'shift_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "shift_types" */
export type ShiftTypesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ShiftTypesVarSampFields = {
  __typename?: 'shift_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "shift_types" */
export type ShiftTypesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ShiftTypesVarianceFields = {
  __typename?: 'shift_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "shift_types" */
export type ShiftTypesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "animal_genders" */
  animal_genders: Array<AnimalGenders>;
  /** fetch aggregated fields from the table: "animal_genders" */
  animal_genders_aggregate: AnimalGendersAggregate;
  /** fetch data from the table: "animal_genders" using primary key columns */
  animal_genders_by_pk?: Maybe<AnimalGenders>;
  /** fetch data from the table in a streaming manner: "animal_genders" */
  animal_genders_stream: Array<AnimalGenders>;
  /** fetch data from the table: "animal_species" */
  animal_species: Array<AnimalSpecies>;
  /** fetch aggregated fields from the table: "animal_species" */
  animal_species_aggregate: AnimalSpeciesAggregate;
  /** fetch data from the table: "animal_species" using primary key columns */
  animal_species_by_pk?: Maybe<AnimalSpecies>;
  /** fetch data from the table in a streaming manner: "animal_species" */
  animal_species_stream: Array<AnimalSpecies>;
  /** fetch data from the table: "booking_request" */
  booking_request: Array<BookingRequest>;
  /** fetch aggregated fields from the table: "booking_request" */
  booking_request_aggregate: BookingRequestAggregate;
  /** fetch data from the table: "booking_request" using primary key columns */
  booking_request_by_pk?: Maybe<BookingRequest>;
  /** fetch data from the table: "booking_request_status" */
  booking_request_status: Array<BookingRequestStatus>;
  /** fetch aggregated fields from the table: "booking_request_status" */
  booking_request_status_aggregate: BookingRequestStatusAggregate;
  /** fetch data from the table: "booking_request_status" using primary key columns */
  booking_request_status_by_pk?: Maybe<BookingRequestStatus>;
  /** fetch data from the table in a streaming manner: "booking_request_status" */
  booking_request_status_stream: Array<BookingRequestStatus>;
  /** fetch data from the table in a streaming manner: "booking_request" */
  booking_request_stream: Array<BookingRequest>;
  /** An array relationship */
  booking_request_treatments: Array<BookingRequestTreatments>;
  /** An aggregate relationship */
  booking_request_treatments_aggregate: BookingRequestTreatmentsAggregate;
  /** fetch data from the table: "booking_request_treatments" using primary key columns */
  booking_request_treatments_by_pk?: Maybe<BookingRequestTreatments>;
  /** fetch data from the table in a streaming manner: "booking_request_treatments" */
  booking_request_treatments_stream: Array<BookingRequestTreatments>;
  /** fetch data from the table: "employee_types" */
  employee_types: Array<EmployeeTypes>;
  /** fetch aggregated fields from the table: "employee_types" */
  employee_types_aggregate: EmployeeTypesAggregate;
  /** fetch data from the table: "employee_types" using primary key columns */
  employee_types_by_pk?: Maybe<EmployeeTypes>;
  /** fetch data from the table in a streaming manner: "employee_types" */
  employee_types_stream: Array<EmployeeTypes>;
  /** An array relationship */
  employees: Array<Employees>;
  /** An aggregate relationship */
  employees_aggregate: EmployeesAggregate;
  /** fetch data from the table: "employees" using primary key columns */
  employees_by_pk?: Maybe<Employees>;
  /** fetch data from the table in a streaming manner: "employees" */
  employees_stream: Array<Employees>;
  /** fetch data from the table: "icons" */
  icons: Array<Icons>;
  /** fetch aggregated fields from the table: "icons" */
  icons_aggregate: IconsAggregate;
  /** fetch data from the table: "icons" using primary key columns */
  icons_by_pk?: Maybe<Icons>;
  /** fetch data from the table in a streaming manner: "icons" */
  icons_stream: Array<Icons>;
  /** An array relationship */
  location_employees: Array<LocationEmployees>;
  /** An aggregate relationship */
  location_employees_aggregate: LocationEmployeesAggregate;
  /** fetch data from the table: "location_employees" using primary key columns */
  location_employees_by_pk?: Maybe<LocationEmployees>;
  /** fetch data from the table in a streaming manner: "location_employees" */
  location_employees_stream: Array<LocationEmployees>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: LocationsAggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** An array relationship */
  resources: Array<Resources>;
  /** An aggregate relationship */
  resources_aggregate: ResourcesAggregate;
  /** fetch data from the table: "resources" using primary key columns */
  resources_by_pk?: Maybe<Resources>;
  /** fetch data from the table in a streaming manner: "resources" */
  resources_stream: Array<Resources>;
  /** An array relationship */
  shift_types: Array<ShiftTypes>;
  /** An aggregate relationship */
  shift_types_aggregate: ShiftTypesAggregate;
  /** fetch data from the table: "shift_types" using primary key columns */
  shift_types_by_pk?: Maybe<ShiftTypes>;
  /** fetch data from the table in a streaming manner: "shift_types" */
  shift_types_stream: Array<ShiftTypes>;
  /** An array relationship */
  treatment_employees: Array<TreatmentEmployees>;
  /** An aggregate relationship */
  treatment_employees_aggregate: TreatmentEmployeesAggregate;
  /** fetch data from the table: "treatment_employees" using primary key columns */
  treatment_employees_by_pk?: Maybe<TreatmentEmployees>;
  /** fetch data from the table in a streaming manner: "treatment_employees" */
  treatment_employees_stream: Array<TreatmentEmployees>;
  /** An array relationship */
  treatment_groups: Array<TreatmentGroups>;
  /** An aggregate relationship */
  treatment_groups_aggregate: TreatmentGroupsAggregate;
  /** fetch data from the table: "treatment_groups" using primary key columns */
  treatment_groups_by_pk?: Maybe<TreatmentGroups>;
  /** fetch data from the table in a streaming manner: "treatment_groups" */
  treatment_groups_stream: Array<TreatmentGroups>;
  /** An array relationship */
  treatment_resources: Array<TreatmentResources>;
  /** An aggregate relationship */
  treatment_resources_aggregate: TreatmentResourcesAggregate;
  /** fetch data from the table: "treatment_resources" using primary key columns */
  treatment_resources_by_pk?: Maybe<TreatmentResources>;
  /** fetch data from the table in a streaming manner: "treatment_resources" */
  treatment_resources_stream: Array<TreatmentResources>;
  /** An array relationship */
  treatment_shift_types: Array<TreatmentShiftTypes>;
  /** An aggregate relationship */
  treatment_shift_types_aggregate: TreatmentShiftTypesAggregate;
  /** fetch data from the table: "treatment_shift_types" using primary key columns */
  treatment_shift_types_by_pk?: Maybe<TreatmentShiftTypes>;
  /** fetch data from the table in a streaming manner: "treatment_shift_types" */
  treatment_shift_types_stream: Array<TreatmentShiftTypes>;
  /** fetch data from the table: "treatment_sms_treads" */
  treatment_sms_treads: Array<TreatmentSmsTreads>;
  /** fetch aggregated fields from the table: "treatment_sms_treads" */
  treatment_sms_treads_aggregate: TreatmentSmsTreadsAggregate;
  /** fetch data from the table: "treatment_sms_treads" using primary key columns */
  treatment_sms_treads_by_pk?: Maybe<TreatmentSmsTreads>;
  /** fetch data from the table in a streaming manner: "treatment_sms_treads" */
  treatment_sms_treads_stream: Array<TreatmentSmsTreads>;
  /** fetch data from the table: "treatment_status" */
  treatment_status: Array<TreatmentStatus>;
  /** fetch aggregated fields from the table: "treatment_status" */
  treatment_status_aggregate: TreatmentStatusAggregate;
  /** fetch data from the table: "treatment_status" using primary key columns */
  treatment_status_by_pk?: Maybe<TreatmentStatus>;
  /** fetch data from the table in a streaming manner: "treatment_status" */
  treatment_status_stream: Array<TreatmentStatus>;
  /** An array relationship */
  treatments: Array<Treatments>;
  /** An aggregate relationship */
  treatments_aggregate: TreatmentsAggregate;
  /** fetch data from the table: "treatments" using primary key columns */
  treatments_by_pk?: Maybe<Treatments>;
  /** fetch data from the table in a streaming manner: "treatments" */
  treatments_stream: Array<Treatments>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};

export type SubscriptionRootAnimalGendersArgs = {
  distinct_on?: InputMaybe<Array<AnimalGendersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnimalGendersOrderBy>>;
  where?: InputMaybe<AnimalGendersBoolExp>;
};

export type SubscriptionRootAnimalGendersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AnimalGendersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnimalGendersOrderBy>>;
  where?: InputMaybe<AnimalGendersBoolExp>;
};

export type SubscriptionRootAnimalGendersByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootAnimalGendersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AnimalGendersStreamCursorInput>>;
  where?: InputMaybe<AnimalGendersBoolExp>;
};

export type SubscriptionRootAnimalSpeciesArgs = {
  distinct_on?: InputMaybe<Array<AnimalSpeciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnimalSpeciesOrderBy>>;
  where?: InputMaybe<AnimalSpeciesBoolExp>;
};

export type SubscriptionRootAnimalSpeciesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AnimalSpeciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnimalSpeciesOrderBy>>;
  where?: InputMaybe<AnimalSpeciesBoolExp>;
};

export type SubscriptionRootAnimalSpeciesByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootAnimalSpeciesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AnimalSpeciesStreamCursorInput>>;
  where?: InputMaybe<AnimalSpeciesBoolExp>;
};

export type SubscriptionRootBookingRequestArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

export type SubscriptionRootBookingRequestAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

export type SubscriptionRootBookingRequestByPkArgs = {
  id: Scalars['uuid'];
};

export type SubscriptionRootBookingRequestStatusArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestStatusOrderBy>>;
  where?: InputMaybe<BookingRequestStatusBoolExp>;
};

export type SubscriptionRootBookingRequestStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestStatusOrderBy>>;
  where?: InputMaybe<BookingRequestStatusBoolExp>;
};

export type SubscriptionRootBookingRequestStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootBookingRequestStatusStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BookingRequestStatusStreamCursorInput>>;
  where?: InputMaybe<BookingRequestStatusBoolExp>;
};

export type SubscriptionRootBookingRequestStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BookingRequestStreamCursorInput>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

export type SubscriptionRootBookingRequestTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestTreatmentsOrderBy>>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

export type SubscriptionRootBookingRequestTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestTreatmentsOrderBy>>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

export type SubscriptionRootBookingRequestTreatmentsByPkArgs = {
  booking_request_id: Scalars['uuid'];
  treatment_id: Scalars['Int'];
};

export type SubscriptionRootBookingRequestTreatmentsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BookingRequestTreatmentsStreamCursorInput>>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

export type SubscriptionRootEmployeeTypesArgs = {
  distinct_on?: InputMaybe<Array<EmployeeTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeTypesOrderBy>>;
  where?: InputMaybe<EmployeeTypesBoolExp>;
};

export type SubscriptionRootEmployeeTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeTypesOrderBy>>;
  where?: InputMaybe<EmployeeTypesBoolExp>;
};

export type SubscriptionRootEmployeeTypesByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEmployeeTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmployeeTypesStreamCursorInput>>;
  where?: InputMaybe<EmployeeTypesBoolExp>;
};

export type SubscriptionRootEmployeesArgs = {
  distinct_on?: InputMaybe<Array<EmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeesOrderBy>>;
  where?: InputMaybe<EmployeesBoolExp>;
};

export type SubscriptionRootEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeesOrderBy>>;
  where?: InputMaybe<EmployeesBoolExp>;
};

export type SubscriptionRootEmployeesByPkArgs = {
  id: Scalars['Int'];
};

export type SubscriptionRootEmployeesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmployeesStreamCursorInput>>;
  where?: InputMaybe<EmployeesBoolExp>;
};

export type SubscriptionRootIconsArgs = {
  distinct_on?: InputMaybe<Array<IconsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IconsOrderBy>>;
  where?: InputMaybe<IconsBoolExp>;
};

export type SubscriptionRootIconsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IconsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IconsOrderBy>>;
  where?: InputMaybe<IconsBoolExp>;
};

export type SubscriptionRootIconsByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootIconsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IconsStreamCursorInput>>;
  where?: InputMaybe<IconsBoolExp>;
};

export type SubscriptionRootLocationEmployeesArgs = {
  distinct_on?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationEmployeesOrderBy>>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

export type SubscriptionRootLocationEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationEmployeesOrderBy>>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

export type SubscriptionRootLocationEmployeesByPkArgs = {
  employee_id: Scalars['Int'];
  location_id: Scalars['Int'];
};

export type SubscriptionRootLocationEmployeesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LocationEmployeesStreamCursorInput>>;
  where?: InputMaybe<LocationEmployeesBoolExp>;
};

export type SubscriptionRootLocationsArgs = {
  distinct_on?: InputMaybe<Array<LocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationsOrderBy>>;
  where?: InputMaybe<LocationsBoolExp>;
};

export type SubscriptionRootLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationsOrderBy>>;
  where?: InputMaybe<LocationsBoolExp>;
};

export type SubscriptionRootLocationsByPkArgs = {
  id: Scalars['Int'];
};

export type SubscriptionRootLocationsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LocationsStreamCursorInput>>;
  where?: InputMaybe<LocationsBoolExp>;
};

export type SubscriptionRootResourcesArgs = {
  distinct_on?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type SubscriptionRootResourcesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type SubscriptionRootResourcesByPkArgs = {
  id: Scalars['Int'];
};

export type SubscriptionRootResourcesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ResourcesStreamCursorInput>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type SubscriptionRootShiftTypesArgs = {
  distinct_on?: InputMaybe<Array<ShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShiftTypesOrderBy>>;
  where?: InputMaybe<ShiftTypesBoolExp>;
};

export type SubscriptionRootShiftTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShiftTypesOrderBy>>;
  where?: InputMaybe<ShiftTypesBoolExp>;
};

export type SubscriptionRootShiftTypesByPkArgs = {
  id: Scalars['Int'];
};

export type SubscriptionRootShiftTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShiftTypesStreamCursorInput>>;
  where?: InputMaybe<ShiftTypesBoolExp>;
};

export type SubscriptionRootTreatmentEmployeesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentEmployeesOrderBy>>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

export type SubscriptionRootTreatmentEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentEmployeesOrderBy>>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

export type SubscriptionRootTreatmentEmployeesByPkArgs = {
  employee_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

export type SubscriptionRootTreatmentEmployeesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentEmployeesStreamCursorInput>>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

export type SubscriptionRootTreatmentGroupsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentGroupsOrderBy>>;
  where?: InputMaybe<TreatmentGroupsBoolExp>;
};

export type SubscriptionRootTreatmentGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentGroupsOrderBy>>;
  where?: InputMaybe<TreatmentGroupsBoolExp>;
};

export type SubscriptionRootTreatmentGroupsByPkArgs = {
  id: Scalars['Int'];
};

export type SubscriptionRootTreatmentGroupsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentGroupsStreamCursorInput>>;
  where?: InputMaybe<TreatmentGroupsBoolExp>;
};

export type SubscriptionRootTreatmentResourcesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentResourcesOrderBy>>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

export type SubscriptionRootTreatmentResourcesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentResourcesOrderBy>>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

export type SubscriptionRootTreatmentResourcesByPkArgs = {
  resource_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

export type SubscriptionRootTreatmentResourcesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentResourcesStreamCursorInput>>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

export type SubscriptionRootTreatmentShiftTypesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentShiftTypesOrderBy>>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

export type SubscriptionRootTreatmentShiftTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentShiftTypesOrderBy>>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

export type SubscriptionRootTreatmentShiftTypesByPkArgs = {
  shift_type_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

export type SubscriptionRootTreatmentShiftTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentShiftTypesStreamCursorInput>>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

export type SubscriptionRootTreatmentSmsTreadsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentSmsTreadsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentSmsTreadsOrderBy>>;
  where?: InputMaybe<TreatmentSmsTreadsBoolExp>;
};

export type SubscriptionRootTreatmentSmsTreadsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentSmsTreadsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentSmsTreadsOrderBy>>;
  where?: InputMaybe<TreatmentSmsTreadsBoolExp>;
};

export type SubscriptionRootTreatmentSmsTreadsByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootTreatmentSmsTreadsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentSmsTreadsStreamCursorInput>>;
  where?: InputMaybe<TreatmentSmsTreadsBoolExp>;
};

export type SubscriptionRootTreatmentStatusArgs = {
  distinct_on?: InputMaybe<Array<TreatmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentStatusOrderBy>>;
  where?: InputMaybe<TreatmentStatusBoolExp>;
};

export type SubscriptionRootTreatmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentStatusOrderBy>>;
  where?: InputMaybe<TreatmentStatusBoolExp>;
};

export type SubscriptionRootTreatmentStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootTreatmentStatusStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentStatusStreamCursorInput>>;
  where?: InputMaybe<TreatmentStatusBoolExp>;
};

export type SubscriptionRootTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

export type SubscriptionRootTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

export type SubscriptionRootTreatmentsByPkArgs = {
  id: Scalars['Int'];
};

export type SubscriptionRootTreatmentsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentsStreamCursorInput>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

export type SubscriptionRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type SubscriptionRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type SubscriptionRootUsersByPkArgs = {
  id: Scalars['uuid'];
};

export type SubscriptionRootUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "treatment_employees" */
export type TreatmentEmployees = {
  __typename?: 'treatment_employees';
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['Int'];
  priority: Scalars['Int'];
  /** An object relationship */
  treatment: Treatments;
  treatment_id: Scalars['Int'];
};

/** aggregated selection of "treatment_employees" */
export type TreatmentEmployeesAggregate = {
  __typename?: 'treatment_employees_aggregate';
  aggregate?: Maybe<TreatmentEmployeesAggregateFields>;
  nodes: Array<TreatmentEmployees>;
};

export type TreatmentEmployeesAggregateBoolExp = {
  count?: InputMaybe<TreatmentEmployeesAggregateBoolExpCount>;
};

export type TreatmentEmployeesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TreatmentEmployeesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "treatment_employees" */
export type TreatmentEmployeesAggregateFields = {
  __typename?: 'treatment_employees_aggregate_fields';
  avg?: Maybe<TreatmentEmployeesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentEmployeesMaxFields>;
  min?: Maybe<TreatmentEmployeesMinFields>;
  stddev?: Maybe<TreatmentEmployeesStddevFields>;
  stddev_pop?: Maybe<TreatmentEmployeesStddevPopFields>;
  stddev_samp?: Maybe<TreatmentEmployeesStddevSampFields>;
  sum?: Maybe<TreatmentEmployeesSumFields>;
  var_pop?: Maybe<TreatmentEmployeesVarPopFields>;
  var_samp?: Maybe<TreatmentEmployeesVarSampFields>;
  variance?: Maybe<TreatmentEmployeesVarianceFields>;
};

/** aggregate fields of "treatment_employees" */
export type TreatmentEmployeesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "treatment_employees" */
export type TreatmentEmployeesAggregateOrderBy = {
  avg?: InputMaybe<TreatmentEmployeesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TreatmentEmployeesMaxOrderBy>;
  min?: InputMaybe<TreatmentEmployeesMinOrderBy>;
  stddev?: InputMaybe<TreatmentEmployeesStddevOrderBy>;
  stddev_pop?: InputMaybe<TreatmentEmployeesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TreatmentEmployeesStddevSampOrderBy>;
  sum?: InputMaybe<TreatmentEmployeesSumOrderBy>;
  var_pop?: InputMaybe<TreatmentEmployeesVarPopOrderBy>;
  var_samp?: InputMaybe<TreatmentEmployeesVarSampOrderBy>;
  variance?: InputMaybe<TreatmentEmployeesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "treatment_employees" */
export type TreatmentEmployeesArrRelInsertInput = {
  data: Array<TreatmentEmployeesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TreatmentEmployeesOnConflict>;
};

/** aggregate avg on columns */
export type TreatmentEmployeesAvgFields = {
  __typename?: 'treatment_employees_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "treatment_employees" */
export type TreatmentEmployeesAvgOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "treatment_employees". All fields are combined with a logical 'AND'. */
export type TreatmentEmployeesBoolExp = {
  _and?: InputMaybe<Array<TreatmentEmployeesBoolExp>>;
  _not?: InputMaybe<TreatmentEmployeesBoolExp>;
  _or?: InputMaybe<Array<TreatmentEmployeesBoolExp>>;
  employee?: InputMaybe<EmployeesBoolExp>;
  employee_id?: InputMaybe<IntComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  treatment?: InputMaybe<TreatmentsBoolExp>;
  treatment_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "treatment_employees" */
export enum TreatmentEmployeesConstraint {
  /** unique or primary key constraint on columns "employee_id", "treatment_id" */
  TREATMENT_EMPLOYEES_PKEY = 'treatment_employees_pkey',
}

/** input type for incrementing numeric columns in table "treatment_employees" */
export type TreatmentEmployeesIncInput = {
  employee_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "treatment_employees" */
export type TreatmentEmployeesInsertInput = {
  employee?: InputMaybe<EmployeesObjRelInsertInput>;
  employee_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  treatment?: InputMaybe<TreatmentsObjRelInsertInput>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TreatmentEmployeesMaxFields = {
  __typename?: 'treatment_employees_max_fields';
  employee_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "treatment_employees" */
export type TreatmentEmployeesMaxOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TreatmentEmployeesMinFields = {
  __typename?: 'treatment_employees_min_fields';
  employee_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "treatment_employees" */
export type TreatmentEmployeesMinOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "treatment_employees" */
export type TreatmentEmployeesMutationResponse = {
  __typename?: 'treatment_employees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentEmployees>;
};

/** on_conflict condition type for table "treatment_employees" */
export type TreatmentEmployeesOnConflict = {
  constraint: TreatmentEmployeesConstraint;
  update_columns?: Array<TreatmentEmployeesUpdateColumn>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

/** Ordering options when selecting data from "treatment_employees". */
export type TreatmentEmployeesOrderBy = {
  employee?: InputMaybe<EmployeesOrderBy>;
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment?: InputMaybe<TreatmentsOrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: treatment_employees */
export type TreatmentEmployeesPkColumnsInput = {
  employee_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

/** select columns of table "treatment_employees" */
export enum TreatmentEmployeesSelectColumn {
  /** column name */
  EMPLOYEE_ID = 'employee_id',
  /** column name */
  PRIORITY = 'priority',
  /** column name */
  TREATMENT_ID = 'treatment_id',
}

/** input type for updating data in table "treatment_employees" */
export type TreatmentEmployeesSetInput = {
  employee_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TreatmentEmployeesStddevFields = {
  __typename?: 'treatment_employees_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "treatment_employees" */
export type TreatmentEmployeesStddevOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TreatmentEmployeesStddevPopFields = {
  __typename?: 'treatment_employees_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "treatment_employees" */
export type TreatmentEmployeesStddevPopOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TreatmentEmployeesStddevSampFields = {
  __typename?: 'treatment_employees_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "treatment_employees" */
export type TreatmentEmployeesStddevSampOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatment_employees" */
export type TreatmentEmployeesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TreatmentEmployeesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentEmployeesStreamCursorValueInput = {
  employee_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TreatmentEmployeesSumFields = {
  __typename?: 'treatment_employees_sum_fields';
  employee_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "treatment_employees" */
export type TreatmentEmployeesSumOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** update columns of table "treatment_employees" */
export enum TreatmentEmployeesUpdateColumn {
  /** column name */
  EMPLOYEE_ID = 'employee_id',
  /** column name */
  PRIORITY = 'priority',
  /** column name */
  TREATMENT_ID = 'treatment_id',
}

export type TreatmentEmployeesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentEmployeesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentEmployeesSetInput>;
  /** filter the rows which have to be updated */
  where: TreatmentEmployeesBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentEmployeesVarPopFields = {
  __typename?: 'treatment_employees_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "treatment_employees" */
export type TreatmentEmployeesVarPopOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TreatmentEmployeesVarSampFields = {
  __typename?: 'treatment_employees_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "treatment_employees" */
export type TreatmentEmployeesVarSampOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TreatmentEmployeesVarianceFields = {
  __typename?: 'treatment_employees_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "treatment_employees" */
export type TreatmentEmployeesVarianceOrderBy = {
  employee_id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "treatment_groups" */
export type TreatmentGroups = {
  __typename?: 'treatment_groups';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  icon: IconsEnum;
  /** An object relationship */
  iconByIcon: Icons;
  id: Scalars['Int'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  /** An array relationship */
  treatments: Array<Treatments>;
  /** An aggregate relationship */
  treatments_aggregate: TreatmentsAggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "treatment_groups" */
export type TreatmentGroupsTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** columns and relationships of "treatment_groups" */
export type TreatmentGroupsTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** aggregated selection of "treatment_groups" */
export type TreatmentGroupsAggregate = {
  __typename?: 'treatment_groups_aggregate';
  aggregate?: Maybe<TreatmentGroupsAggregateFields>;
  nodes: Array<TreatmentGroups>;
};

export type TreatmentGroupsAggregateBoolExp = {
  count?: InputMaybe<TreatmentGroupsAggregateBoolExpCount>;
};

export type TreatmentGroupsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TreatmentGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TreatmentGroupsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "treatment_groups" */
export type TreatmentGroupsAggregateFields = {
  __typename?: 'treatment_groups_aggregate_fields';
  avg?: Maybe<TreatmentGroupsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentGroupsMaxFields>;
  min?: Maybe<TreatmentGroupsMinFields>;
  stddev?: Maybe<TreatmentGroupsStddevFields>;
  stddev_pop?: Maybe<TreatmentGroupsStddevPopFields>;
  stddev_samp?: Maybe<TreatmentGroupsStddevSampFields>;
  sum?: Maybe<TreatmentGroupsSumFields>;
  var_pop?: Maybe<TreatmentGroupsVarPopFields>;
  var_samp?: Maybe<TreatmentGroupsVarSampFields>;
  variance?: Maybe<TreatmentGroupsVarianceFields>;
};

/** aggregate fields of "treatment_groups" */
export type TreatmentGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "treatment_groups" */
export type TreatmentGroupsAggregateOrderBy = {
  avg?: InputMaybe<TreatmentGroupsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TreatmentGroupsMaxOrderBy>;
  min?: InputMaybe<TreatmentGroupsMinOrderBy>;
  stddev?: InputMaybe<TreatmentGroupsStddevOrderBy>;
  stddev_pop?: InputMaybe<TreatmentGroupsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TreatmentGroupsStddevSampOrderBy>;
  sum?: InputMaybe<TreatmentGroupsSumOrderBy>;
  var_pop?: InputMaybe<TreatmentGroupsVarPopOrderBy>;
  var_samp?: InputMaybe<TreatmentGroupsVarSampOrderBy>;
  variance?: InputMaybe<TreatmentGroupsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "treatment_groups" */
export type TreatmentGroupsArrRelInsertInput = {
  data: Array<TreatmentGroupsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TreatmentGroupsOnConflict>;
};

/** aggregate avg on columns */
export type TreatmentGroupsAvgFields = {
  __typename?: 'treatment_groups_avg_fields';
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "treatment_groups" */
export type TreatmentGroupsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "treatment_groups". All fields are combined with a logical 'AND'. */
export type TreatmentGroupsBoolExp = {
  _and?: InputMaybe<Array<TreatmentGroupsBoolExp>>;
  _not?: InputMaybe<TreatmentGroupsBoolExp>;
  _or?: InputMaybe<Array<TreatmentGroupsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  icon?: InputMaybe<IconsEnumComparisonExp>;
  iconByIcon?: InputMaybe<IconsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  treatments?: InputMaybe<TreatmentsBoolExp>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "treatment_groups" */
export enum TreatmentGroupsConstraint {
  /** unique or primary key constraint on columns "id" */
  TREATMENT_GROUPS_PKEY = 'treatment_groups_pkey',
}

/** input type for incrementing numeric columns in table "treatment_groups" */
export type TreatmentGroupsIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "treatment_groups" */
export type TreatmentGroupsInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<IconsEnum>;
  iconByIcon?: InputMaybe<IconsObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  treatments?: InputMaybe<TreatmentsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TreatmentGroupsMaxFields = {
  __typename?: 'treatment_groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "treatment_groups" */
export type TreatmentGroupsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TreatmentGroupsMinFields = {
  __typename?: 'treatment_groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "treatment_groups" */
export type TreatmentGroupsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "treatment_groups" */
export type TreatmentGroupsMutationResponse = {
  __typename?: 'treatment_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentGroups>;
};

/** input type for inserting object relation for remote table "treatment_groups" */
export type TreatmentGroupsObjRelInsertInput = {
  data: TreatmentGroupsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TreatmentGroupsOnConflict>;
};

/** on_conflict condition type for table "treatment_groups" */
export type TreatmentGroupsOnConflict = {
  constraint: TreatmentGroupsConstraint;
  update_columns?: Array<TreatmentGroupsUpdateColumn>;
  where?: InputMaybe<TreatmentGroupsBoolExp>;
};

/** Ordering options when selecting data from "treatment_groups". */
export type TreatmentGroupsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  icon?: InputMaybe<OrderBy>;
  iconByIcon?: InputMaybe<IconsOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: treatment_groups */
export type TreatmentGroupsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "treatment_groups" */
export enum TreatmentGroupsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ICON = 'icon',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PRIORITY = 'priority',
  /** column name */
  UPDATED_AT = 'updated_at',
}

/** input type for updating data in table "treatment_groups" */
export type TreatmentGroupsSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<IconsEnum>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TreatmentGroupsStddevFields = {
  __typename?: 'treatment_groups_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "treatment_groups" */
export type TreatmentGroupsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TreatmentGroupsStddevPopFields = {
  __typename?: 'treatment_groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "treatment_groups" */
export type TreatmentGroupsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TreatmentGroupsStddevSampFields = {
  __typename?: 'treatment_groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "treatment_groups" */
export type TreatmentGroupsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatment_groups" */
export type TreatmentGroupsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TreatmentGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentGroupsStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<IconsEnum>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TreatmentGroupsSumFields = {
  __typename?: 'treatment_groups_sum_fields';
  id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "treatment_groups" */
export type TreatmentGroupsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "treatment_groups" */
export enum TreatmentGroupsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ICON = 'icon',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PRIORITY = 'priority',
  /** column name */
  UPDATED_AT = 'updated_at',
}

export type TreatmentGroupsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentGroupsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentGroupsSetInput>;
  /** filter the rows which have to be updated */
  where: TreatmentGroupsBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentGroupsVarPopFields = {
  __typename?: 'treatment_groups_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "treatment_groups" */
export type TreatmentGroupsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TreatmentGroupsVarSampFields = {
  __typename?: 'treatment_groups_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "treatment_groups" */
export type TreatmentGroupsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TreatmentGroupsVarianceFields = {
  __typename?: 'treatment_groups_variance_fields';
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "treatment_groups" */
export type TreatmentGroupsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** columns and relationships of "treatment_resources" */
export type TreatmentResources = {
  __typename?: 'treatment_resources';
  /** An object relationship */
  resource: Resources;
  resource_id: Scalars['Int'];
  /** An object relationship */
  treatment: Treatments;
  treatment_id: Scalars['Int'];
};

/** aggregated selection of "treatment_resources" */
export type TreatmentResourcesAggregate = {
  __typename?: 'treatment_resources_aggregate';
  aggregate?: Maybe<TreatmentResourcesAggregateFields>;
  nodes: Array<TreatmentResources>;
};

export type TreatmentResourcesAggregateBoolExp = {
  count?: InputMaybe<TreatmentResourcesAggregateBoolExpCount>;
};

export type TreatmentResourcesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TreatmentResourcesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "treatment_resources" */
export type TreatmentResourcesAggregateFields = {
  __typename?: 'treatment_resources_aggregate_fields';
  avg?: Maybe<TreatmentResourcesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentResourcesMaxFields>;
  min?: Maybe<TreatmentResourcesMinFields>;
  stddev?: Maybe<TreatmentResourcesStddevFields>;
  stddev_pop?: Maybe<TreatmentResourcesStddevPopFields>;
  stddev_samp?: Maybe<TreatmentResourcesStddevSampFields>;
  sum?: Maybe<TreatmentResourcesSumFields>;
  var_pop?: Maybe<TreatmentResourcesVarPopFields>;
  var_samp?: Maybe<TreatmentResourcesVarSampFields>;
  variance?: Maybe<TreatmentResourcesVarianceFields>;
};

/** aggregate fields of "treatment_resources" */
export type TreatmentResourcesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "treatment_resources" */
export type TreatmentResourcesAggregateOrderBy = {
  avg?: InputMaybe<TreatmentResourcesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TreatmentResourcesMaxOrderBy>;
  min?: InputMaybe<TreatmentResourcesMinOrderBy>;
  stddev?: InputMaybe<TreatmentResourcesStddevOrderBy>;
  stddev_pop?: InputMaybe<TreatmentResourcesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TreatmentResourcesStddevSampOrderBy>;
  sum?: InputMaybe<TreatmentResourcesSumOrderBy>;
  var_pop?: InputMaybe<TreatmentResourcesVarPopOrderBy>;
  var_samp?: InputMaybe<TreatmentResourcesVarSampOrderBy>;
  variance?: InputMaybe<TreatmentResourcesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "treatment_resources" */
export type TreatmentResourcesArrRelInsertInput = {
  data: Array<TreatmentResourcesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TreatmentResourcesOnConflict>;
};

/** aggregate avg on columns */
export type TreatmentResourcesAvgFields = {
  __typename?: 'treatment_resources_avg_fields';
  resource_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "treatment_resources" */
export type TreatmentResourcesAvgOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "treatment_resources". All fields are combined with a logical 'AND'. */
export type TreatmentResourcesBoolExp = {
  _and?: InputMaybe<Array<TreatmentResourcesBoolExp>>;
  _not?: InputMaybe<TreatmentResourcesBoolExp>;
  _or?: InputMaybe<Array<TreatmentResourcesBoolExp>>;
  resource?: InputMaybe<ResourcesBoolExp>;
  resource_id?: InputMaybe<IntComparisonExp>;
  treatment?: InputMaybe<TreatmentsBoolExp>;
  treatment_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "treatment_resources" */
export enum TreatmentResourcesConstraint {
  /** unique or primary key constraint on columns "resource_id", "treatment_id" */
  TREATMENT_RESOURCES_PKEY = 'treatment_resources_pkey',
}

/** input type for incrementing numeric columns in table "treatment_resources" */
export type TreatmentResourcesIncInput = {
  resource_id?: InputMaybe<Scalars['Int']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "treatment_resources" */
export type TreatmentResourcesInsertInput = {
  resource?: InputMaybe<ResourcesObjRelInsertInput>;
  resource_id?: InputMaybe<Scalars['Int']>;
  treatment?: InputMaybe<TreatmentsObjRelInsertInput>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TreatmentResourcesMaxFields = {
  __typename?: 'treatment_resources_max_fields';
  resource_id?: Maybe<Scalars['Int']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "treatment_resources" */
export type TreatmentResourcesMaxOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TreatmentResourcesMinFields = {
  __typename?: 'treatment_resources_min_fields';
  resource_id?: Maybe<Scalars['Int']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "treatment_resources" */
export type TreatmentResourcesMinOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "treatment_resources" */
export type TreatmentResourcesMutationResponse = {
  __typename?: 'treatment_resources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentResources>;
};

/** on_conflict condition type for table "treatment_resources" */
export type TreatmentResourcesOnConflict = {
  constraint: TreatmentResourcesConstraint;
  update_columns?: Array<TreatmentResourcesUpdateColumn>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

/** Ordering options when selecting data from "treatment_resources". */
export type TreatmentResourcesOrderBy = {
  resource?: InputMaybe<ResourcesOrderBy>;
  resource_id?: InputMaybe<OrderBy>;
  treatment?: InputMaybe<TreatmentsOrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: treatment_resources */
export type TreatmentResourcesPkColumnsInput = {
  resource_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

/** select columns of table "treatment_resources" */
export enum TreatmentResourcesSelectColumn {
  /** column name */
  RESOURCE_ID = 'resource_id',
  /** column name */
  TREATMENT_ID = 'treatment_id',
}

/** input type for updating data in table "treatment_resources" */
export type TreatmentResourcesSetInput = {
  resource_id?: InputMaybe<Scalars['Int']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TreatmentResourcesStddevFields = {
  __typename?: 'treatment_resources_stddev_fields';
  resource_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "treatment_resources" */
export type TreatmentResourcesStddevOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TreatmentResourcesStddevPopFields = {
  __typename?: 'treatment_resources_stddev_pop_fields';
  resource_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "treatment_resources" */
export type TreatmentResourcesStddevPopOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TreatmentResourcesStddevSampFields = {
  __typename?: 'treatment_resources_stddev_samp_fields';
  resource_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "treatment_resources" */
export type TreatmentResourcesStddevSampOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatment_resources" */
export type TreatmentResourcesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TreatmentResourcesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentResourcesStreamCursorValueInput = {
  resource_id?: InputMaybe<Scalars['Int']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TreatmentResourcesSumFields = {
  __typename?: 'treatment_resources_sum_fields';
  resource_id?: Maybe<Scalars['Int']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "treatment_resources" */
export type TreatmentResourcesSumOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** update columns of table "treatment_resources" */
export enum TreatmentResourcesUpdateColumn {
  /** column name */
  RESOURCE_ID = 'resource_id',
  /** column name */
  TREATMENT_ID = 'treatment_id',
}

export type TreatmentResourcesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentResourcesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentResourcesSetInput>;
  /** filter the rows which have to be updated */
  where: TreatmentResourcesBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentResourcesVarPopFields = {
  __typename?: 'treatment_resources_var_pop_fields';
  resource_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "treatment_resources" */
export type TreatmentResourcesVarPopOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TreatmentResourcesVarSampFields = {
  __typename?: 'treatment_resources_var_samp_fields';
  resource_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "treatment_resources" */
export type TreatmentResourcesVarSampOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TreatmentResourcesVarianceFields = {
  __typename?: 'treatment_resources_variance_fields';
  resource_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "treatment_resources" */
export type TreatmentResourcesVarianceOrderBy = {
  resource_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "treatment_shift_types" */
export type TreatmentShiftTypes = {
  __typename?: 'treatment_shift_types';
  /** An object relationship */
  shift_type: ShiftTypes;
  shift_type_id: Scalars['Int'];
  /** An object relationship */
  treatment: Treatments;
  treatment_id: Scalars['Int'];
};

/** aggregated selection of "treatment_shift_types" */
export type TreatmentShiftTypesAggregate = {
  __typename?: 'treatment_shift_types_aggregate';
  aggregate?: Maybe<TreatmentShiftTypesAggregateFields>;
  nodes: Array<TreatmentShiftTypes>;
};

export type TreatmentShiftTypesAggregateBoolExp = {
  count?: InputMaybe<TreatmentShiftTypesAggregateBoolExpCount>;
};

export type TreatmentShiftTypesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TreatmentShiftTypesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "treatment_shift_types" */
export type TreatmentShiftTypesAggregateFields = {
  __typename?: 'treatment_shift_types_aggregate_fields';
  avg?: Maybe<TreatmentShiftTypesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentShiftTypesMaxFields>;
  min?: Maybe<TreatmentShiftTypesMinFields>;
  stddev?: Maybe<TreatmentShiftTypesStddevFields>;
  stddev_pop?: Maybe<TreatmentShiftTypesStddevPopFields>;
  stddev_samp?: Maybe<TreatmentShiftTypesStddevSampFields>;
  sum?: Maybe<TreatmentShiftTypesSumFields>;
  var_pop?: Maybe<TreatmentShiftTypesVarPopFields>;
  var_samp?: Maybe<TreatmentShiftTypesVarSampFields>;
  variance?: Maybe<TreatmentShiftTypesVarianceFields>;
};

/** aggregate fields of "treatment_shift_types" */
export type TreatmentShiftTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "treatment_shift_types" */
export type TreatmentShiftTypesAggregateOrderBy = {
  avg?: InputMaybe<TreatmentShiftTypesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TreatmentShiftTypesMaxOrderBy>;
  min?: InputMaybe<TreatmentShiftTypesMinOrderBy>;
  stddev?: InputMaybe<TreatmentShiftTypesStddevOrderBy>;
  stddev_pop?: InputMaybe<TreatmentShiftTypesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TreatmentShiftTypesStddevSampOrderBy>;
  sum?: InputMaybe<TreatmentShiftTypesSumOrderBy>;
  var_pop?: InputMaybe<TreatmentShiftTypesVarPopOrderBy>;
  var_samp?: InputMaybe<TreatmentShiftTypesVarSampOrderBy>;
  variance?: InputMaybe<TreatmentShiftTypesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "treatment_shift_types" */
export type TreatmentShiftTypesArrRelInsertInput = {
  data: Array<TreatmentShiftTypesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TreatmentShiftTypesOnConflict>;
};

/** aggregate avg on columns */
export type TreatmentShiftTypesAvgFields = {
  __typename?: 'treatment_shift_types_avg_fields';
  shift_type_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesAvgOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "treatment_shift_types". All fields are combined with a logical 'AND'. */
export type TreatmentShiftTypesBoolExp = {
  _and?: InputMaybe<Array<TreatmentShiftTypesBoolExp>>;
  _not?: InputMaybe<TreatmentShiftTypesBoolExp>;
  _or?: InputMaybe<Array<TreatmentShiftTypesBoolExp>>;
  shift_type?: InputMaybe<ShiftTypesBoolExp>;
  shift_type_id?: InputMaybe<IntComparisonExp>;
  treatment?: InputMaybe<TreatmentsBoolExp>;
  treatment_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "treatment_shift_types" */
export enum TreatmentShiftTypesConstraint {
  /** unique or primary key constraint on columns "shift_type_id", "treatment_id" */
  TREATMENT_SHIFT_TYPES_PKEY = 'treatment_shift_types_pkey',
}

/** input type for incrementing numeric columns in table "treatment_shift_types" */
export type TreatmentShiftTypesIncInput = {
  shift_type_id?: InputMaybe<Scalars['Int']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "treatment_shift_types" */
export type TreatmentShiftTypesInsertInput = {
  shift_type?: InputMaybe<ShiftTypesObjRelInsertInput>;
  shift_type_id?: InputMaybe<Scalars['Int']>;
  treatment?: InputMaybe<TreatmentsObjRelInsertInput>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TreatmentShiftTypesMaxFields = {
  __typename?: 'treatment_shift_types_max_fields';
  shift_type_id?: Maybe<Scalars['Int']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesMaxOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TreatmentShiftTypesMinFields = {
  __typename?: 'treatment_shift_types_min_fields';
  shift_type_id?: Maybe<Scalars['Int']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesMinOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "treatment_shift_types" */
export type TreatmentShiftTypesMutationResponse = {
  __typename?: 'treatment_shift_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentShiftTypes>;
};

/** on_conflict condition type for table "treatment_shift_types" */
export type TreatmentShiftTypesOnConflict = {
  constraint: TreatmentShiftTypesConstraint;
  update_columns?: Array<TreatmentShiftTypesUpdateColumn>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

/** Ordering options when selecting data from "treatment_shift_types". */
export type TreatmentShiftTypesOrderBy = {
  shift_type?: InputMaybe<ShiftTypesOrderBy>;
  shift_type_id?: InputMaybe<OrderBy>;
  treatment?: InputMaybe<TreatmentsOrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: treatment_shift_types */
export type TreatmentShiftTypesPkColumnsInput = {
  shift_type_id: Scalars['Int'];
  treatment_id: Scalars['Int'];
};

/** select columns of table "treatment_shift_types" */
export enum TreatmentShiftTypesSelectColumn {
  /** column name */
  SHIFT_TYPE_ID = 'shift_type_id',
  /** column name */
  TREATMENT_ID = 'treatment_id',
}

/** input type for updating data in table "treatment_shift_types" */
export type TreatmentShiftTypesSetInput = {
  shift_type_id?: InputMaybe<Scalars['Int']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TreatmentShiftTypesStddevFields = {
  __typename?: 'treatment_shift_types_stddev_fields';
  shift_type_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesStddevOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TreatmentShiftTypesStddevPopFields = {
  __typename?: 'treatment_shift_types_stddev_pop_fields';
  shift_type_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesStddevPopOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TreatmentShiftTypesStddevSampFields = {
  __typename?: 'treatment_shift_types_stddev_samp_fields';
  shift_type_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesStddevSampOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatment_shift_types" */
export type TreatmentShiftTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TreatmentShiftTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentShiftTypesStreamCursorValueInput = {
  shift_type_id?: InputMaybe<Scalars['Int']>;
  treatment_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TreatmentShiftTypesSumFields = {
  __typename?: 'treatment_shift_types_sum_fields';
  shift_type_id?: Maybe<Scalars['Int']>;
  treatment_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesSumOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** update columns of table "treatment_shift_types" */
export enum TreatmentShiftTypesUpdateColumn {
  /** column name */
  SHIFT_TYPE_ID = 'shift_type_id',
  /** column name */
  TREATMENT_ID = 'treatment_id',
}

export type TreatmentShiftTypesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentShiftTypesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentShiftTypesSetInput>;
  /** filter the rows which have to be updated */
  where: TreatmentShiftTypesBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentShiftTypesVarPopFields = {
  __typename?: 'treatment_shift_types_var_pop_fields';
  shift_type_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesVarPopOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TreatmentShiftTypesVarSampFields = {
  __typename?: 'treatment_shift_types_var_samp_fields';
  shift_type_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesVarSampOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TreatmentShiftTypesVarianceFields = {
  __typename?: 'treatment_shift_types_variance_fields';
  shift_type_id?: Maybe<Scalars['Float']>;
  treatment_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "treatment_shift_types" */
export type TreatmentShiftTypesVarianceOrderBy = {
  shift_type_id?: InputMaybe<OrderBy>;
  treatment_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "treatment_sms_treads" */
export type TreatmentSmsTreads = {
  __typename?: 'treatment_sms_treads';
  name: Scalars['String'];
  /** An array relationship */
  treatments: Array<Treatments>;
  /** An aggregate relationship */
  treatments_aggregate: TreatmentsAggregate;
};

/** columns and relationships of "treatment_sms_treads" */
export type TreatmentSmsTreadsTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** columns and relationships of "treatment_sms_treads" */
export type TreatmentSmsTreadsTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** aggregated selection of "treatment_sms_treads" */
export type TreatmentSmsTreadsAggregate = {
  __typename?: 'treatment_sms_treads_aggregate';
  aggregate?: Maybe<TreatmentSmsTreadsAggregateFields>;
  nodes: Array<TreatmentSmsTreads>;
};

/** aggregate fields of "treatment_sms_treads" */
export type TreatmentSmsTreadsAggregateFields = {
  __typename?: 'treatment_sms_treads_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TreatmentSmsTreadsMaxFields>;
  min?: Maybe<TreatmentSmsTreadsMinFields>;
};

/** aggregate fields of "treatment_sms_treads" */
export type TreatmentSmsTreadsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentSmsTreadsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "treatment_sms_treads". All fields are combined with a logical 'AND'. */
export type TreatmentSmsTreadsBoolExp = {
  _and?: InputMaybe<Array<TreatmentSmsTreadsBoolExp>>;
  _not?: InputMaybe<TreatmentSmsTreadsBoolExp>;
  _or?: InputMaybe<Array<TreatmentSmsTreadsBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
  treatments?: InputMaybe<TreatmentsBoolExp>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateBoolExp>;
};

/** unique or primary key constraints on table "treatment_sms_treads" */
export enum TreatmentSmsTreadsConstraint {
  /** unique or primary key constraint on columns "name" */
  TREATMENT_SMS_TREADS_PKEY = 'treatment_sms_treads_pkey',
}

export enum TreatmentSmsTreadsEnum {
  EUTHANASIA = 'euthanasia',
  FASTING = 'fasting',
  GENERAL = 'general',
  PASSPORT = 'passport',
  VIDEO = 'video',
}

/** Boolean expression to compare columns of type "treatment_sms_treads_enum". All fields are combined with logical 'AND'. */
export type TreatmentSmsTreadsEnumComparisonExp = {
  _eq?: InputMaybe<TreatmentSmsTreadsEnum>;
  _in?: InputMaybe<Array<TreatmentSmsTreadsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TreatmentSmsTreadsEnum>;
  _nin?: InputMaybe<Array<TreatmentSmsTreadsEnum>>;
};

/** input type for inserting data into table "treatment_sms_treads" */
export type TreatmentSmsTreadsInsertInput = {
  name?: InputMaybe<Scalars['String']>;
  treatments?: InputMaybe<TreatmentsArrRelInsertInput>;
};

/** aggregate max on columns */
export type TreatmentSmsTreadsMaxFields = {
  __typename?: 'treatment_sms_treads_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TreatmentSmsTreadsMinFields = {
  __typename?: 'treatment_sms_treads_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "treatment_sms_treads" */
export type TreatmentSmsTreadsMutationResponse = {
  __typename?: 'treatment_sms_treads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentSmsTreads>;
};

/** input type for inserting object relation for remote table "treatment_sms_treads" */
export type TreatmentSmsTreadsObjRelInsertInput = {
  data: TreatmentSmsTreadsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TreatmentSmsTreadsOnConflict>;
};

/** on_conflict condition type for table "treatment_sms_treads" */
export type TreatmentSmsTreadsOnConflict = {
  constraint: TreatmentSmsTreadsConstraint;
  update_columns?: Array<TreatmentSmsTreadsUpdateColumn>;
  where?: InputMaybe<TreatmentSmsTreadsBoolExp>;
};

/** Ordering options when selecting data from "treatment_sms_treads". */
export type TreatmentSmsTreadsOrderBy = {
  name?: InputMaybe<OrderBy>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateOrderBy>;
};

/** primary key columns input for table: treatment_sms_treads */
export type TreatmentSmsTreadsPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "treatment_sms_treads" */
export enum TreatmentSmsTreadsSelectColumn {
  /** column name */
  NAME = 'name',
}

/** input type for updating data in table "treatment_sms_treads" */
export type TreatmentSmsTreadsSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "treatment_sms_treads" */
export type TreatmentSmsTreadsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TreatmentSmsTreadsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentSmsTreadsStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "treatment_sms_treads" */
export enum TreatmentSmsTreadsUpdateColumn {
  /** column name */
  NAME = 'name',
}

export type TreatmentSmsTreadsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentSmsTreadsSetInput>;
  /** filter the rows which have to be updated */
  where: TreatmentSmsTreadsBoolExp;
};

/** columns and relationships of "treatment_status" */
export type TreatmentStatus = {
  __typename?: 'treatment_status';
  name: Scalars['String'];
  /** An array relationship */
  treatments: Array<Treatments>;
  /** An aggregate relationship */
  treatments_aggregate: TreatmentsAggregate;
};

/** columns and relationships of "treatment_status" */
export type TreatmentStatusTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** columns and relationships of "treatment_status" */
export type TreatmentStatusTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentsOrderBy>>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** aggregated selection of "treatment_status" */
export type TreatmentStatusAggregate = {
  __typename?: 'treatment_status_aggregate';
  aggregate?: Maybe<TreatmentStatusAggregateFields>;
  nodes: Array<TreatmentStatus>;
};

/** aggregate fields of "treatment_status" */
export type TreatmentStatusAggregateFields = {
  __typename?: 'treatment_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TreatmentStatusMaxFields>;
  min?: Maybe<TreatmentStatusMinFields>;
};

/** aggregate fields of "treatment_status" */
export type TreatmentStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "treatment_status". All fields are combined with a logical 'AND'. */
export type TreatmentStatusBoolExp = {
  _and?: InputMaybe<Array<TreatmentStatusBoolExp>>;
  _not?: InputMaybe<TreatmentStatusBoolExp>;
  _or?: InputMaybe<Array<TreatmentStatusBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
  treatments?: InputMaybe<TreatmentsBoolExp>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateBoolExp>;
};

/** unique or primary key constraints on table "treatment_status" */
export enum TreatmentStatusConstraint {
  /** unique or primary key constraint on columns "name" */
  TREATMENT_STATUS_PKEY = 'treatment_status_pkey',
}

export enum TreatmentStatusEnum {
  ARCHIVED = 'archived',
  DRAFT = 'draft',
  PRIVATE = 'private',
  PUBLISHED = 'published',
}

/** Boolean expression to compare columns of type "treatment_status_enum". All fields are combined with logical 'AND'. */
export type TreatmentStatusEnumComparisonExp = {
  _eq?: InputMaybe<TreatmentStatusEnum>;
  _in?: InputMaybe<Array<TreatmentStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TreatmentStatusEnum>;
  _nin?: InputMaybe<Array<TreatmentStatusEnum>>;
};

/** input type for inserting data into table "treatment_status" */
export type TreatmentStatusInsertInput = {
  name?: InputMaybe<Scalars['String']>;
  treatments?: InputMaybe<TreatmentsArrRelInsertInput>;
};

/** aggregate max on columns */
export type TreatmentStatusMaxFields = {
  __typename?: 'treatment_status_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TreatmentStatusMinFields = {
  __typename?: 'treatment_status_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "treatment_status" */
export type TreatmentStatusMutationResponse = {
  __typename?: 'treatment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentStatus>;
};

/** input type for inserting object relation for remote table "treatment_status" */
export type TreatmentStatusObjRelInsertInput = {
  data: TreatmentStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TreatmentStatusOnConflict>;
};

/** on_conflict condition type for table "treatment_status" */
export type TreatmentStatusOnConflict = {
  constraint: TreatmentStatusConstraint;
  update_columns?: Array<TreatmentStatusUpdateColumn>;
  where?: InputMaybe<TreatmentStatusBoolExp>;
};

/** Ordering options when selecting data from "treatment_status". */
export type TreatmentStatusOrderBy = {
  name?: InputMaybe<OrderBy>;
  treatments_aggregate?: InputMaybe<TreatmentsAggregateOrderBy>;
};

/** primary key columns input for table: treatment_status */
export type TreatmentStatusPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "treatment_status" */
export enum TreatmentStatusSelectColumn {
  /** column name */
  NAME = 'name',
}

/** input type for updating data in table "treatment_status" */
export type TreatmentStatusSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "treatment_status" */
export type TreatmentStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TreatmentStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentStatusStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "treatment_status" */
export enum TreatmentStatusUpdateColumn {
  /** column name */
  NAME = 'name',
}

export type TreatmentStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentStatusSetInput>;
  /** filter the rows which have to be updated */
  where: TreatmentStatusBoolExp;
};

/** columns and relationships of "treatments" */
export type Treatments = {
  __typename?: 'treatments';
  /** An object relationship */
  animal_gender?: Maybe<AnimalGenders>;
  /** An object relationship */
  animal_specy?: Maybe<AnimalSpecies>;
  /** An array relationship */
  booking_request_treatments: Array<BookingRequestTreatments>;
  /** An aggregate relationship */
  booking_request_treatments_aggregate: BookingRequestTreatmentsAggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  gender?: Maybe<AnimalGendersEnum>;
  id: Scalars['Int'];
  instructions?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  provet_id: Scalars['String'];
  sms_tread: TreatmentSmsTreadsEnum;
  species?: Maybe<AnimalSpeciesEnum>;
  status: TreatmentStatusEnum;
  /** An array relationship */
  treatment_employees: Array<TreatmentEmployees>;
  /** An aggregate relationship */
  treatment_employees_aggregate: TreatmentEmployeesAggregate;
  /** An object relationship */
  treatment_group?: Maybe<TreatmentGroups>;
  treatment_group_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  treatment_resources: Array<TreatmentResources>;
  /** An aggregate relationship */
  treatment_resources_aggregate: TreatmentResourcesAggregate;
  /** An array relationship */
  treatment_shift_types: Array<TreatmentShiftTypes>;
  /** An aggregate relationship */
  treatment_shift_types_aggregate: TreatmentShiftTypesAggregate;
  /** An object relationship */
  treatment_sms_tread: TreatmentSmsTreads;
  /** An object relationship */
  treatment_status: TreatmentStatus;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "treatments" */
export type TreatmentsBookingRequestTreatmentsArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestTreatmentsOrderBy>>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

/** columns and relationships of "treatments" */
export type TreatmentsBookingRequestTreatmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestTreatmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestTreatmentsOrderBy>>;
  where?: InputMaybe<BookingRequestTreatmentsBoolExp>;
};

/** columns and relationships of "treatments" */
export type TreatmentsTreatmentEmployeesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentEmployeesOrderBy>>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

/** columns and relationships of "treatments" */
export type TreatmentsTreatmentEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentEmployeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentEmployeesOrderBy>>;
  where?: InputMaybe<TreatmentEmployeesBoolExp>;
};

/** columns and relationships of "treatments" */
export type TreatmentsTreatmentResourcesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentResourcesOrderBy>>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

/** columns and relationships of "treatments" */
export type TreatmentsTreatmentResourcesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentResourcesOrderBy>>;
  where?: InputMaybe<TreatmentResourcesBoolExp>;
};

/** columns and relationships of "treatments" */
export type TreatmentsTreatmentShiftTypesArgs = {
  distinct_on?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentShiftTypesOrderBy>>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

/** columns and relationships of "treatments" */
export type TreatmentsTreatmentShiftTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TreatmentShiftTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TreatmentShiftTypesOrderBy>>;
  where?: InputMaybe<TreatmentShiftTypesBoolExp>;
};

/** aggregated selection of "treatments" */
export type TreatmentsAggregate = {
  __typename?: 'treatments_aggregate';
  aggregate?: Maybe<TreatmentsAggregateFields>;
  nodes: Array<Treatments>;
};

export type TreatmentsAggregateBoolExp = {
  count?: InputMaybe<TreatmentsAggregateBoolExpCount>;
};

export type TreatmentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TreatmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TreatmentsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "treatments" */
export type TreatmentsAggregateFields = {
  __typename?: 'treatments_aggregate_fields';
  avg?: Maybe<TreatmentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentsMaxFields>;
  min?: Maybe<TreatmentsMinFields>;
  stddev?: Maybe<TreatmentsStddevFields>;
  stddev_pop?: Maybe<TreatmentsStddevPopFields>;
  stddev_samp?: Maybe<TreatmentsStddevSampFields>;
  sum?: Maybe<TreatmentsSumFields>;
  var_pop?: Maybe<TreatmentsVarPopFields>;
  var_samp?: Maybe<TreatmentsVarSampFields>;
  variance?: Maybe<TreatmentsVarianceFields>;
};

/** aggregate fields of "treatments" */
export type TreatmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "treatments" */
export type TreatmentsAggregateOrderBy = {
  avg?: InputMaybe<TreatmentsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TreatmentsMaxOrderBy>;
  min?: InputMaybe<TreatmentsMinOrderBy>;
  stddev?: InputMaybe<TreatmentsStddevOrderBy>;
  stddev_pop?: InputMaybe<TreatmentsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TreatmentsStddevSampOrderBy>;
  sum?: InputMaybe<TreatmentsSumOrderBy>;
  var_pop?: InputMaybe<TreatmentsVarPopOrderBy>;
  var_samp?: InputMaybe<TreatmentsVarSampOrderBy>;
  variance?: InputMaybe<TreatmentsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "treatments" */
export type TreatmentsArrRelInsertInput = {
  data: Array<TreatmentsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TreatmentsOnConflict>;
};

/** aggregate avg on columns */
export type TreatmentsAvgFields = {
  __typename?: 'treatments_avg_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "treatments" */
export type TreatmentsAvgOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "treatments". All fields are combined with a logical 'AND'. */
export type TreatmentsBoolExp = {
  _and?: InputMaybe<Array<TreatmentsBoolExp>>;
  _not?: InputMaybe<TreatmentsBoolExp>;
  _or?: InputMaybe<Array<TreatmentsBoolExp>>;
  animal_gender?: InputMaybe<AnimalGendersBoolExp>;
  animal_specy?: InputMaybe<AnimalSpeciesBoolExp>;
  booking_request_treatments?: InputMaybe<BookingRequestTreatmentsBoolExp>;
  booking_request_treatments_aggregate?: InputMaybe<BookingRequestTreatmentsAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  display_name?: InputMaybe<StringComparisonExp>;
  duration?: InputMaybe<IntComparisonExp>;
  gender?: InputMaybe<AnimalGendersEnumComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  instructions?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  provet_id?: InputMaybe<StringComparisonExp>;
  sms_tread?: InputMaybe<TreatmentSmsTreadsEnumComparisonExp>;
  species?: InputMaybe<AnimalSpeciesEnumComparisonExp>;
  status?: InputMaybe<TreatmentStatusEnumComparisonExp>;
  treatment_employees?: InputMaybe<TreatmentEmployeesBoolExp>;
  treatment_employees_aggregate?: InputMaybe<TreatmentEmployeesAggregateBoolExp>;
  treatment_group?: InputMaybe<TreatmentGroupsBoolExp>;
  treatment_group_id?: InputMaybe<IntComparisonExp>;
  treatment_resources?: InputMaybe<TreatmentResourcesBoolExp>;
  treatment_resources_aggregate?: InputMaybe<TreatmentResourcesAggregateBoolExp>;
  treatment_shift_types?: InputMaybe<TreatmentShiftTypesBoolExp>;
  treatment_shift_types_aggregate?: InputMaybe<TreatmentShiftTypesAggregateBoolExp>;
  treatment_sms_tread?: InputMaybe<TreatmentSmsTreadsBoolExp>;
  treatment_status?: InputMaybe<TreatmentStatusBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "treatments" */
export enum TreatmentsConstraint {
  /** unique or primary key constraint on columns "id" */
  TREATMENTS_PKEY = 'treatments_pkey',
}

/** input type for incrementing numeric columns in table "treatments" */
export type TreatmentsIncInput = {
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  treatment_group_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "treatments" */
export type TreatmentsInsertInput = {
  animal_gender?: InputMaybe<AnimalGendersObjRelInsertInput>;
  animal_specy?: InputMaybe<AnimalSpeciesObjRelInsertInput>;
  booking_request_treatments?: InputMaybe<BookingRequestTreatmentsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<AnimalGendersEnum>;
  id?: InputMaybe<Scalars['Int']>;
  instructions?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  provet_id?: InputMaybe<Scalars['String']>;
  sms_tread?: InputMaybe<TreatmentSmsTreadsEnum>;
  species?: InputMaybe<AnimalSpeciesEnum>;
  status?: InputMaybe<TreatmentStatusEnum>;
  treatment_employees?: InputMaybe<TreatmentEmployeesArrRelInsertInput>;
  treatment_group?: InputMaybe<TreatmentGroupsObjRelInsertInput>;
  treatment_group_id?: InputMaybe<Scalars['Int']>;
  treatment_resources?: InputMaybe<TreatmentResourcesArrRelInsertInput>;
  treatment_shift_types?: InputMaybe<TreatmentShiftTypesArrRelInsertInput>;
  treatment_sms_tread?: InputMaybe<TreatmentSmsTreadsObjRelInsertInput>;
  treatment_status?: InputMaybe<TreatmentStatusObjRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TreatmentsMaxFields = {
  __typename?: 'treatments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  provet_id?: Maybe<Scalars['String']>;
  treatment_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "treatments" */
export type TreatmentsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  instructions?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TreatmentsMinFields = {
  __typename?: 'treatments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  provet_id?: Maybe<Scalars['String']>;
  treatment_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "treatments" */
export type TreatmentsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  instructions?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "treatments" */
export type TreatmentsMutationResponse = {
  __typename?: 'treatments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Treatments>;
};

/** input type for inserting object relation for remote table "treatments" */
export type TreatmentsObjRelInsertInput = {
  data: TreatmentsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TreatmentsOnConflict>;
};

/** on_conflict condition type for table "treatments" */
export type TreatmentsOnConflict = {
  constraint: TreatmentsConstraint;
  update_columns?: Array<TreatmentsUpdateColumn>;
  where?: InputMaybe<TreatmentsBoolExp>;
};

/** Ordering options when selecting data from "treatments". */
export type TreatmentsOrderBy = {
  animal_gender?: InputMaybe<AnimalGendersOrderBy>;
  animal_specy?: InputMaybe<AnimalSpeciesOrderBy>;
  booking_request_treatments_aggregate?: InputMaybe<BookingRequestTreatmentsAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  instructions?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  sms_tread?: InputMaybe<OrderBy>;
  species?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  treatment_employees_aggregate?: InputMaybe<TreatmentEmployeesAggregateOrderBy>;
  treatment_group?: InputMaybe<TreatmentGroupsOrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
  treatment_resources_aggregate?: InputMaybe<TreatmentResourcesAggregateOrderBy>;
  treatment_shift_types_aggregate?: InputMaybe<TreatmentShiftTypesAggregateOrderBy>;
  treatment_sms_tread?: InputMaybe<TreatmentSmsTreadsOrderBy>;
  treatment_status?: InputMaybe<TreatmentStatusOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: treatments */
export type TreatmentsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "treatments" */
export enum TreatmentsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DISPLAY_NAME = 'display_name',
  /** column name */
  DURATION = 'duration',
  /** column name */
  GENDER = 'gender',
  /** column name */
  ID = 'id',
  /** column name */
  INSTRUCTIONS = 'instructions',
  /** column name */
  NAME = 'name',
  /** column name */
  PRICE = 'price',
  /** column name */
  PRIORITY = 'priority',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  SMS_TREAD = 'sms_tread',
  /** column name */
  SPECIES = 'species',
  /** column name */
  STATUS = 'status',
  /** column name */
  TREATMENT_GROUP_ID = 'treatment_group_id',
  /** column name */
  UPDATED_AT = 'updated_at',
}

/** input type for updating data in table "treatments" */
export type TreatmentsSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<AnimalGendersEnum>;
  id?: InputMaybe<Scalars['Int']>;
  instructions?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  provet_id?: InputMaybe<Scalars['String']>;
  sms_tread?: InputMaybe<TreatmentSmsTreadsEnum>;
  species?: InputMaybe<AnimalSpeciesEnum>;
  status?: InputMaybe<TreatmentStatusEnum>;
  treatment_group_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TreatmentsStddevFields = {
  __typename?: 'treatments_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "treatments" */
export type TreatmentsStddevOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TreatmentsStddevPopFields = {
  __typename?: 'treatments_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "treatments" */
export type TreatmentsStddevPopOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TreatmentsStddevSampFields = {
  __typename?: 'treatments_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "treatments" */
export type TreatmentsStddevSampOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatments" */
export type TreatmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TreatmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentsStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<AnimalGendersEnum>;
  id?: InputMaybe<Scalars['Int']>;
  instructions?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  provet_id?: InputMaybe<Scalars['String']>;
  sms_tread?: InputMaybe<TreatmentSmsTreadsEnum>;
  species?: InputMaybe<AnimalSpeciesEnum>;
  status?: InputMaybe<TreatmentStatusEnum>;
  treatment_group_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TreatmentsSumFields = {
  __typename?: 'treatments_sum_fields';
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  treatment_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "treatments" */
export type TreatmentsSumOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
};

/** update columns of table "treatments" */
export enum TreatmentsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DISPLAY_NAME = 'display_name',
  /** column name */
  DURATION = 'duration',
  /** column name */
  GENDER = 'gender',
  /** column name */
  ID = 'id',
  /** column name */
  INSTRUCTIONS = 'instructions',
  /** column name */
  NAME = 'name',
  /** column name */
  PRICE = 'price',
  /** column name */
  PRIORITY = 'priority',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  SMS_TREAD = 'sms_tread',
  /** column name */
  SPECIES = 'species',
  /** column name */
  STATUS = 'status',
  /** column name */
  TREATMENT_GROUP_ID = 'treatment_group_id',
  /** column name */
  UPDATED_AT = 'updated_at',
}

export type TreatmentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentsSetInput>;
  /** filter the rows which have to be updated */
  where: TreatmentsBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentsVarPopFields = {
  __typename?: 'treatments_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "treatments" */
export type TreatmentsVarPopOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TreatmentsVarSampFields = {
  __typename?: 'treatments_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "treatments" */
export type TreatmentsVarSampOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TreatmentsVarianceFields = {
  __typename?: 'treatments_variance_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  treatment_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "treatments" */
export type TreatmentsVarianceOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  treatment_group_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  address?: Maybe<Scalars['String']>;
  /** An array relationship */
  bookingRequestsByUserId: Array<BookingRequest>;
  /** An aggregate relationship */
  bookingRequestsByUserId_aggregate: BookingRequestAggregate;
  /** An array relationship */
  booking_requests: Array<BookingRequest>;
  /** An aggregate relationship */
  booking_requests_aggregate: BookingRequestAggregate;
  city?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  phone_number: Scalars['String'];
  provet_id: Scalars['String'];
  referral_code?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  zip_code?: Maybe<Scalars['String']>;
};

/** columns and relationships of "users" */
export type UsersBookingRequestsByUserIdArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

/** columns and relationships of "users" */
export type UsersBookingRequestsByUserIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

/** columns and relationships of "users" */
export type UsersBookingRequestsArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

/** columns and relationships of "users" */
export type UsersBookingRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookingRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookingRequestOrderBy>>;
  where?: InputMaybe<BookingRequestBoolExp>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
};

/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  bookingRequestsByUserId?: InputMaybe<BookingRequestBoolExp>;
  bookingRequestsByUserId_aggregate?: InputMaybe<BookingRequestAggregateBoolExp>;
  booking_requests?: InputMaybe<BookingRequestBoolExp>;
  booking_requests_aggregate?: InputMaybe<BookingRequestAggregateBoolExp>;
  city?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  phone_number?: InputMaybe<StringComparisonExp>;
  provet_id?: InputMaybe<StringComparisonExp>;
  referral_code?: InputMaybe<StringComparisonExp>;
  roles?: InputMaybe<StringArrayComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  zip_code?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "id" */
  USERS_PKEY = 'users_pkey',
}

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  bookingRequestsByUserId?: InputMaybe<BookingRequestArrRelInsertInput>;
  booking_requests?: InputMaybe<BookingRequestArrRelInsertInput>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  provet_id?: InputMaybe<Scalars['String']>;
  referral_code?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'users_max_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  provet_id?: Maybe<Scalars['String']>;
  referral_code?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'users_min_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  provet_id?: Maybe<Scalars['String']>;
  referral_code?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UsersOnConflict>;
};

/** on_conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "users". */
export type UsersOrderBy = {
  address?: InputMaybe<OrderBy>;
  bookingRequestsByUserId_aggregate?: InputMaybe<BookingRequestAggregateOrderBy>;
  booking_requests_aggregate?: InputMaybe<BookingRequestAggregateOrderBy>;
  city?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone_number?: InputMaybe<OrderBy>;
  provet_id?: InputMaybe<OrderBy>;
  referral_code?: InputMaybe<OrderBy>;
  roles?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zip_code?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: users */
export type UsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  ADDRESS = 'address',
  /** column name */
  CITY = 'city',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EMAIL = 'email',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PHONE_NUMBER = 'phone_number',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  REFERRAL_CODE = 'referral_code',
  /** column name */
  ROLES = 'roles',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  ZIP_CODE = 'zip_code',
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  provet_id?: InputMaybe<Scalars['String']>;
  referral_code?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  provet_id?: InputMaybe<Scalars['String']>;
  referral_code?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  ADDRESS = 'address',
  /** column name */
  CITY = 'city',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EMAIL = 'email',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PHONE_NUMBER = 'phone_number',
  /** column name */
  PROVET_ID = 'provet_id',
  /** column name */
  REFERRAL_CODE = 'referral_code',
  /** column name */
  ROLES = 'roles',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  ZIP_CODE = 'zip_code',
}

export type UsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AnimalFragment = {
  __typename?: 'Animal';
  breed?: string | null;
  deceased?: string | null;
  dob?: string | null;
  gender?: AnimalGender | null;
  id: string;
  insurance?: string | null;
  insuranceCompany?: string | null;
  name: string;
  owner: string;
  state?: DtoState | null;
  type?: AnimalType | null;
  weight?: number | null;
};

export type GetMyAnimalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyAnimalsQuery = {
  __typename?: 'query_root';
  animals: Array<({ __typename?: 'Animal' } & AnimalFragment) | null>;
};

export type CreateMyAnimalMutationVariables = Exact<{
  gender?: InputMaybe<AnimalGender>;
  dob?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AnimalType>;
  weight?: InputMaybe<Scalars['Float']>;
}>;

export type CreateMyAnimalMutation = {
  __typename?: 'mutation_root';
  animal?: ({ __typename?: 'Animal' } & AnimalFragment) | null;
};

export type UpdateMyAnimalMutationVariables = Exact<{
  dob?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<AnimalGender>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AnimalType>;
  weight?: InputMaybe<Scalars['Float']>;
}>;

export type UpdateMyAnimalMutation = {
  __typename?: 'mutation_root';
  animal?: ({ __typename?: 'Animal' } & AnimalFragment) | null;
};

export type DeleteMyAnimalMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type DeleteMyAnimalMutation = {
  __typename?: 'mutation_root';
  deleteMyAnimal?: { __typename?: 'IdOutput'; id: string } | null;
};

export type SlotTimeInfoFragment = {
  __typename?: 'TimeInfo';
  endTime: string;
  primaryResources: Array<string>;
  secondaryResources: Array<string>;
  service: string;
  startTime: string;
};

export type SlotFragment = {
  __typename?: 'Slot';
  isAvailable: boolean;
  numberOfAvailableSlots: number;
  status: string;
  time: string;
  timeInfo: Array<({ __typename?: 'TimeInfo' } & SlotTimeInfoFragment) | null>;
};

export type AvailablityFragment = {
  __typename?: 'Availablity';
  date: string;
  isAvailable: boolean;
  isOpen: boolean;
  slots: Array<{ __typename?: 'Slot' } & SlotFragment>;
};

export type GetBookingRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBookingRequestsQuery = {
  __typename?: 'query_root';
  booking_request: Array<{
    __typename?: 'booking_request';
    id: any;
    created_at: any;
    status: BookingRequestStatusEnum;
    location?: {
      __typename?: 'locations';
      city: string;
      name: string;
      streetAddress: string;
      zipCode: string;
    } | null;
    booking_request_treatments: Array<{
      __typename?: 'booking_request_treatments';
      treatment: { __typename?: 'treatments'; name: string };
    }>;
  }>;
};

export type ArchiveBookingRequestMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type ArchiveBookingRequestMutation = {
  __typename?: 'mutation_root';
  update_booking_request_by_pk?: { __typename?: 'booking_request'; id: any } | null;
};

export type BookingFragment = {
  __typename?: 'Booking';
  bookingIds: Array<string | null>;
  confirmed: boolean;
  duration: number;
  notes?: string | null;
  price: number;
  state: BookingState;
  time: string;
  animal: { __typename?: 'Animal' } & AnimalFragment;
  location?: ({ __typename?: 'locations' } & LocationFragment) | null;
  services: Array<{
    __typename?: 'Service';
    duration: number;
    id: string;
    name: string;
    price: number;
    unknown: boolean;
  } | null>;
};

export type CreateBookingMutationVariables = Exact<{
  args: CreateBookingInput;
}>;

export type CreateBookingMutation = {
  __typename?: 'mutation_root';
  createBooking?: ({ __typename?: 'Booking' } & BookingFragment) | null;
};

export type GetMyBookingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyBookingsQuery = {
  __typename?: 'query_root';
  getMyBookings?: Array<({ __typename?: 'Booking' } & BookingFragment) | null> | null;
};

export type GetMyBookingsForTodayQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyBookingsForTodayQuery = {
  __typename?: 'query_root';
  getMyBookingsForToday: Array<({ __typename?: 'Booking' } & BookingFragment) | null>;
};

export type DeleteBookingMutationVariables = Exact<{
  bookingIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteBookingMutation = {
  __typename?: 'mutation_root';
  deleteBooking?: ({ __typename?: 'Booking' } & BookingFragment) | null;
};

export type UpdateMyBookingMutationVariables = Exact<{
  bookingIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  time?: InputMaybe<Scalars['String']>;
}>;

export type UpdateMyBookingMutation = {
  __typename?: 'mutation_root';
  updateMyBooking?: ({ __typename?: 'Booking' } & BookingFragment) | null;
};

export type UpdateMyBookingToCheckedInMutationVariables = Exact<{
  bookingIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
}>;

export type UpdateMyBookingToCheckedInMutation = {
  __typename?: 'mutation_root';
  status?: ({ __typename?: 'StatusOutput' } & StatusFragment) | null;
};

export type CreateBookingFromRequestMutationVariables = Exact<{
  id: Scalars['String'];
  time: Scalars['String'];
}>;

export type CreateBookingFromRequestMutation = {
  __typename?: 'mutation_root';
  createBookingFromRequest?: ({ __typename?: 'Booking' } & BookingFragment) | null;
};

export type StatusFragment = { __typename?: 'StatusOutput'; message: string };

export type LocationFragment = {
  __typename?: 'locations';
  id: number;
  city: string;
  name: string;
  streetAddress: string;
  zipCode: string;
  provetId: string;
  openingDate: any;
};

export type GetLocationsQueryVariables = Exact<{
  where: LocationsBoolExp;
}>;

export type GetLocationsQuery = {
  __typename?: 'query_root';
  locations: Array<{ __typename?: 'locations' } & LocationFragment>;
};

export type GetAvailabilityQueryVariables = Exact<{
  locationId: Scalars['String'];
  serviceIds: Array<Scalars['String']> | Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;

export type GetAvailabilityQuery = {
  __typename?: 'query_root';
  getAvailability: Array<({ __typename?: 'Availablity' } & AvailablityFragment) | null>;
};

export type GetAvailabilityForBookingQueryVariables = Exact<{
  bookingIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetAvailabilityForBookingQuery = {
  __typename?: 'query_root';
  availablity: Array<({ __typename?: 'Availablity' } & AvailablityFragment) | null>;
};

export type GetAvailabilityForBookingRequestQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type GetAvailabilityForBookingRequestQuery = {
  __typename?: 'query_root';
  availablity: Array<({ __typename?: 'Availablity' } & AvailablityFragment) | null>;
};

export type GetTreatmentsByGroupQueryVariables = Exact<{
  where: TreatmentsBoolExp;
}>;

export type GetTreatmentsByGroupQuery = {
  __typename?: 'query_root';
  treatment_groups: Array<{
    __typename?: 'treatment_groups';
    icon: IconsEnum;
    id: number;
    name: string;
    description: string;
    treatments: Array<{
      __typename?: 'treatments';
      description?: string | null;
      duration?: number | null;
      id: number;
      instructions?: string | null;
      name: string;
      display_name?: string | null;
      price?: number | null;
      provet_id: string;
      species?: AnimalSpeciesEnum | null;
      gender?: AnimalGendersEnum | null;
    }>;
  }>;
};

export type UserFragment = {
  __typename?: 'User';
  animals: Array<string | null>;
  city: string;
  email: string;
  id: string;
  name: string;
  phoneNumber: string;
  state: DtoState;
  streetAddress: string;
  zipCode: string;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'query_root';
  getMe: { __typename?: 'User' } & UserFragment;
};

export type UpdateMeMutationVariables = Exact<{
  name: Scalars['String'];
  streetAddress: Scalars['String'];
  email: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
}>;

export type UpdateMeMutation = {
  __typename?: 'mutation_root';
  updateMe: { __typename?: 'User' } & UserFragment;
};

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String'];
  streetAddress: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
}>;

export type CreateUserMutation = {
  __typename?: 'mutation_root';
  createUser?: { __typename?: 'IdOutput'; id: string } | null;
};

export const SlotTimeInfoFragmentDoc = gql`
  fragment SlotTimeInfo on TimeInfo {
    endTime
    primaryResources
    secondaryResources
    service
    startTime
  }
`;
export const SlotFragmentDoc = gql`
  fragment Slot on Slot {
    isAvailable
    numberOfAvailableSlots
    status
    time
    timeInfo {
      ...SlotTimeInfo
    }
  }
  ${SlotTimeInfoFragmentDoc}
`;
export const AvailablityFragmentDoc = gql`
  fragment Availablity on Availablity {
    date
    isAvailable
    isOpen
    slots {
      ...Slot
    }
  }
  ${SlotFragmentDoc}
`;
export const AnimalFragmentDoc = gql`
  fragment Animal on Animal {
    breed
    deceased
    dob
    gender
    id
    insurance
    insuranceCompany
    name
    owner
    state
    type
    weight
  }
`;
export const LocationFragmentDoc = gql`
  fragment Location on locations {
    id
    city
    name: title
    streetAddress: address
    zipCode: zip_code
    provetId: provet_id
    openingDate: opening_date
  }
`;
export const BookingFragmentDoc = gql`
  fragment Booking on Booking {
    bookingIds
    animal {
      ...Animal
    }
    confirmed
    duration
    location {
      ...Location
    }
    notes
    price
    services {
      duration
      id
      name
      price
      unknown
    }
    state
    time
  }
  ${AnimalFragmentDoc}
  ${LocationFragmentDoc}
`;
export const StatusFragmentDoc = gql`
  fragment Status on StatusOutput {
    message
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    animals
    city
    email
    id
    name
    phoneNumber
    state
    streetAddress
    zipCode
  }
`;
export const GetMyAnimalsDocument = gql`
  query getMyAnimals {
    animals: getMyAnimals {
      ...Animal
    }
  }
  ${AnimalFragmentDoc}
`;
export const CreateMyAnimalDocument = gql`
  mutation createMyAnimal(
    $gender: AnimalGender = female
    $dob: String = ""
    $name: String = ""
    $type: AnimalType = cat
    $weight: Float = 10
  ) {
    animal: createMyAnimal(dob: $dob, gender: $gender, name: $name, type: $type, weight: $weight) {
      ...Animal
    }
  }
  ${AnimalFragmentDoc}
`;
export const UpdateMyAnimalDocument = gql`
  mutation updateMyAnimal(
    $dob: String = ""
    $gender: AnimalGender = female
    $id: String = ""
    $name: String = ""
    $type: AnimalType = cat
    $weight: Float = 10
  ) {
    animal: updateMyAnimal(
      dob: $dob
      gender: $gender
      id: $id
      name: $name
      type: $type
      weight: $weight
    ) {
      ...Animal
    }
  }
  ${AnimalFragmentDoc}
`;
export const DeleteMyAnimalDocument = gql`
  mutation deleteMyAnimal($id: String = "") {
    deleteMyAnimal(id: $id) {
      id
    }
  }
`;
export const GetBookingRequestsDocument = gql`
  query getBookingRequests {
    booking_request(where: { status: { _eq: active } }) {
      id
      created_at
      location {
        name: title
        streetAddress: address
        zipCode: zip_code
        city
      }
      status
      booking_request_treatments {
        treatment {
          name
        }
      }
    }
  }
`;
export const ArchiveBookingRequestDocument = gql`
  mutation archiveBookingRequest($id: uuid!) {
    update_booking_request_by_pk(pk_columns: { id: $id }, _set: { status: archived }) {
      id
    }
  }
`;
export const CreateBookingDocument = gql`
  mutation createBooking($args: CreateBookingInput!) {
    createBooking(args: $args) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
export const GetMyBookingsDocument = gql`
  query getMyBookings {
    getMyBookings {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
export const GetMyBookingsForTodayDocument = gql`
  query getMyBookingsForToday {
    getMyBookingsForToday {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
export const DeleteBookingDocument = gql`
  mutation deleteBooking($bookingIds: [String!]!) {
    deleteBooking(bookingIds: $bookingIds) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
export const UpdateMyBookingDocument = gql`
  mutation updateMyBooking($bookingIds: [String!] = "", $time: String = "") {
    updateMyBooking(bookingIds: $bookingIds, time: $time) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
export const UpdateMyBookingToCheckedInDocument = gql`
  mutation updateMyBookingToCheckedIn($bookingIds: [String!] = "", $weight: Float = 1.5) {
    status: updateMyBookingToCheckedIn(bookingIds: $bookingIds, weight: $weight) {
      ...Status
    }
  }
  ${StatusFragmentDoc}
`;
export const CreateBookingFromRequestDocument = gql`
  mutation createBookingFromRequest($id: String!, $time: String!) {
    createBookingFromRequest(id: $id, time: $time) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
export const GetLocationsDocument = gql`
  query getLocations($where: locations_bool_exp!) {
    locations(where: $where) {
      ...Location
    }
  }
  ${LocationFragmentDoc}
`;
export const GetAvailabilityDocument = gql`
  query getAvailability(
    $locationId: String!
    $serviceIds: [String!]!
    $startDate: String!
    $endDate: String!
  ) {
    getAvailability(
      endDate: $endDate
      locationId: $locationId
      serviceIds: $serviceIds
      startDate: $startDate
    ) {
      ...Availablity
    }
  }
  ${AvailablityFragmentDoc}
`;
export const GetAvailabilityForBookingDocument = gql`
  query getAvailabilityForBooking($bookingIds: [String!] = "") {
    availablity: getAvailabilityForBooking(bookingIds: $bookingIds) {
      ...Availablity
    }
  }
  ${AvailablityFragmentDoc}
`;
export const GetAvailabilityForBookingRequestDocument = gql`
  query getAvailabilityForBookingRequest($id: String = "") {
    availablity: getAvailabilityForBookingRequest(id: $id) {
      ...Availablity
    }
  }
  ${AvailablityFragmentDoc}
`;
export const GetTreatmentsByGroupDocument = gql`
  query getTreatmentsByGroup($where: treatments_bool_exp!) {
    treatment_groups(order_by: { priority: desc }) {
      icon
      id
      name
      description
      treatments(where: $where, order_by: { priority: desc }) {
        description
        duration
        id
        instructions
        name
        display_name
        price
        provet_id
        species
        gender
      }
    }
  }
`;
export const GetMeDocument = gql`
  query getMe {
    getMe {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export const UpdateMeDocument = gql`
  mutation updateMe(
    $name: String!
    $streetAddress: String!
    $email: String!
    $city: String!
    $zipCode: String!
  ) {
    updateMe(address: $streetAddress, city: $city, email: $email, name: $name, zipCode: $zipCode) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export const CreateUserDocument = gql`
  mutation createUser(
    $name: String!
    $streetAddress: String!
    $email: String!
    $phoneNumber: String!
    $city: String!
    $zipCode: String!
  ) {
    createUser(
      address: $streetAddress
      city: $city
      email: $email
      name: $name
      phoneNumber: $phoneNumber
      zipCode: $zipCode
    ) {
      id
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getMyAnimals(
      variables?: GetMyAnimalsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetMyAnimalsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMyAnimalsQuery>(GetMyAnimalsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getMyAnimals',
        'query',
      );
    },
    createMyAnimal(
      variables?: CreateMyAnimalMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateMyAnimalMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateMyAnimalMutation>(CreateMyAnimalDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createMyAnimal',
        'mutation',
      );
    },
    updateMyAnimal(
      variables?: UpdateMyAnimalMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateMyAnimalMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateMyAnimalMutation>(UpdateMyAnimalDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateMyAnimal',
        'mutation',
      );
    },
    deleteMyAnimal(
      variables?: DeleteMyAnimalMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteMyAnimalMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteMyAnimalMutation>(DeleteMyAnimalDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteMyAnimal',
        'mutation',
      );
    },
    getBookingRequests(
      variables?: GetBookingRequestsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetBookingRequestsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBookingRequestsQuery>(GetBookingRequestsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getBookingRequests',
        'query',
      );
    },
    archiveBookingRequest(
      variables: ArchiveBookingRequestMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<ArchiveBookingRequestMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ArchiveBookingRequestMutation>(ArchiveBookingRequestDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'archiveBookingRequest',
        'mutation',
      );
    },
    createBooking(
      variables: CreateBookingMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateBookingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateBookingMutation>(CreateBookingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createBooking',
        'mutation',
      );
    },
    getMyBookings(
      variables?: GetMyBookingsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetMyBookingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMyBookingsQuery>(GetMyBookingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getMyBookings',
        'query',
      );
    },
    getMyBookingsForToday(
      variables?: GetMyBookingsForTodayQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetMyBookingsForTodayQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMyBookingsForTodayQuery>(GetMyBookingsForTodayDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getMyBookingsForToday',
        'query',
      );
    },
    deleteBooking(
      variables: DeleteBookingMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteBookingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteBookingMutation>(DeleteBookingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteBooking',
        'mutation',
      );
    },
    updateMyBooking(
      variables?: UpdateMyBookingMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateMyBookingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateMyBookingMutation>(UpdateMyBookingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateMyBooking',
        'mutation',
      );
    },
    updateMyBookingToCheckedIn(
      variables?: UpdateMyBookingToCheckedInMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateMyBookingToCheckedInMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateMyBookingToCheckedInMutation>(
            UpdateMyBookingToCheckedInDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateMyBookingToCheckedIn',
        'mutation',
      );
    },
    createBookingFromRequest(
      variables: CreateBookingFromRequestMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateBookingFromRequestMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateBookingFromRequestMutation>(
            CreateBookingFromRequestDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createBookingFromRequest',
        'mutation',
      );
    },
    getLocations(
      variables: GetLocationsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetLocationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetLocationsQuery>(GetLocationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getLocations',
        'query',
      );
    },
    getAvailability(
      variables: GetAvailabilityQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetAvailabilityQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAvailabilityQuery>(GetAvailabilityDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getAvailability',
        'query',
      );
    },
    getAvailabilityForBooking(
      variables?: GetAvailabilityForBookingQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetAvailabilityForBookingQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAvailabilityForBookingQuery>(
            GetAvailabilityForBookingDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'getAvailabilityForBooking',
        'query',
      );
    },
    getAvailabilityForBookingRequest(
      variables?: GetAvailabilityForBookingRequestQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetAvailabilityForBookingRequestQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAvailabilityForBookingRequestQuery>(
            GetAvailabilityForBookingRequestDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'getAvailabilityForBookingRequest',
        'query',
      );
    },
    getTreatmentsByGroup(
      variables: GetTreatmentsByGroupQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetTreatmentsByGroupQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTreatmentsByGroupQuery>(GetTreatmentsByGroupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getTreatmentsByGroup',
        'query',
      );
    },
    getMe(
      variables?: GetMeQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetMeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMeQuery>(GetMeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getMe',
        'query',
      );
    },
    updateMe(
      variables: UpdateMeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateMeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateMeMutation>(UpdateMeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateMe',
        'mutation',
      );
    },
    createUser(
      variables: CreateUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateUserMutation>(CreateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createUser',
        'mutation',
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
